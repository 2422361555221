import { useCallback, useEffect } from "react";
import { useState } from "react";
import { getKeys, setKeys } from "../../firebase";
import "./KeyboardShortcuts.css";

const Shortcut = ({ icon, text, keyText, handleClick, keyCode, item, setReady }) => {
  return (
    <div className="col-10 d-flex flex-row justify-content-between align-items-center mt-2 optionsFont ">
      <div className="col-auto d-flex flex-row justify-content-center align-items-center">
        <img src={`/assets/icons/${icon}.svg`} height="18" width="18" className=" m-1" />
        <span className="w-3 optionsFont">{text}</span>
      </div>
      <span
        className="col-6 border pointer hoverSoft optionsFont"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          console.log(item);
          handleClick(() => {
            return { ...item };
          });
          setReady(true);
        }}
      >
        {keyText}
      </span>
    </div>
  );
};

const KeyboardShortcuts = ({ setisEditingSettings,show }) => {
  const [localKeys, setLocalKeys] = useState([]);
  const [currentKey, setCurrentKey] = useState({});
  const [ready, setready] = useState(false);
  const localCb = (data) => {
    console.log("data",data)
    setLocalKeys(data.keys);
  };
  // KEY FUNCTIONS


  useEffect(() => {
    const fireAsync = async () => {
    await getKeys(localCb);
    };
    fireAsync();

  }, []);

  useEffect(() => {
    if (ready) {
      window.$("#exampleModal").modal("show");
      return;
    }
    if (!ready) {
      setisEditingSettings(false);
      window.$("#exampleModal").modal("hide");
      return;
    }
  }, [ready]);

  const mappit = useCallback(
    function(e){
      const mapped = localKeys.map((el) => {
        if (el.text === currentKey.text) {
          return {
            ...el,
            keyCode: e.code,
            keyText: e.code,
          };
        }
        return el;
      });

      setLocalKeys(mapped);
      setKeys(mapped).then(() => {
        setCurrentKey({});
        setready(false);
        // window.location.reload()
      });
    },
    [currentKey, ready]
  );
  useEffect(() => {
    let elem = document.querySelector("#exampleModal")

    if (ready) {
      elem.addEventListener("keydown", mappit, false);
    }

    return () => {
      elem.removeEventListener("keydown",mappit, false);
    };
  }, [ready]);

  
  return (
    <div className={`col-12 ${show ? "d-flex" :"d-none"} flex-column justify-content-center align-items-center text-white`}>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-dark">Press Any Key...</div>
            <div class="modal-footer d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-secondary pe-2 ps-2 p-1"
                onClick={() => {
                  setready(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {localKeys.map((el) => {
        return (
          <Shortcut
            icon={el.icon}
            keyText={el.keyText}
            text={el.text}
            key={el.text}
            handleClick={setCurrentKey}
            keyCode={el.keyCode}
            item={el}
            setReady={setready}
          />
        );
      })}
    </div>
  );
};
export default KeyboardShortcuts;
