import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Editor from "./Components/Editor";
import Login from "./Components/Login";
import OutputPrompt from "./Components/OutputPrompt";
import Prompter from "./Components/Prompter";
import View from "./View";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";

const AppRouter = () => {
  const [scrollPosition, setScrollPosition] = useState({});

  const handleScroll = useCallback((event) => {
    const elem = document.querySelector(".DraftEditor-root");

    setScrollPosition({y: elem.scrollTop });
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/app" element={<View setScrollPosition={handleScroll} />} />

        {/* <Route exact path="/editor" element={<Editor />} />
        <Route exact path="/prompter" element={<Prompter />} /> */}
        <Route exact path="/output" element={<OutputPrompt scrollPosition={scrollPosition} />} />
        <Route exact path="/" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
