import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { getUserScripts, removeDrafts, updateReadOnly } from "../../firebase";
import { setCurrentDocID, setDeletDocID, setDocData, setDriveLink, setFunctionsList, setReadonly, setRefreshData } from "../../Redux/Utils";
import { getPreviewText, loadDocToEditor } from "../../Utils";
import "./ScriptList.css";
const handleLockClick = async (docID, readonly) => {
  await updateReadOnly(docID, !readonly);
  // loadDoc();
};
const Script = ({ name, createdAt, updatedAt, scriptObj }) => {
  console.log({ name, createdAt, updatedAt, scriptObj })
  const [deleteDoc, setDeleteDoc] = useState(false);
  const dispatch = useDispatch();

  const loadDoc = useCallback(() => {
    window.sessionStorage.setItem("currentDocID", scriptObj.id);
    setCurrentDocID(dispatch, scriptObj.id);
    setDocData(dispatch, scriptObj);
  }, [scriptObj]);

  const [previewText, setpreviewText] = useState(false);

  useEffect(() => {
    if (deleteDoc) {
      setDeletDocID(dispatch, scriptObj.id);
      window.$("#deleteModal").modal("show");
      setDeleteDoc(false);
      return;
    }
    setDeleteDoc(false);
    return;
  }, [deleteDoc]);

  useEffect(() => {
    const text = getPreviewText(scriptObj.content);
    setpreviewText(text);
  }, [scriptObj]);

  if (scriptObj.type === "draft") {
    return null;
  }
  return (
    <div
      className="col-12 d-flex flex-column justify-content-start align-items-center text-white scriptShortcutContainer mt-2 hoverSoft smFont"
      data-docid={scriptObj.id}
    >
      <div className="col-10 border-white d-flex flex-column justify-content-evenly align-items-end scriptShortcut pointer">
        <div className="col-12 d-flex flex-column justify-content-center align-items-center h-50" onClick={loadDoc}>
          <span className="text-start w-5 align-self-start " style={{ fontSize: "16px" }}>
            {name}
          </span>
          <span className="text-start align-self-start" style={{ wordBreak: "break-all" }}>
            {previewText}
          </span>
        </div>
        <div className="col-12 d-flex flex-row justify-content-between align-items-center">
          <div className="col-auto d-flex flex-row justify-content-center align-items-center">
            <span className=" text-white-50 me-2">Updated At</span>
            <span className="text-white-50 smFont me-2">{updatedAt?.date}</span>
            <span className="text-white-50 smFont">{updatedAt?.time}</span>
          </div>
          <div className="col-auto d-flex flex-row justify-content-center align-items-center">
            <span className="greenDot d-none" style={{ height: "12px", width: "12px", borderRadius: "50%", backgroundColor: "green" }} />

            <div className="col-auto d-flex justify-content-center align-items-center m-1" onClick={() => handleLockClick(scriptObj.id, scriptObj.readonly)}>
              <img src={scriptObj.readonly ? "/assets/icons/lock-close.svg" : "/assets/icons/lock-open.svg"} height="18" width="18" />
            </div>
            <div className="col-auto d-flex justify-content-center align-items-center" onClick={() => setDeleteDoc(true)}>
              <img src="/assets/icons/trash.svg" height="16" width="16" />
            </div>
          </div>
        </div>
      </div>
      <hr className="m-1" style={{ width: "85%", color: "#f3f3f3", opacity: "0.1" }} />
    </div>
  );
};
const ScriptList = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [listCopy, setListCopy] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const currentDocID = useSelector((state) => state.user.currentDocID);
  const [hasList, sethasList] = useState(false);
  

  useEffect(() => {
    let ref;

    const fireAsync = async () => {
      const setDoc = (docs) => {
        const data = docs.filter((el) => el);
        const id = currentDocID;
        if (id === "undefined") {
          window.sessionStorage.clear();
          window.location.reload();
        }
        if (id) {
          const item = data.filter((el) => el.id == id);
          setDriveLink(dispatch, item[0]?.driveLink || "");
          setReadonly(dispatch, item[0]?.readonly || false);
          try {
            setFunctionsList(dispatch, item[0].functions);
          } catch (err) {}
        }
        setList(data);
        setListCopy(data);
        sethasList(true);
      };
      ref = await getUserScripts("1234567", setDoc);
    };
    fireAsync();

    return () => {
      if (ref) {
        ref();
      }
    };
  }, [currentDocID]);

  useEffect(() => {
    if (list == false) {
      return;
    }

    if (currentDocID) {
      const item = list.filter((el) => el.id == currentDocID);
      loadDocToEditor(item[0]);
      setDocData(dispatch, item[0]);
    }
  }, [currentDocID, hasList]);

  useEffect(() => {
    if (!searchTerm) {
      setListCopy(list);
      return;
    }
    if (searchTerm) {
      const filteredScripts = list.filter((el) => el.name.includes(searchTerm) || el.content.includes(searchTerm));
      setListCopy(filteredScripts);
    }
  }, [searchTerm]);

  return (
    <div className="col-3 d-flex flex-column  align-items-center actionBarBg thelist mt-2">
      <div className="col-12 d-flex flex-column justify-content-start align-items-center actionBarBg">
        <div className="col-10 d-flex flex-column  align-items-center">
          <input
            type="text"
            className="form-control rounded-1 smFont"
            style={{ height: "30px" }}
            placeholder="Search document"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-12 d-flex flex-column  align-items-center scriptsContainer">
          {listCopy == false ? (
            <span className="text-white">No documents found.</span>
          ) : (
            listCopy.map((el) => <Script createdAt={el.createdAt} name={el.name} previewText={el.previewText} updatedAt={el.updatedAt} scriptObj={el} />)
          )}
        </div>
      </div>
    </div>
  );
};
export default ScriptList;
