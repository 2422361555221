import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./Pointer.css";
import { calculatePercentage, sendMonitorCommand } from "../../Utils";
import { createRef } from "react";
import Draggable from "react-draggable"; // The default
import { useCallback } from "react";

const Pointer = ({ dragPosition, monitor1, showTextLine, isDemo }) => {
  const [newPosition, setnewPosition] = useState({ x: 0, y: 0 });
  const outerHeight = useSelector((state) => state.user.outerHeight);
  const outerWidth = useSelector((state) => state.user.outerWidth);
  const [bounds, setBounds] = useState("translate(0px, 0px)");
  const ref = createRef(null);
  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    console.log(scrollTop);
    const elem = document.querySelector(".DraftEditor-root");

    elem.scrollTop = scrollTop;
  };

  const topLayerElementRef = useRef(null);

  useEffect(() => {
    if (isDemo) {
      return;
    }
    console.log("here");
    const elem = document.querySelector(".dragWrapper");

    const handleWheel = (event) => {
      event.preventDefault();
      const { deltaY } = event;
      const elem = document.querySelector(".DraftEditor-root");

      // Calculate the new scrollTop value based on the deltaY value
      const newScrollTop = elem.scrollTop + deltaY;

      // Set the new scrollTop value to the scrollable element
      elem.scrollTop = newScrollTop;
    };

    // Add the wheel event listener to the top layer element
    elem.addEventListener("wheel", handleWheel, { passive: false });

    // Clean up the event listener when the component unmounts
    return () => {
      elem.removeEventListener("wheel", handleWheel);
    };
  }, [topLayerElementRef]);

  const cbLight = (e, data) => {
    console.log(e, data);
    // Save to localStorage
    window.localStorage.setItem("lastPosition", JSON.stringify({ x: data.x, y: data.y }));
  };

  const cb = useCallback(
    (e, data) => {
      sendMonitorCommand(monitor1, "setPointer", { x: 0, y: data.y });
    },
    [monitor1]
  );

  const Backed = useCallback(
    () => (
      <Draggable defaultPosition={{ x: 0, y: dragPosition.y }} axis="y" scale={1} onDrag={cb} bounds={"parent"} defaultClassName="dragWrapper">
        <div className={`d-flex handle flex-row justify-content-start align-items-center ${showTextLine ? "prompterPointer" : "prompterPointerStripped"}`}>
          <img src={"/assets/icons/playGrey.svg"} height="35" width="35" className="handle" />
        </div>
      </Draggable>
    ),
    [dragPosition, showTextLine]
  );
  if (isDemo) {
    return <Backed />;
  }

  let lastPosition = JSON.parse(window.localStorage.getItem("lastPosition")) || { y: 0, x: 0 };
  return (
    <Draggable
      ref={topLayerElementRef}
      defaultPosition={{ x: 0, y: lastPosition.y }}
      axis="y"
      handle=".handle"
      defaultClassName="dragWrapper"
      scale={1}
      onDrag={cb}
      bounds={"parent"}
      onStop={cbLight}
    >
      <div
        className={`d-flex handle flex-row justify-content-start thepointer align-items-center ${showTextLine ? "prompterPointer" : "prompterPointerStripped"}`}
        id="here"
        ref={ref}
      >
        <img src={"/assets/icons/playGrey.svg"} height="35" width="35" className="handle" />
      </div>
    </Draggable>
  );
};
export default Pointer;
