import { useState } from "react";
import "./Vmix.css";
import { useEffect } from "react";
import { resetFuncs, setToast, toggleRowStyle } from "../../Utils";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ColorTrigger from "../ColorTrigger";
import TextTrigger from "../TextTrigger";
import { setEditFunction, setSelectedFunction } from "../../Redux/Utils";
import VmixFunctionList from "vmix-function-list";
import { setFunctionsToDB } from "../../firebase";
import VmixLogin from "../../VmixLogin";
import { useCallback } from "react";
import SettingRow from "../SettingRow";

const AddFunctionRow = ({ setTriggerType, triggerType, selectedFunctionsID, setFunctionObject, setOutFunctionObject, setisEditing }) => {
  const functionsList = useSelector((state) => state.user.functionsList);

  const [remove, setRemove] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (remove) {
      const res = functionsList.filter((el) => el.id != selectedFunctionsID).filter((el) => el.Function);
      console.log(res);
      const currentDocIDLocal = window.sessionStorage.getItem("currentDocID");

      setFunctionsToDB(res, currentDocIDLocal).then(() => {
        setRemove(false);
      });
    }
  }, [remove]);

  useEffect(() => {
    if (edit) {
      const res = functionsList.filter((el) => el.id == selectedFunctionsID);
      console.log(res);
      if (res[0].triggerType === "color") {
        setFunctionObject(res[0]);
        setOutFunctionObject(res[1]);
        setTriggerType("Color trigger");
        console.log(res[0]);
        console.log(res[1]);
      }
      if (res[0].triggerType === "text") {
        setFunctionObject(res[0]);
        setTriggerType("Text trigger");
      }
      setisEditing(true);
      setEdit(false);
    }
  }, [edit]);

  return (
    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-10 col-10 d-flex flex-row justify-content-start align-items-start text-white mb-2">
      <div className="col-10 d-flex flex-row justify-content-center align-items-center">
        <div className="col d-flex flex-row justify-content-center align-items-center">
          <select value={triggerType} onChange={(e) => setTriggerType(e.target.value)} className="form-select rounded-1 p-0 mdFont p-1 m-2">
            {["Color trigger", "Text trigger"].map((el) => {
              return (
                <option className="w-5 midFont pointer" value={el}>
                  {el}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col me-2 d-flex flex-row justify-content-center align-items-center">
          {/* <div className=" col-3 text-white m-2  rounded-1 p-1 fontSm w-5 mdFont  blueBg pointer hoverSoft ">ADD</div> */}
          <div className=" col-3 text-white m-2  rounded-1 p-1 fontSm w-5 mdFont  blueBg pointer hoverSoft " onClick={() => setEdit(true)}>
            EDIT
          </div>
          <div className=" col-3 text-white m-2  rounded-1 p-1 fontSm w-5 mdFont  blueBg pointer hoverSoft " onClick={() => setRemove(true)}>
            DELETE
          </div>
        </div>
      </div>
    </div>
  );
};
const BackedView = ({
  vmixObject,
  addallFunctions,
  setAddAllFunctions,
  allFunctions,
  isEditing,
  setisEditing,
  setAllFunctions,
  textFunctions,
  colorFunctions,
  triggerType,
}) => {
  if (triggerType === "color") {
    return (
      <ColorTrigger
        vmixObject={vmixObject}
        addallFunctions={addallFunctions}
        setAddAllFunctions={setAddAllFunctions}
        setAllFunctions={setAllFunctions}
        allFunctions={allFunctions}
        externalFunctions={colorFunctions}
        isEditing={isEditing}
        setIsEditing={setisEditing}
      />
    );
  }
  return (
    <TextTrigger
      allFunctions={allFunctions}
      vmixObject={vmixObject}
      addallFunctions={addallFunctions}
      setAddAllFunctions={setAddAllFunctions}
      setAllFunctions={setAllFunctions}
      externalFunctions={textFunctions}
      isEditing={isEditing}
      setIsEditing={setisEditing}
    />
  );
};
const Vmix = ({ showPanel }) => {
  const functionsList = useSelector((state) => state.user.functionsList);

  const dispatch = useDispatch();
  const [triggerType, setTriggerType] = useState("color");
  const [isEditing, setisEditing] = useState(false);
  const [allFunctions, setAllFunctions] = useState([]);
  const [addallFunctions, setAddAllFunctions] = useState(false);
  const currentDocID = useSelector((state) => state.user.currentDocID);
  const [done, setdone] = useState(false);
  const [colorFunctions, setcolorFunctions] = useState({});
  const [textFunctions, settextFunctions] = useState({});
  const [deleteFunc, setdeleteFunc] = useState(false);
  const loginToVmix = useSelector((state) => state.user.loginToVmix);
  const loggedToVmix = useSelector((state) => state.user.loggedToVmix);

  const vmixObject = useSelector((state) => state.user.vmixObject);

  const changeTriggerColor = useCallback(() => {
    setTriggerType("color");
    setcolorFunctions([]);
  });
  const changeTriggerText = useCallback(() => {
    setTriggerType("text");
    settextFunctions([]);
  });
  useEffect(() => {
    if (deleteFunc) {
      const item = document.querySelector(".selectedLine");
      if (!item) {
        setdeleteFunc(false);
        return;
      }

      const id = item.dataset.rowid;
      if (!id) {
        setdeleteFunc(false);
        return;
      }

      const all = functionsList.filter((el) => el.id !== id);
      console.log(all);
      setFunctionsToDB(all, currentDocID).then(() => {
        setdeleteFunc(false);
      });
    }
  }, [deleteFunc]);

  useEffect(() => {
    if (allFunctions == false) {
      return;
    }

    const item = functionsList.filter((el) => el.id !== allFunctions[0].id);

    const total = [...allFunctions, ...item];
    console.log(total);
    console.log("functionsList", functionsList);
    console.log("allFunctions", allFunctions);

    setFunctionsToDB(total, currentDocID).then(() => {
      setAllFunctions([]);
      setcolorFunctions([]);
      settextFunctions([]);
    });
  }, [allFunctions]);


  useEffect(() => {
    const resetFunc = function () {
      setcolorFunctions([]);
      settextFunctions([]);
    };
    document.addEventListener("resetFunc", resetFunc);
    return () => {
      document.removeEventListener("resetFunc", resetFunc);
    };
  }, []);

  
  const handleEdit = (e) => {
    toggleRowStyle(e);
    setisEditing(false);
    const id = e.currentTarget.dataset.rowid;
    const functions = functionsList.filter((el) => el.id === id);
    const elem = document.querySelector(".selectedLine");
    if (!elem) {
      setcolorFunctions([]);
      settextFunctions([]);
      return;
    }
    console.log(elem);
    if (functions == false) {
      return;
    }
    console.log(functions);
    functions[0].triggerType === "color" ? setcolorFunctions(functions[0]) : settextFunctions(functions[0]);
    functions[0].triggerType === "color" ? setTriggerType("color") : setTriggerType("text");
  };

  return (
    <div
      className={`${showPanel ? "d-flex" : "d-none"} flex-column justify-content-center align-items-center p-2`}
      style={{ width: "97%", height: "25%", backgroundColor: "#111111",marginTop:"100px" }}
    >
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <VmixLogin />
        <SettingRow setIsEditing={setisEditing} onclick={handleEdit} setAllFunctions={setAllFunctions} allFunctions={allFunctions} />
        <div className="col-12 d-flex flex-row justify-content-between align-items-center mb-5">
          <div className="col-5 d-flex flex-row justify-content-center align-items-center">
            <div
              style={{ borderRadius: "2px", fontSize: "14px" }}
              className="col d-flex flex-row justify-content-center align-items-center lightBlue me-3  text-white w-5  shadow-sm pointer hoverSoft"
              onClick={changeTriggerColor}
            >
              Add Color Trigger
            </div>
            <div
              style={{ borderRadius: "2px", fontSize: "14px" }}
              className="col d-flex flex-row justify-content-center align-items-center lightBlue  text-white w-5  shadow-sm pointer hoverSoft"
              onClick={changeTriggerText}
            >
              Add Text Trigger
            </div>
          </div>
          <div className="col-4 d-flex flex-row justify-content-center align-items-center">
            <div
              style={{ borderRadius: "2px", fontSize: "14px" }}
              className="col d-flex flex-row justify-content-center align-items-center lightBlue me-3  text-white w-5  shadow-sm pointer hoverSoft"
              onClick={() => setAddAllFunctions(true)}
            >
              Add
            </div>
            <div
              style={{ borderRadius: "2px", fontSize: "14px" }}
              onClick={() => setAddAllFunctions(true)}
              className="col d-flex flex-row justify-content-center align-items-center lightBlue me-3  text-white w-5  shadow-sm pointer hoverSoft"
            >
              Edit
            </div>
            <div
              onClick={() => setdeleteFunc(true)}
              style={{ borderRadius: "2px", fontSize: "14px" }}
              className="col d-flex flex-row justify-content-center align-items-center lightBlue me-3  text-white w-5  shadow-sm pointer hoverSoft"
            >
              Delete
            </div>
          </div>
        </div>
        <BackedView
          addallFunctions={addallFunctions}
          allFunctions={allFunctions}
          isEditing={isEditing}
          setAddAllFunctions={setAddAllFunctions}
          setAllFunctions={setAllFunctions}
          setisEditing={setisEditing}
          colorFunctions={colorFunctions}
          textFunctions={textFunctions}
          vmixObject={vmixObject}
          triggerType={triggerType}
        />
      </div>
    </div>
  );
};
export default Vmix;
