import { useRef } from "react";
import { callVmix, getElementsRelated } from "../Utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useFunctionsHook = (editorReady, play, speed) => {
  const functionsList = useSelector((state) => state.user.functionsList);

  const readyColorFunctions = useRef([]);
  const readyTextFunctions = useRef([]);
  useEffect(() => {
    if (!editorReady) {
      return;
    }
    getElementsRelated(functionsList, readyColorFunctions, readyTextFunctions);
  }, [functionsList, editorReady, speed, play]);

  useEffect(() => {
    if (readyColorFunctions.current == false && readyTextFunctions.current == false) {
      return;
    }

    let intreval;
    intreval = setInterval(() => {
      const thepointer = document.querySelector(".thepointer");
      const layerRect = thepointer.getBoundingClientRect();
      readyColorFunctions.current.forEach((el) => {
        el.elems.forEach((els) => {
          let target = els.getBoundingClientRect();
          if (!els.classList.contains("doneAlready")) {
            // console.log("layerRect.bottom",layerRect.bottom)
            // console.log(" target.bottom", target.bottom)
            // console.log("target.top",target.top)
            // console.log("layerRect.top",layerRect.top)


            if (target.top < layerRect.bottom && target.bottom > layerRect.top) {
              console.log(els);
              console.log("GOING IN FUNCTION")
              const inF = el.functions
                .filter((item) => item.type === "in")
                .forEach((elem) => {
                  callVmix(
                    `Function=${elem.Function}&Input=${elem.Input}&Duration=${elem.Duration}&Delay=${elem.Delay}&Mix=${elem.Mix}`,
                    elem.Delay,
                    el.active
                  );
                });
              console.log("doing this", els);
              els.classList.add("doneAlready");
            }
          }
          if (!els.classList.contains("outDoneAlready")) {
            if (layerRect.top > target.bottom) {
              console.log(els);
              console.log("GOING OUT FUNCTION")

              const outF = el.functions
                .filter((item) => item.type === "out")
                .forEach((elem) => {
                  callVmix(
                    `Function=${elem.Function}&Input=${elem.Input}&Duration=${elem.Duration}&Delay=${elem.Delay}&Mix=${elem.Mix}`,
                    elem.Delay,
                    el.active
                  );
                });

              console.log("out", els);
              els.classList.add("outDoneAlready");
            }
          }
        });
      });
    }, 500);
    return () => {
      if (intreval) {
        clearInterval(intreval);
      }
    };
  }, [readyColorFunctions.current]);

  useEffect(() => {
    if (readyColorFunctions.current == false && readyTextFunctions.current == false) {
      return;
    }

    let intreval;
    intreval = setInterval(() => {
      const thepointer = document.querySelector(".thepointer");
      const layerRect = thepointer.getBoundingClientRect();
      readyTextFunctions.current.forEach((el) => {
        el.elems.forEach((els) => {
          let target = els.getBoundingClientRect();
          if (!els.classList.contains("doneAlreadyText")) {
            if (target.top < layerRect.bottom && target.bottom > layerRect.top) {
              const inF = el.functions
                .filter((item) => item.type === "in")
                .forEach((elem) => {
                  callVmix(
                    `Duration=${elem.Duration}&Input=${encodeURIComponent(elem.Input)}&Delay=${elem.Delay}&Function=${elem.Function}&Mix=${elem.Mix}`,
                    elem.Delay,
                    el.active
                  );
                });
              console.log("doing this", inF);
              els.classList.add("doneAlreadyText");
            }
          }
          if (!els.classList.contains("outDoneAlreadyText")) {
            if (layerRect.top > target.bottom) {
              const outF = el.functions
                .filter((item) => item.type === "out")
                .forEach((elem) => {
                  callVmix(
                    `Duration=${elem.Duration}&Input=${elem.Input}&Delay=${elem.Delay}&Function=${elem.Function}&Mix=${elem.Mix}`,
                    elem.Delay,
                    el.active
                  );
                });

              console.log("out", outF);
              els.classList.add("outDoneAlreadyText");
            }
          }
        });
      });
    }, 500);
    return () => {
      if (intreval) {
        clearInterval(intreval);
      }
    };
  }, [readyTextFunctions.current]);

  return null;
};

export { useFunctionsHook };
