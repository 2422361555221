import { useEffect } from "react";
import { useState } from "react";
import FunctionList from "../../FunctionList";
import InputsList from "../InputsList";
import { useDispatch, useSelector } from "react-redux";
import SampleColor from "../SampleColor";
import { v4 } from "uuid";
import { setToast } from "../../Utils";
import { useCallback } from "react";

const FunctionIn = ({ type, inFunctionsList, setInFunctionsList, listElem, factory, idx, id, addallFunctions, setinAmount }) => {
  const dispatch = useDispatch();
  const [selectedFunctionLocal, setselectedFunctionLocal] = useState([]);
  const [selectedFunction, setSelectedInFunction] = useState({});
  const vmixObject = useSelector((state) => state.user.vmixObject);
  const [changedManually, setchangedManually] = useState(false);

  const [functionObject, setFunctionObject] = useState(
    listElem || {
      Duration: "500",
      Delay: "0",
      Mix: "1",
      Function: "",
      Input: "",
      id: "",
      triggerType: "color",
      textTrigger: "",
      colorTrigger: "",
      type: type,
    }
  );

  useEffect(() => {
    if (!selectedFunction) {
      return;
    }
    const value = Object.entries(selectedFunction?.parameters || {});
    setselectedFunctionLocal(value);
  }, [selectedFunction]);

  useEffect(() => {
    if (addallFunctions) {
      // total = [...inFunctionsList, functionObject];

      setFunctionObject({
        Duration: "500",
        Delay: "0",
        Mix: "1",
        Function: "",
        Input: "",
        id: "",
        triggerType: "color",
        textTrigger: "",
        colorTrigger: "",
        type: type,
      });
    }
  }, [addallFunctions]);
  const removeOne = useCallback(() => {
    const item = inFunctionsList.filter((el) => el.id == id);
    if (item == false) {
      if (setinAmount) {
        setinAmount((prev) => {
          return prev.filter((el) => el !== id)
        });
      }
      return;
    }
    const without = inFunctionsList.filter((el) => el.id != id);
    setInFunctionsList(without);
  }, [id, inFunctionsList,setinAmount]);

  useEffect(() => {
    const functionID = functionObject.id ? functionObject.id : v4();
    const copy = { ...functionObject, id: functionID };
    let all;
    all = inFunctionsList.map((el) => {
      if (el.id === functionID) {
        return copy;
      }
      return el;
    });
    const item = all.filter((el) => el.id === functionID);

    if (item == false) {
      all = [...all, copy];
    }
    if (functionObject.Function && changedManually) {
      setInFunctionsList(all);
    }
  }, [functionObject]);

  return (
    <div
      className={"col-12 d-flex flex-column justify-content-start align-items-start bg-dark mt-2 functionContainer"}
      onMouseEnter={() => {
        setchangedManually(true);
      }}
    >
      <div className="col-12 d-flex flex-row justify-content-center align-items-center text-white position-relative" style={{ backgroundColor: "#040D1A" }}>
        {!factory && (
          <span className="position-absolute start-0 p-2 hoverSoft pointer" onClick={removeOne}>
            X
          </span>
        )}
        {factory ? "" : `${idx}.`}Function {type}
      </div>
      <div className="w-100 d-flex flex-row justify-content-start align-items-start align-self-start p-0 ms-2">
        <FunctionList
          key="asdhoaoisdhsdfsdfsdfioashd"
          setFunctionObject={setFunctionObject}
          functionObject={functionObject}
          withTitle={true}
          setSelectedFunction={setSelectedInFunction}
        />
        
      </div>
      <div className="w-100 d-flex flex-column justify-content-start align-items-start">
        {selectedFunctionLocal != false &&
          selectedFunctionLocal.map((el) => {
            if (!el) {
              return null;
            }
            const paramter = el[1];
            const type = paramter.type;
            if (paramter.type === "input") {
              return <InputsList withTitle={true} onchange={setFunctionObject} val={functionObject} {...vmixObject} />;
            }
            return (
              <div className="col-5 ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput">
                <span className="text-white">{el[0]}</span>
                <input
                  type="text"
                  onChange={(e) =>
                    setFunctionObject((prev) => {
                      return { ...prev, [el[0]]: e.target.value };
                    })
                  }
                  value={functionObject[el[0]]}
                  className="form-control rounded-1 p-0 mdFont p-1"
                  placeholder={type}
                />
              </div>
            );
          })}
        {/* <div className="col-5 ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput">
          <span className="text-white">Duration</span>
          <input
            type="text"
            value={functionObject.Duration}
            onChange={(e) =>
              setFunctionObject((prev) => {
                return { ...prev, Duration: e.target.value };
              })
            }
            className="form-control rounded-1 p-0 mdFont p-1"
            placeholder="500"
          />
        </div> */}
        <div className="col-5 ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput">
          <span className="text-white">Delay</span>
          <input
            value={functionObject.Delay}
            onChange={(e) =>
              setFunctionObject((prev) => {
                return { ...prev, Delay: e.target.value };
              })
            }
            type="text"
            className="form-control rounded-1 p-0 mdFont p-1"
            placeholder="0"
          />
        </div>
        <div className="col-5 ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput">
          <span className="text-white">Mix</span>
          <input
            value={functionObject.Mix}
            onChange={(e) =>
              setFunctionObject((prev) => {
                return { ...prev, Mix: e.target.value };
              })
            }
            type="text"
            className="form-control rounded-1 p-0 mdFont p-1"
            placeholder="1"
          />
        </div>
      </div>
    </div>
  );
};

export default FunctionIn;
