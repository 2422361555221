import { useEffect, useState } from "react";
import "./Toast.css";
const handleBorderState = (state) => {
  if (state == "success") {
    return "success";
  }
  if (state == "warning") {
    return "warning";
  }
  if (state == "failed") {
    return "failed";
  }
};
const Toast = () => {
  const [localState, setLocalState] = useState("");
  const [localtext, setLocaltext] = useState("");

  useEffect(() => {
    document.addEventListener("setToast", (e) => {
      e.stopImmediatePropagation();
      setLocalState(e.detail.state);
      setLocaltext(e.detail.text);
      setTimeout(() => {
        setLocalState("");
      }, 5000);
    });

    return () => {
      document.removeEventListener("setToast", (e) => {});
    };
  }, []);
  if (!localState) {
    return null;
  }
  return (
    <div
      className="col-12 d-flex flex-row justify-content-center align-items-center topToast shadow-sm"
      style={{ zIndex: "9999"}}
      onClick={() => setLocalState("")}
    >
      <div
        className={`toastContainer d-flex flex-column justify-content-center align-items-center mt-1 btnShadow position-relative slide-bottom border-3`}
      >
      <div className="col-12 position-absolute top-0" style={{backgroundColor:"#3B7CBF",height:"25%"}}/>
        <span className="text-center ">{localtext}</span>
      </div>
    </div>
  );
};
export default Toast;
