import { RichUtils } from "draft-js";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../constants";
import { setTextColorHex } from "../../Redux/Utils";
import { createCommand, createCommandToggle, removeBgStyles } from "../../Utils";

const ColorItem = ({ icon, command, editorState, setEditorState, tooltipText, itemType, classItem, undoCommand }) => {
  const [showPallete, setShowPallete] = useState(false);
  const textColorHex = useSelector((state) => state.user.textColorHex);
  const ref = createRef();

  const handleClick = (name) => {
    if (itemType === "text") {
      const command = `text${name}`;
      console.log(command);
      createCommand(editorState, setEditorState, command);
    } else {
      const command = `bg${name}`;
      console.log(command);
      createCommand(editorState, setEditorState, command);
    }
  };

  useEffect(() => {
    if (textColorHex) {
      createCommand(editorState, setEditorState, command);
    }
  }, [textColorHex]);

  useEffect(() => {
    if (showPallete) {
      document.addEventListener(
        "click",
        (e) => {
          if (!e.target.classList.contains(classItem)) {
            setShowPallete(false);
          } else {
            // console.log("yes");
          }
        },
        false
      );
    }

    return () => {
      document.removeEventListener("click", () => {}, false);
    };
  }, [showPallete]);

  return (
    <div className={`col-auto d-flex flex-row ps-1 pe-1 ${classItem}`} style={showPallete ? { zIndex: "9999" } : {}}>
      <div
        onClick={() => {
          setShowPallete((prev) => !prev);
        }}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-title={tooltipText}
        className={`col-auto pointer  position-relative ${classItem}`}
      >
        <img src={`./assets/icons/${icon}`} className={classItem} height="25" width="25" />
        {showPallete && (
          <div className="colorPallete d-flex flex-row flex-wrap justify-content-evenly shadow-sm" ref={ref}>
            {colors.map((el) => {
              return (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick(el.name);
                    setShowPallete(false);
                  }}
                  className="colorItem  m-1 hoverSoft"
                  style={{ backgroundColor: `#${el.color}` }}
                />
              );
            })}
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (undoCommand === "defaultBg") {
                  removeBgStyles(editorState, setEditorState);
                }
                if (undoCommand === "defaultText") {
                  createCommand(editorState, setEditorState, undoCommand);
                }
                setShowPallete(false);
              }}
              className="colorItem  m-1 hoverSoft text-dark w-5"
              style={{ backgroundColor: `lightGrey` }}
            >
              X
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default ColorItem;
