import { useEffect, useState } from "react";
const useEditorReadyHook = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, 1500);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
  return { ready };
};
export default useEditorReadyHook;
