import { convertToRaw } from "draft-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { savePrompt, updateCommand, updatePrompt } from "../../firebase";
import { setCurrentDocID, setRefreshData, setSaveMode } from "../../Redux/Utils";
import { setToast } from "../../Utils";
import "./SaveButton.css";
import { v4 } from "uuid";

const SaveButton = ({ editorState }) => {
  const [saveState, setSaveState] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [fileName, setfileName] = useState("");
  const isEditing = useSelector((state) => state.user.isEditing);
  const currentDocID = useSelector((state) => state.user.currentDocID);
  const saveMode = useSelector((state) => state.user.saveMode);
  const [savesSuccess, setsavesSuccess] = useState(false);
  const driveLink = useSelector((state) => state.user.driveLink);

  const dispatch = useDispatch();

  const handleSaveClick = () => {
    if (isEditing) {
      const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      
      updatePrompt(currentDocID, content).then((res) => {
        setToast({ state: "success", text: "Saves succsfully!" });
        // setCurrentDocID(dispatch, res);
        setSaveState(false);
        return;
      });
      return;
    }
    setSaveState(true);
  };

  useEffect(() => {
    if (submit) {
      if (!fileName || fileName.length <= 2) {
        setToast({ state: "warning", text: "Please give a proper name to the file." });
        setSubmit(false);

        return;
      }
      setSaveMode(dispatch, false);
      setsavesSuccess(true);

      const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      savePrompt({ content, name: fileName, type: "prod", driveLink }).then((res) => {
        console.log("res", res);
        window.sessionStorage.setItem("currentDocID", res);
        setCurrentDocID(dispatch, res);
        setSubmit(false);
        setSaveState(false);
        return;
      });
    }
  }, [submit]);



  useEffect(() => {
    let interval;
    if (savesSuccess) {
      interval = setTimeout(() => {
        setsavesSuccess(false);
      }, 1500);
    }
    if (isEditing && saveState) {
      setSaveState(false);
    }

    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
  }, [isEditing, savesSuccess]);

  if (saveState || saveMode) {
    return (
      <div className="col-12 d-flex flex-row justify-content-center align-items-center" style={{height:"30px"}}>
        <div
          className="blueBg  pe-3 ps-3 rounded-1 text-center col midFont  pointer    d-flex w-5 justify-content-center align-items-center m-1"
          onClick={() => setSubmit(true)}
        >
          save
        </div>
        <input className=" smFont form-control  p-1 rounded-1" onChange={(e) => setfileName(e.target.value)} type="text" placeholder="Enter script name" />
      </div>
    );
  }
  return (
    <div className="col-12 d-flex flex-row justify-content-end align-items-center" onClick={handleSaveClick}>
      <div className="blueBg col rounded pointer hoverSoft ps-3  pe-3">{savesSuccess ? "Saved succesfully" :"Save"}</div>
    </div>
  );
};
export default SaveButton;
