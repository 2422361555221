import { createRef, useEffect, useState } from "react";
import styleMap from "../../Utils/styleMap";
import SettingsPopup from "../SettingsPopup";
import "./Prompter.css";
import { DefaultDraftBlockRenderMap, Editor, EditorState, convertFromRaw } from "draft-js";
import PrompterController from "../PrompterContoller";
import { getSpeicificDoc, getStylesRef, removeDrafts } from "../../firebase";
import Pointer from "../Pointer";
import { useDispatch, useSelector } from "react-redux";
import ColorLayer from "../../ColorLayer";
import Immutable from "immutable";
import useEditorReadyHook from "../../hooks/readyHook";
import {
  CustomBlock,
  addBlockWithHeightToEditor,
  addLineBreakAfterBackground,
  addSpaceAfterDot,
  flipPageHorizontal,
  flipPageVerical,
  handleDotSpace,
  handlePointerToggle,
  sendMonitorCommand,
  setPageMargin,
} from "../../Utils";
import {
  setAllCaps,
  setBreakOnSentenceEnd,
  setBreakOnTriggerColor,
  setDualMonitor1,
  setDualMonitor2,
  setFlipHorizontal1,
  setFlipVertical1,
  setLineSpacing,
  setOuterHeight,
  setOuterWidth,
  setRemoveListener,
  setRunAgain,
  setScreenMargin,
  setShowTextLine,
  setTextSize,
} from "../../Redux/Utils";
import OnRefresh from "../../OnRefresh";
import { setView } from "../../Redux/Slice";
import Line from "../Line";
import ButtonLoader from "../ButtonLoader";
const Prompter = ({ setScrollPosition }) => {
  const editorRef = createRef();
  const [showSettings, setShowSettings] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [dragPosition, setDragPosition] = useState(0);
  const [isClicked, setisClicked] = useState(false);
  const [currentMarker, setCurrentMarker] = useState(-1);
  const lineSpacing = useSelector((state) => state.user.lineSpacing);
  const [monitor1, setMonitor1] = useState(false);
  const [monitor2, setMonitor2] = useState(false);
  const [isEditingSettings, setisEditingSettings] = useState(false);

  const dualMonitor1 = useSelector((state) => state.user.dualMonitor1);
  const showTextLine = useSelector((state) => state.user.showTextLine);
  const breakOnTriggerColor = useSelector((state) => state.user.breakOnTriggerColor);
  const breakOnSentenceEnd = useSelector((state) => state.user.breakOnSentenceEnd);
  const textDirection = useSelector((state) => state.user.textDirection);
  const textRTL = useSelector((state) => state.user.textRTL);
  const currentDocID = useSelector((state) => state.user.currentDocID);
  const outerHeight = useSelector((state) => state.user.outerHeight);
  const outerWidth = useSelector((state) => state.user.outerWidth);
  const flipHorizontal = useSelector((state) => state.user.flipHorizontal1);
  const flipVertical = useSelector((state) => state.user.flipVertical1);
  const dualMonitor = useSelector((state) => state.user.dualMonitor1);
  const [styles, setstyles] = useState({});
  const dispatch = useDispatch();
  const { ready } = useEditorReadyHook();
  const runAgain = useSelector((state) => state.user.runAgain);
  const docData = useSelector((state) => state.user.docData);

  const [shouldAddBlock, setshouldAddBlock] = useState(false);

  useEffect(() => {
    getSpeicificDoc(currentDocID).then((res) => {
      const doc = res;
      if (doc.hasOwnProperty("lineSpacing")) {
        setLineSpacing(dispatch, parseFloat(doc.lineSpacing));
      }
      if (doc.hasOwnProperty("margin")) {
        setScreenMargin(dispatch, parseInt(doc.margin));
      }
      if (doc.hasOwnProperty("textSize")) {
        setTextSize(dispatch, parseFloat(doc.textSize));
      }
      if (doc.hasOwnProperty("breakColor")) {
        setBreakOnTriggerColor(dispatch, doc.breakColor);
      }
      if (doc.hasOwnProperty("breakSentence")) {
        setBreakOnSentenceEnd(dispatch, doc.breakSentence);
      }
      if (doc.hasOwnProperty("allCaps")) {
        setAllCaps(dispatch, doc.allCaps);
      }
      if (doc.hasOwnProperty("flipHorizontal")) {
        setFlipHorizontal1(dispatch, doc.flipHorizontal);
      }
      if (doc.hasOwnProperty("flipVertial")) {
        setFlipVertical1(dispatch, doc.flipVertial);
      }
      if (doc.hasOwnProperty("showTextLine")) {
        setShowTextLine(dispatch, doc.showTextLine);
      }

      if (!res.content) {
        setEditorState(EditorState.createEmpty());
        return;
      } else {
        const parsed = JSON.parse(doc.content);
        const content = convertFromRaw(parsed);
        setEditorState(EditorState.createWithContent(content));
      }
    });
  }, []);

  useEffect(() => {
    if (dualMonitor1) {
      flipPageHorizontal(false);
      flipPageVerical(false);
    } else {
      flipPageHorizontal(flipHorizontal);
      flipPageVerical(flipVertical);
    }
  }, [flipHorizontal, flipVertical, dualMonitor1]);

  useEffect(() => {
    // styles
    let ref;
    const fireAsync = async () => {
      ref = await getStylesRef((doc) => {
        setstyles(doc);
      });
    };
    const closePrompter = function () {
      setView(dispatch, 1);
    };
    const handler = function (e) {
      console.log(e.detail);
      setOuterHeight(dispatch, e.detail.height);
      setOuterWidth(dispatch, e.detail.width);
    };
    window.addEventListener("closePrompter", closePrompter);
    fireAsync();
    window.addEventListener("newWindowResize", handler);

    return () => {
      window.removeEventListener("closePrompter", closePrompter);

      window.removeEventListener("newWindowResize", handler);
      if (ref) {
        ref();
      }
    };
  }, []);

  useEffect(() => {
    if (ready && lineSpacing > 0) {
      const elem = document.querySelector("[data-contents]");
      elem.style.lineHeight = `${lineSpacing}px`;
    }
  }, [ready, lineSpacing]);

  useEffect(() => {
    if (ready) {
      addLineBreakAfterBackground(editorState, setEditorState, breakOnTriggerColor);
    }
  }, [ready, breakOnTriggerColor]);

  useEffect(() => {
    let timeout;
    if (ready) {
      setTimeout(() => {
        addBlockWithHeightToEditor("start");
      }, 500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [ready]);


  useEffect(() => {
    if (ready) {
      handleDotSpace(breakOnSentenceEnd, editorState, setEditorState,dispatch);
    }
  }, [ready, breakOnSentenceEnd]);

  useEffect(() => {
    handlePointerToggle(showTextLine);
  }, [showTextLine]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    setOuterHeight(dispatch, outerHeight + 1);
    setOuterWidth(dispatch, outerWidth + 1);
  }, [ready]);
  useEffect(() => {
    if (!runAgain) {
      return;
    }
    if (!docData || !docData.hasOwnProperty("type")) {
      return;
    }
    const doc = docData;
    if (doc.content) {
      const parsed = JSON.parse(doc.content);
      const content = convertFromRaw(parsed);
      const data = EditorState.createWithContent(content);
      setEditorState(data);
    }
    if (!doc.content) {
      const data = EditorState.createEmpty();
      setEditorState(data);
    }
    return;
  }, [runAgain]);

  return (
    <div
      className="col-12 d-flex flex-column justify-content-between align-items-center position-relative actionBarBg"
      style={{ height: "100vh", overflowY: "hidden" }}
    >
      <ButtonLoader state={!ready} className={"position-absolute top-0 left-0 right-0 bottom-0"} />

      <Line width={20} />
      <OnRefresh />
      <div
        className={`col-12 d-flex flex-column alrighty justify-content-center align-items-center wallakH position-relative withPointer prompterArea m-auto`}
        style={dualMonitor1 ? { fontSize: "35px", height: `${outerHeight}px`, width: `${outerWidth}px` } : { fontSize: "35px" }}
        onMouseDown={(e) => {
          if (showSettings) {
            setShowSettings(false);
          }
        }}
      >
        <Pointer
          setisClicked={setisClicked}
          isClicked={isClicked}
          dragPosition={dragPosition}
          editorReady={ready}
          monitor1={monitor1}
          monitor2={monitor2}
          showTextLine={showTextLine}
        />
        <Editor
          readOnly={true}
          ref={editorRef}
          customStyleMap={styles}
          editorState={editorState}
          onChange={setEditorState}
          textAlignment={textDirection}
          textDirectionality={textRTL}
        />
      </div>
      <SettingsPopup
        showPopup={showSettings}
        setMonitor2={setMonitor2}
        setMonitor1={setMonitor1}
        monitor2={monitor2}
        monitor1={monitor1}
        editorState={editorState}
        setEditorState={setEditorState}
        setisEditingSettings={setisEditingSettings}
        ready={ready}
      />
      <PrompterController
        monitor2={monitor2}
        editorReady={ready}
        setShowSettings={setShowSettings}
        editorState={editorState}
        setCurrentMarker={setCurrentMarker}
        setEditorState={setEditorState}
        monitor1={monitor1}
        setMonitor2={setMonitor2}
        setMonitor1={setMonitor1}
        setScrollPosition={setScrollPosition}
      />
    </div>
  );
};
export default Prompter;
