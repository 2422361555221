import { useEffect } from "react";
import { calculatePercentage } from "../../Utils";

const Input = ({ show, text, handleChange, valueProp, stepType, minType, maxType, dirType, id, setShow, classItem }) => {
  useEffect(() => {
    if (show) {
      document.addEventListener(
        "mouseup",
        (e) => {
          setShow(false);
          // console.log("yes");
        },
        false
      );
    }

    return () => {
      document.removeEventListener("click", () => {}, false);
    };
  }, [show]);

  if (!show) {
    return null;
  }
  if (text === "Text Size") {
    window.localStorage.setItem("textSize", valueProp);
  }
  if (text === "Speed") {
    window.sessionStorage.setItem("scrollSpeed", valueProp);
  }
  return (
    <div className={`floatingInput d-flex flex-column justify-content-evenly align-items-center nonPointer rounded ${classItem}`} id={id}>
      <div className="col-12 d-flex flex-row justify-content-center align-items-center optionsFont">
        <span className="optionsFont">{text}</span>
        <span className=" m-1 optionsFont">{calculatePercentage(valueProp, maxType)}%</span>
      </div>

      <div className="col-11 d-flex justify-content-center align-items-center">
        <input
          dir={dirType}
          min={minType}
          max={maxType}
          step={stepType}
          value={valueProp}
          type="range"
          className="form-range"
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Input;
