import { convertToRaw } from "draft-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromptRef, removeDrafts, updatePrompt } from "../firebase";
import { debounce, isEqual } from "lodash";
import { clearLocal, serverUrl, setToast } from "../Utils";
import {
  setBreakOnSentenceEnd,
  setBreakOnTriggerColor,
  setDualMonitor1,
  setFlipHorizontal1,
  setFlipVertical1,
  setInputs,
  setLineSpacing,
  setLoggedToVmix,
  setLoginToVmix,
  setScreenMargin,
} from "../Redux/Utils";
import io from "socket.io-client";

const Manager = () => {
  const [needupdate, setneedupdate] = useState(null);
  let lastNum = 0;
  const SAVE_DELAY = 500; // Adjust the delay as needed
  const currentDocID = useSelector((state) => state.user.currentDocID);
  const loginToVmix = useSelector((state) => state.user.loginToVmix);
  const vmixObject = useSelector((state) => state.user.vmixObject);
  const loggedToVmix = useSelector((state) => state.user.loggedToVmix);

  const dispatch = useDispatch();
  const [sok, setsok] = useState(false);

  useEffect(() => {
    const getInputs = function (e) {
      if (sok) {
        console.log("emit");
        sok.emit("dataTransfer", { type: "login", value: { ...vmixObject } });
      }
    };
    const callVmix = function (e) {
      if (sok) {
        console.log("calliong", e.detail);
        sok.emit("dataTransfer", { type: "callVmix", value: { ...vmixObject, function: e.detail } });
      }
    };
    if (sok) {
      document.addEventListener("getInputs", getInputs);
      document.addEventListener("callVmix", callVmix);
    }

    return () => {
      document.removeEventListener("getInputs", getInputs);
      document.removeEventListener("callVmix", callVmix);
    };
  }, [sok]);

  useEffect(() => {
    let socket;
    if (loggedToVmix) {
      socket = io(serverUrl);
      socket.on("dataTransferFrom", (e) => {
        if (e.type == "login") {
          if (e.value) {
            if (!loggedToVmix) {
              setLoggedToVmix(dispatch, true);
            }
            setInputs(dispatch, e.value);
            return;
          }
          setToast({ state: "warning", text: "Connection is not successfule." });
        }
      });
      socket.emit("dataTransfer", { type: "login", value: vmixObject });
      setsok(socket);
      if (!socket.active) {
        setLoginToVmix(dispatch, false);
      }
      return;
    }

    return () => {
      if (socket) {
        socket.disconnect();
        setLoginToVmix(dispatch, false);
      }
    };
  }, [loggedToVmix]);

  useEffect(() => {
    let debounceit;
    if (needupdate) {
      console.log("currentDocID", currentDocID);
      const saveContent = async () => {
        console.log("saving...");
        await updatePrompt(currentDocID, needupdate);
      };
      debounceit = debounce(saveContent, SAVE_DELAY);
      debounceit();
    }

    return () => {
      if (debounceit) {
        debounceit.cancel();
      }
    };
  }, [needupdate]);

  useEffect(() => {
    const func = function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      console.log("currentDocID", currentDocID);
      if (currentDocID) {
        const plainText = e.detail.getPlainText();
        if (lastNum == 0) {
          lastNum = plainText.length;
          console.log(lastNum);
          const content = JSON.stringify(convertToRaw(e.detail));
          console.log("updating");

          setneedupdate(content);
          return;
        }
        if (lastNum !== plainText.length) {
          lastNum = plainText.length;
          if (currentDocID) {
            const content = JSON.stringify(convertToRaw(e.detail));
            console.log("updating");

            setneedupdate(content);
          }
        }
      }
    };
    document.addEventListener("saveOnType", func);

    return () => {
      document.removeEventListener("saveOnType", func);
    };
  }, [currentDocID]);

  useEffect(() => {
    const func = function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();

      const content = JSON.stringify(convertToRaw(e.detail));
      setneedupdate(content);

      console.log("updating");
    };
    document.addEventListener("saveOnChange", func);

    return () => {
      console.log("out");
      document.removeEventListener("saveOnChange", func);
    };
  }, []);
  useEffect(() => {
    clearLocal();
    try {
      // window.monitordata.close()
    } catch {
      console.log("out");
    }
  }, [currentDocID]);


  return null;
};

export default Manager;
