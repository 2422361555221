import { useState } from "react";
import ActionBar from "../ActionBar";
import ScriptList from "../ScriptList";
import TextEditor from "../TextEditor";
import Vmix from "../Vmix";
import "./Editor.css";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import ButtonLoader from "../ButtonLoader";
const Menu = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="col-5 d-flex flex-column justify-content-end align-items-end position-relative">
      {show ? (
        <div
          className="position-absolute  bg-dark col-5 d-flex flex-column justify-content-evenly align-items-center rounded"
          style={{ height: "250px", top: "25px", right: 0 }}
        >
          <span className="align-self-end m-2 col-auto fs-3 position-absolute top-0 pointer" onClick={() => setShow((prev) => !prev)}>
            X
          </span>

          <a href="/editor" className="w-5 fs-2 pointer text-white" style={{ textDecoration: "none" }}>
            Prompter<span className="w-3 fs-2 pointer text-white">API</span>
          </a>
          <span className="w-5 fs-5 pointer blueText hoverSoft" onClick={() => (window.location.href = "/prompter")}>
            Prompter
          </span>
          <span className="w-5 fs-5 pointer hoverSoft" onClick={() => (window.location.href = "/editor")}>
            Script Editor
          </span>
        </div>
      ) : (
        <img src="/assets/icons/menu.svg" height="35" width="35" className="pointer" onClick={() => setShow((prev) => !prev)} />
      )}
    </div>
  );
};
const Editor = () => {
  const showPanel = useSelector((state) => state.user.showPanel);

  const Backed = useCallback(() => <Vmix showPanel={showPanel} />, [showPanel]);
  return (
    <div className="col-12 d-flex flex-column justify-content-start align-items-center editorWrapper actionBarBg">
      {/* logos and stuff */}
      <div className="d-flex flex-row justify-content-between align-items-center text-white p-2 col-12">
        <div className="col-auto d-flex flex-row justify-content-center align-items-center ms-4">
          <a href="/editor" className="w-3 fs-2 pointer text-white" style={{ textDecoration: "none" }}>
            Prompter<span className="w-5 fs-2 me-5 pointer text-white">API</span>
          </a>
          {/* <span className="w-4  fs-5 ms-3 pointer blueText hoverSoft" onClick={() => (window.location.href = "/prompter")}>
            Prompter
          </span>
          <span className="w-4  fs-5 ms-3 pointer hoverSoft" onClick={() => (window.location.href = "/editor")}>
            Script Editor
          </span> */}
        </div>
        {/* <Menu /> */}
      </div>
      {/* action bar */}
      <div className="col-12 d-flex flex-row justify-content-start align-items-center actionBarBg ">{/* <ActionBar  /> */}</div>
      {/* row-column => editor and docs */}
      <div className=" d-flex  justify-content-start align-items-start editorHeight" style={{ width: "100%" }}>
        <ScriptList />

        <TextEditor />
      </div>
      <Backed />
    </div>
  );
};
export default Editor;
