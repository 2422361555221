import { createRef, useEffect, useState } from "react";
import "./ActionBar.css";
import { EditorState, Modifier } from "draft-js";
import { OrderedSet } from "immutable";

// import   convertFromHTML from "html-to-draftjs"
import { useDispatch, useSelector } from "react-redux";
import { appendElementInNewLine, createCommand, createCommandToggle, loadDocToEditor, setOnTypeChange, setToast, toggleInlineStyle } from "../../Utils";
import ColorItem from "../ColorItem";
import SaveButton from "../SaveButton";
import { setDeletDocID, setDriveLink, setTextDirection, setTextRTL } from "../../Redux/Utils";
import { setStylesMap, updateCommand } from "../../firebase";
import axios from "axios";

const ActionBar = ({ editorState, setEditorState, editorRef, setstyles, styles }) => {
  const dispatch = useDispatch();
  const [isRtl, setisRtl] = useState(null);
  const readonly = useSelector((state) => state.user.readonly);
  const docType = useSelector((state) => state.user.docType);
  const currentDocID = useSelector((state) => state.user.currentDocID);
  const textRtl = useSelector((state) => state.user.textRtl);
  const textDirection = useSelector((state) => state.user.textDirection);
  const textRTL = useSelector((state) => state.user.textRTL);
  const driveLink = useSelector((state) => state.user.driveLink);
  const lockRef = createRef();
  const [docSaved, setdocSaved] = useState(false);
  const [newDoc, setNewDoc] = useState(false);
  const [loadFromDocs, setLoadFromDocs] = useState(false);
  // const [docLink, setdocLink] = useState("https://docs.google.com/document/d/1N1sJ42crnQ81zKOgwPh-_r17NlyHeY97e_AD_m1YZOI/edit");
  const [docLink, setdocLink] = useState("");
  const [saveStyles, setsaveStyles] = useState(false);

  useEffect(() => {
      setdocLink(driveLink);
  }, [driveLink]);

  useEffect(() => {
    const textRTL = window.localStorage.getItem("textRTL");
    if (textRTL) {
      if (textRTL === "RTL") {
        setisRtl(true);
        return;
      }
      setisRtl(false)
    }
  }, []);

  useEffect(() => {
    if (newDoc) {
      console.log(editorState.getCurrentContent().hasText());
      setDeletDocID(dispatch, currentDocID);
      window.$("#contentModal").modal("show");
      setNewDoc(false);

      return;
    }
  }, [newDoc]);

  useEffect(() => {
    if (saveStyles) {
      setStylesMap({ ...styles }).then((res) => {});
    }
  }, [saveStyles, styles]);

  function appendTextObjectsToEditorState(textObjects, editorState) {
    let newEditorState = editorState;

    textObjects.forEach((el) => {
      if (el.length > 1) {
        el.forEach((inner) => {
          const backgroundName = `bg${inner.backgroundColor}`;
          const colorName = `text${inner.color}`;

          setstyles((prev) => {
            return {
              ...prev,
              [backgroundName]: {
                backgroundColor: `#${inner.backgroundColor}`,
              },
              [colorName]: {
                color: `#${inner.color}`,
              },
            };
          });

          const contentState = newEditorState.getCurrentContent();
          EditorState.createEmpty().getCurrentContent().createEntity();
          const bold = inner.bold ? "BOLD" : "";
          const italic = inner.italic ? "ITALIC" : "";
          const underline = inner.underline ? "UNDERLINE" : "";
          const colorNameForSet = inner.color ? `text${inner.color}` : "";

          const backgroundNameForSet = inner.backgroundColor ? `bg${inner.backgroundColor}` : "";

          const contentStateWithEntity = contentState.createEntity("CUSTOM-STYLE", "IMMUTABLE");
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

          const selectionState = newEditorState.getSelection();
          const contentStateWithText = Modifier.insertText(
            contentState,
            selectionState,
            inner.textContent,
            OrderedSet.of(colorNameForSet, backgroundNameForSet, bold, italic, underline),
            // OrderedSet.of([]),
            entityKey
          );
          newEditorState = EditorState.push(newEditorState, contentStateWithText, "insert-characters");
        });
      }
      if (el.length === 1) {
        const item = el[0];
        const backgroundName = `bg${item.backgroundColor}`;
        const colorName = `text${item.color}`;

        setstyles((prev) => {
          return {
            ...prev,
            [backgroundName]: {
              backgroundColor: `#${item.backgroundColor}`,
            },
            [colorName]: {
              color: `#${item.color}`,
            },
          };
        });
        const contentState = newEditorState.getCurrentContent();
        const selectionState = newEditorState.getSelection();
        EditorState.createEmpty().getCurrentContent().createEntity();
        const bold = item.bold ? "BOLD" : "";
        const italic = item.italic ? "ITALIC" : "";
        const underline = item.underline ? "UNDERLINE" : "";
        const colorNameForSet = item.color ? `texttext${item.color}` : "";

        const backgroundNameForSet = item.backgroundColor ? `bg${item.backgroundColor}` : "";

        const contentStateWithEntity = contentState.createEntity("CUSTOM-STYLE", "IMMUTABLE");
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const contentStateWithText = Modifier.insertText(
          contentState,
          selectionState,
          item.textContent,
          OrderedSet.of(colorNameForSet, backgroundNameForSet, bold, italic, underline),
          // OrderedSet.of([]),
          entityKey
        );
        newEditorState = EditorState.push(newEditorState, contentStateWithText, "insert-characters");
      }
    });

    return newEditorState;
  }

  useEffect(() => {
    try {
      // if(!)
      if (loadFromDocs) {
        if (!docLink) {
          setToast({ state: "warning", text: "Please Enter valid document link." });
          setLoadFromDocs(false);
          return;
        }
        const splitted = docLink.split(":");
        if (!splitted) {
          setToast({ state: "warning", text: "Please Enter valid document link." });
          setLoadFromDocs(false);
          return;
        }
        if (!splitted[0].includes("https")) {
          setToast({ state: "warning", text: "Please Enter valid document link." });
          setLoadFromDocs(false);
          return;
        }
        updateCommand(currentDocID, 'driveLink', docLink);
        setDriveLink(dispatch, docLink);
        let id;
        const splittedLink = docLink.split("/");
        for (let i = 0; i < splittedLink.length; i++) {
          const item = splittedLink[i];
          if (item === "d") {
            id = splittedLink[i + 1];
            break;
          }
        }
        const fireAsync = async () => {
          const localEditorState = EditorState.createEmpty();
          const req = await axios.post("/getDoc", { id: id });
          const sortedInputArray = req.data.doc;
          const res = appendTextObjectsToEditorState(sortedInputArray, localEditorState);
          await setEditorState(res);
          setOnTypeChange(res.getCurrentContent());
          setsaveStyles(true);
        };
        fireAsync().catch((err) => {
          setToast({ state: "warning", text: "Please check the permission on the file and try again." });
          setLoadFromDocs(false);
          return;
        });
        setLoadFromDocs(false);
      }
    } catch (err) {
      setToast({ state: "warning", text: "Please check the permission on the file and try again." });
      setLoadFromDocs(false);
      return;
    }
  }, [loadFromDocs]);

  useEffect(() => {
    editorRef.current.focus();
    if (readonly) {
      lockRef.current.classList.remove("d-none");
      return;
    }
    if (!readonly) {
      lockRef.current.classList.add("d-none");
    }
  }, [readonly]);

  useEffect(() => {
    if (isRtl === true) {
      setTextRTL(dispatch, "RTL");
      editorRef.current.focus();
      window.localStorage.setItem("textRTL", "RTL");

      // createCommand(editorState, setEditorState, "toRtl");
      return;
    }
    if (isRtl === false) {
      setTextRTL(dispatch, "LTR");
      editorRef.current.focus();
      window.localStorage.setItem("textRTL", "LTR");
    }

    // createCommand(editorState, setEditorState, "toLtr");
  }, [isRtl]);

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));
  }, []);

  const handleDriveClick = () => {
    const elem = document.querySelector(".public-DraftEditor-content");
    console.log(elem.innerHTML);
  };

  return (
    <div
      className="d-flex flex-row justify-content-center align-items-center actionBarBg text-white align-self-start col-12 m-auto position-relative mb-1"
      style={{ width: "95%" }}
    >
      <div className="col-12 d-none  position-absolute top-0 end-0 h-100" style={{ zIndex: "999" }} ref={lockRef}></div>
      <div className="w-100 d-flex flex-row justify-content-start align-items-center">
        <div className="col-auto d-flex flex-row justify-content-center ms-2 border-end align-items-center me-1 pe-1">
          <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="New Doc" className="col-auto  pointer" onClick={() => setNewDoc(true)}>
            <img src="./assets/icons/doc.svg" className="invert me-2" height="25" width="25" />
          </div>
          <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Drive" className="col-auto pointer hoverSoft me-2" onClick={handleDriveClick}>
            <img src="./assets/icons/drive.svg" height="25" width="25" />
          </div>
          <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="נטוי" className="col-7 pointer hoverSoft pe-2">
            <input
              className="form-control  smFont rounded-1 p-1 text-dark"
              type="text"
              placeholder="Google doc link"
              value={docLink}
              onChange={(e) => setdocLink(e.target.value)}
            />
          </div>
          <div className="blueBg rounded me-1 pointer" style={{ width: "100px" }} onClick={() => setLoadFromDocs(true)}>
            <span className="">Load</span>
          </div>
        </div>
        <div className="col-auto d-flex flex-row  pe-1 ms-1 border-end">
          <div
            onClick={() => createCommandToggle(editorState, setEditorState, "BOLD")}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Bold"
            className="col-auto pointer position-relative"
          >
            <img src="./assets/icons/bolder.svg" className="invert" height="25" />
          </div>
          <div
            onClick={() => createCommandToggle(editorState, setEditorState, "ITALIC")}
            className="col-auto pointer hoverSoft"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Italic"
          >
            <img src="./assets/icons/slash.svg" className="invert" height="25" width="25" />
          </div>
          <div
            onClick={() => createCommandToggle(editorState, setEditorState, "UNDERLINE")}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Unerline"
            className="col-auto  pointer hoverSoft"
          >
            <img src="./assets/icons/underline.svg" className="invert" height="25" width="25" />
          </div>
        </div>
        <div className="col-auto d-flex flex-row  ps-1 pe-1 border-end">
          <ColorItem
            command={"color"}
            editorState={editorState}
            icon={"dee.svg"}
            setEditorState={setEditorState}
            key="ksidmsiomdiosomsd"
            tooltipText={"Text Color"}
            itemType="text"
            classItem="color"
            undoCommand={"defaultText"}
          />
          <ColorItem
            command={"color"}
            editorState={editorState}
            icon={"Aye.svg"}
            setEditorState={setEditorState}
            key="ksidmsdsfsdfsdfiomdiosomsd"
            tooltipText={"Background Color"}
            itemType="bg"
            classItem="bgColor"
            undoCommand={"defaultBg"}
          />

          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Toggle Styles"
            className="col-auto  pointer hoverSoft"
            onClick={() => toggleInlineStyle(editorState, setEditorState, "defaultStyle")}
          >
            <img src="./assets/icons/linethrough.svg" className="" height="25" width="25" />
          </div>
        </div>
        <div className="col-auto d-flex flex-row  ps-1 pe-1 border-end">
          <div
            onClick={() => toggleInlineStyle(editorState, setEditorState, "smText")}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Small Text"
            className="col-auto pointer hoverSoft me-1 ms-1"
          >
            <div className="plusSize text-center d-flex justify-content-center align-items-center w-5 rounded-1" style={{ fontSize: "10px" }}>
              M
            </div>
          </div>
          <div
            onClick={() => toggleInlineStyle(editorState, setEditorState, "mdText")}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Medium Text"
            className="col-auto pointer hoverSoft me-1 ms-1"
          >
            <div className="plusSize  text-center d-flex justify-content-center align-items-center w-5 rounded-1" style={{ fontSize: "10px" }}>
              M
            </div>
          </div>
          <div
            onClick={() => toggleInlineStyle(editorState, setEditorState, "lgText")}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Large Text"
            className="col-auto pointer hoverSoft me-1 ms-1"
          >
            <div className="plusSize  text-center d-flex justify-content-center align-items-center w-5 rounded-1" style={{ fontSize: "10px" }}>
              M
            </div>
          </div>
        </div>
        <div className="col-auto d-flex flex-row  ps-1 pe-1 border-end">
          <div
            onClick={() => {
              setTextDirection(dispatch, "left");
              // createCommandToggle(editorState, "alignTextLeft");
            }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Text Aligned Left"
            className="col-auto me-2 ms-2 pointer hoverSoft"
          >
            <img src="./assets/icons/ltr.svg" className="invert" height="25" width="25" />
          </div>
          <div
            onClick={() => setTextDirection(dispatch, "center")}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Text Centered"
            className="col-auto pointer hoverSoft"
          >
            <img src="./assets/icons/center.svg" className="invert" height="25" width="25" />
          </div>
          <div
            onClick={() => {
              setTextDirection(dispatch, "right");
            }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Text Aligned Right"
            className="col-auto me-2 ms-2  pointer hoverSoft"
          >
            <img src="./assets/icons/rtl.svg" className="invert" height="25" width="25" />
          </div>
        </div>
        <div
          onClick={() => setisRtl((prev) => !prev)}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-title="RTL/LTR"
          className="col-auto   pointer hoverSoft ms-1 ps-1"
        >
          <span className="text-center">
            {isRtl ? (
              <>
                <strong className="text-success">RTL </strong>/LTR
              </>
            ) : (
              <>
                RTL /<strong className="text-success">LTR</strong>
              </>
            )}{" "}
          </span>
        </div>
        <div
          onClick={() => appendElementInNewLine(editorState, setEditorState)}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-title="Add Marker"
          className="col-auto  pointer hoverSoft"
        >
          <img src="./assets/icons/stop.svg" className="me-2 ms-2" height="25" width="25" />
        </div>
      </div>
      <div className="col-auto">
        <SaveButton editorState={editorState} />
      </div>
    </div>
  );
};
export default ActionBar;
