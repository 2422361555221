const ButtonLoader = ({ state, text, className }) => {
  if (state) {
    return (
      <div style={{ zIndex: 9999 }} className={`col-12 d-flex justify-content-center align-items-center ${className}`}>
        <div className="spinner-border text-muted" style={{ height: "75px", width: "75px" }} role="status"></div>
      </div>
    );
  }
  return text;
};

export default ButtonLoader;
