import axios from "axios";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { setToast } from "../../Utils";
const Login = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [submit, setsubmit] = useState(false);
  const [isOk, setIsOk] = useState(false);

  useEffect(() => {
    if (submit) {
      console.log("click");
      if (!username || !password) {
        setToast({ state: "warning", text: "פרטי ההתחברות אינם נכונים." });
        setsubmit(false);
        return;
      }
      const fireAsync = async () => {
        const req = await axios.post("/login", { username, password });
        if (req.data.success) {
          setIsOk(true);
          console.log(req.data);
          window.localStorage.setItem("isOk", req.data.token);
          window.location.href = "/";
          setsubmit(false);
          return;
        } else {
          setToast({ state: "warning", text: "פרטי ההתחברות אינם נכונים." });
          setsubmit(false);
          return;
        }
      };
      fireAsync();
    }
  }, [submit]);

  useEffect(() => {
    const token = window.localStorage.getItem("isOk");
    if (!token) {
      return;
    }
    let decoded;
    try {
      decoded = jwt_decode(token);
    } catch (err) {
      console.log(err);
      window.localStorage.clear();
      window.location.href = "/";
    }
    const seconds = new Date().getTime() / 1000 + 2592000;
    console.log("decoded.exp", decoded.exp);
    console.log("seconds", seconds);

    if (decoded.exp > seconds) {
      window.localStorage.removeItem("isOk");
      window.location.href = "/";
      return;
    } else {
      window.location.href = "app";
    }
  }, []);

  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center actionBarBg" style={{ height: "100vh" }}>
      <div className="col-12 d-flex flex-row justify-content-center align-items-center">
        <img src="/assets/icons/gringo.jpg" height="100" width="100" className="rounded-circle" />
      </div>
      <div className="col-3 d-flex flex-column justify-content-center align-items-center  mt-2">
        <input onChange={(e) => setusername(e.target.value)} type="text" className="form-control smFont" placeholder="Username" />
        <input onChange={(e) => setpassword(e.target.value)} type="text" className="form-control smFont mt-2" placeholder="Password" />
        <div onClick={() => setsubmit(true)} className="col-6 bg-gradient text-white border rounded p-1 mt-2 w-5 pointer">
          Login
        </div>
      </div>
    </div>
  );
};
export default Login;
