import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDualMonitor1 } from "../Redux/Utils";

const OnRefresh = () => {
  const disptach = useDispatch();
  const onRefresh = function (event) {
    event.preventDefault(); // Cancel the default event
    event.returnValue = ""; // Set a blank value for the returnValue property
    setDualMonitor1(disptach, false);
      window.localStorage.removeItem("lineSpacing");

    if (window.confirm("Are you sure you want to leave this page?")) {
      // The user clicked "OK", so allow the default event to occur
      event.returnValue = undefined;
    }
  };
  useEffect(() => {
    window.addEventListener("beforeunload", onRefresh);

    return () => {
      window.addEventListener("beforeunload", onRefresh);
    };
  }, []);
  return null;
};

export default OnRefresh;
