import { useState } from "react";
import { useEffect } from "react";
import { removeDrafts } from "../../firebase";

const SampleColor = ({ settriggerColor, triggerColor }) => {
  const [submitIn, setSubmitIn] = useState(false);
  const [colors, setcolors] = useState([]);

  useEffect(() => {
    if (submitIn) {
      const elems = document.querySelector("[data-contents]");
      const all = elems.querySelectorAll("*");
      const children = [...all];
      const mapped = children
        .map((el) => {
          if (el.style.backgroundColor && el.textContent.length >1) {
            console.log(el.textContent);
            return el.style.backgroundColor;
          }
        })
        .filter((el) => el);
      const set = new Set(mapped);
      const arr = Array.from(set);
      setcolors(arr);
    }
  }, [submitIn]);
  return (
    <div className="d-flex flex-column justify-content-center align-items-center align-self-center text-white  ms-2 me-2 text-center position-relative">
      <span
        className="pointer"
        style={{ height: "35px", width: "35px", backgroundColor: triggerColor ? triggerColor : "#ffffff" }}
        onClick={() => setSubmitIn((prev) => !prev)}
      ></span>
      <div className="col d-flex flex-row justify-content-center align-items-center position-absolute bottom-100">
        {submitIn &&
          colors.map((el) => {
            return (
              <span
                className="pointer m-1"
                style={{ height: "18px", width: "18px", backgroundColor: el }}
                onClick={(e) => {
                  settriggerColor(el);
                  setSubmitIn(false);
                }}
              ></span>
            );
          })}
      </div>
    </div>
  );
};
export default SampleColor;
