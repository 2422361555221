const { useEffect } = require("react");
const { useState } = require("react");
const { startScrolling, stopScrolling, startScrollingTop, sendMonitorCommand } = require("../Utils");
const { useRef } = require("react");

const useScrollHook = (monitor1, speed) => {
  const scrollRef = useRef(null);
  const scrollSpeedRef = useRef(null);

  const [play, setPlay] = useState(false);
  const [forward, setForward] = useState(false);
  const [backword, setBackword] = useState(false);

  useEffect(() => {
    if (play) {
      startScrolling(speed, scrollRef);
    }

    return () => {
      stopScrolling(scrollRef);
    };
  }, [play, speed]);

  useEffect(() => {
   
    if (forward) {
      startScrolling(800, scrollSpeedRef);
    }
    return () => {
      stopScrolling(scrollSpeedRef);
    };
  }, [forward]);



  useEffect(() => {
    if (backword) {
      startScrollingTop(800, scrollSpeedRef);
    }
    return () => {
      stopScrolling(scrollSpeedRef);
    };
  }, [backword]);

  return { play, setPlay, setBackword, setForward };
};

export { useScrollHook };
