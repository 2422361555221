import { useCallback, useEffect } from "react";
import { useState } from "react";
import FunctionList from "../../FunctionList";
import InputsList from "../InputsList";
import { useDispatch, useSelector } from "react-redux";
import SampleColor from "../SampleColor";
import { v4 } from "uuid";
import { setToast } from "../../Utils";

const TextFunctionIn = ({ addallFunctions, inFunctionsList, setInFunctionsList, listElem, settriggerText, idx, setinAmount, id }) => {
  const dispatch = useDispatch();
  const [selectedFunctionLocal, setselectedFunctionLocal] = useState([]);
  const [selectedFunction, setSelectedInFunction] = useState({});
  const vmixObject = useSelector((state) => state.user.vmixObject);
  const [isSaved, setisSaved] = useState(false);
  const [changedManually, setchangedManually] = useState(false);

  const [functionObject, setFunctionObject] = useState(
    listElem || {
      Duration: "500",
      Delay: "0",
      Mix: "1",
      Function: "",
      Input: "",
      id: "",
      triggerType: "text",
      textTrigger: "",
      colorTrigger: "",
      type: "in",
    }
  );

  useEffect(() => {
    if (!selectedFunction) {
      return;
    }
    const value = Object.entries(selectedFunction?.parameters || {});
    setselectedFunctionLocal(value);
  }, [selectedFunction]);

  useEffect(() => {
    if (addallFunctions) {
      // setFunctionsToDB(total, currentDocID).then(() => {
      setFunctionObject({
        Duration: "500",
        Delay: "0",
        Mix: "1",
        Function: "",
        Input: "",
        id: "",
        triggerType: "text",
        textTrigger: "",
        colorTrigger: "",
        type: "in",
      });

      // });
    }
  }, [addallFunctions]);

  useEffect(() => {
    const functionID = functionObject.id ? functionObject.id : v4();
    console.log(functionID);
    const copy = { ...functionObject, id: functionID };
    let all;
    all = inFunctionsList.map((el) => {
      if (el.id === functionID) {
        return copy;
      }
      return el;
    });
    const item = all.filter((el) => el.id === functionID);
    if (item == false) {
      all = [...all, copy];
    }
    if (functionObject.Function && changedManually) {
      setInFunctionsList(all);
    }
  }, [functionObject]);
  const removeOne = useCallback(() => {
    const item = inFunctionsList.filter((el) => el.id == id);
    if (item == false) {
      if (setinAmount) {
        setinAmount((prev) => {
          console.log(id)
          console.log(prev)

          
          return prev.filter((el) => el !== id)
        });
      }
      return;
    }
    const without = inFunctionsList.filter((el) => el.id != id);
    setInFunctionsList(without);
  }, [id, inFunctionsList,setinAmount]);
  return (
    <div
      className="col-12 d-flex flex-column justify-content-center align-items-center position-relative"
      onMouseEnter={() => {
        console.log("adding touch");
        setchangedManually(true);
      }}
    >
      <span className="position-absolute start-0 text-white p-2 hoverSoft pointer top-0" onClick={removeOne}>
        X
      </span>
      <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center bg-dark m-2 ">
        <div className="col ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput m-2">
          <span className="text-white">Text</span>
          <input
            value={functionObject.textTrigger}
            onChange={(e) => {
              setFunctionObject((prev) => {
                return { ...prev, textTrigger: e.target.value };
              });
              settriggerText(e.target.value);
            }}
            type="text"
            className="form-control rounded-1 p-0 smFont p-1 m-2"
            placeholder="Text trigger"
          />
        </div>
        <FunctionList
          key="asdhoaoisdhsdfsdfsdfioashd"
          setFunctionObject={setFunctionObject}
          setSelectedFunction={setSelectedInFunction}
          functionObject={functionObject}
          withTitle={true}
        />
        <InputsList withTitle={true} onchange={setFunctionObject} val={functionObject} {...vmixObject} />
        {selectedFunctionLocal != false &&
          selectedFunctionLocal.map((el) => {
            const paramter = el[1];
            const type = paramter.type;
            if (paramter.type === "input") {
              return null;
            }
            return (
              <div className="col ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput">
                <span className="text-white">{el[0]}</span>
                <input
                  type="text"
                  onChange={(e) =>
                    setFunctionObject((prev) => {
                      return { ...prev, [el[0]]: e.target.value };
                    })
                  }
                  className="form-control rounded-1 p-0 smFont p-1"
                  placeholder={type}
                />
              </div>
            );
          })}
        <div className="col ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput">
          <span className="text-white">Duration</span>
          <input
            type="text"
            onChange={(e) =>
              setFunctionObject((prev) => {
                return { ...prev, Duration: e.target.value };
              })
            }
            className="form-control rounded-1 p-0 smFont p-1"
            placeholder="500"
          />
        </div>
        <div className="col ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput">
          <span className="text-white">Delay</span>
          <input
            onChange={(e) =>
              setFunctionObject((prev) => {
                return { ...prev, Delay: e.target.value };
              })
            }
            type="text"
            className="form-control rounded-1 p-0 smFont p-1"
            placeholder="0"
          />
        </div>
        <div className="col ms-2 d-flex flex-column justify-content-center align-items-center textTriggerInput">
          <span className="text-white">Mix</span>
          <input
            onChange={(e) =>
              setFunctionObject((prev) => {
                return { ...prev, Mix: e.target.value };
              })
            }
            type="text"
            className="form-control rounded-1 p-0 smFont p-1"
            placeholder="1"
          />
        </div>
      </div>
    </div>
  );
};

export default TextFunctionIn;
