import ActionBar from "../ActionBar";
import "./TextEditor.css";
import "draft-js/dist/Draft.css";
import { onSnapshot } from "firebase/firestore";

import {
  Editor,
  EditorState,
  RichUtils,
  DefaultDraftBlockRenderMap,
  convertFromRaw,
  Modifier,
  ContentBlock,
  convertToRaw,
  ContentState,
  CharacterMetadata,
  genKey,
  SelectionState,
  getDefaultKeyBinding,
  KeyBindingUtil,
} from "draft-js";

import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentDocID,
  setDocType,
  setDriveLink,
  setDualMonitor1,
  setDualMonitor2,
  setFunctionsList,
  setIsEditing,
  setReadonly,
  setRefreshData,
  setShowPanel,
  setTextDirection,
  setTextRTL,
} from "../../Redux/Utils";
import DeleteModal from "../DeleteModal";
import ContentModal from "../ContentModal";
import { getPromptRef, getStylesRef, removeDrafts, savePrompt, setStylesNew } from "../../firebase";
import { changeView, setOnTypeData } from "../../Utils";
import OnRefresh from "../../OnRefresh";
import { setStylesMap } from "../../firebase";
const TextEditor = () => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const readonly = useSelector((state) => state.user.readonly);
  const currentDocID = useSelector((state) => state.user.currentDocID);
  const textDirection = useSelector((state) => state.user.textDirection);
  const textRTL = useSelector((state) => state.user.textRTL);
  const docType = useSelector((state) => state.user.docType);
  const [styles, setstyles] = useState({});
  const dispatch = useDispatch();
  const [draftDoc, setDraftDoc] = useState(false);
  const [docName, setdocName] = useState("");
  const [editorReady, seteditorReady] = useState(false);
  const docData = useSelector((state) => state.user.docData);
  const showPanel = useSelector((state) => state.user.showPanel);
  const driveLink = useSelector((state) => state.user.driveLink);

  useEffect(() => {
    if (!currentDocID) {
      setDraftDoc(true);
    }
  }, [currentDocID]);
  useEffect(() => {
    if (!currentDocID) {
      setDraftDoc(true);
    }
  }, [currentDocID]);



  useEffect(() => {
    if (!currentDocID) {
      console.log("saving draft");
      savePrompt({ content: "", name: "draft", type: "draft", driveLink }).then((newDocumentID) => {
        window.sessionStorage.setItem("currentDocID", newDocumentID);
        console.log("newDocumentID", newDocumentID);
        setIsEditing(dispatch, false);
        setDraftDoc(false);
        setCurrentDocID(dispatch, newDocumentID);
      });
    }
    // return () => {
    //   setEditorState(EditorState.createEmpty());
    // };
  }, [currentDocID]);

  useEffect(() => {
    const textDirection = window.localStorage.getItem("textDirection");
    if (textDirection) {
      setTextDirection(dispatch, textDirection);
    }
    const textRTL = window.localStorage.getItem("textRTL");
    if (textRTL) {
      setTextRTL(dispatch, textRTL);
    }
  }, []);

  useEffect(() => {
    // setStylesNew(styleMap).then(()=>{
    //   console.log("done")
    // })
    // removeDrafts();
    let ref;
    const fireAsync = async () => {
      const local = window.sessionStorage.getItem("styles");
      if (local) {
        setstyles(JSON.parse(local));
        return;
      }
      ref = await getStylesRef((doc) => {
        window.sessionStorage.setItem("styles", JSON.stringify(doc));

        setstyles(doc);
      });
    };
    fireAsync();

    return () => {
      if (ref) {
        ref();
      }
    };
  }, [currentDocID]);
  useEffect(() => {
    if (!docData || !docData.hasOwnProperty("type")) {
      return;
    }
    const doc = docData;
    const all = document.querySelectorAll("[data-docid]");
     
    all.forEach((element) => {
      if (element.dataset.docid == doc?.id) {
        const dot = element.querySelector(".greenDot");
        if (dot) {
          dot.classList.remove("d-none");
        }
      } else {
        const dot = element.querySelector(".greenDot");
        if (dot) {
          dot.classList.add("d-none");
        }
      }
    });
     

    if (doc.type === "draft") {
      setIsEditing(dispatch, false);
    }
    if (doc.type === "prod") {
      setIsEditing(dispatch, true);
      setdocName(doc.name);
    }
    if (doc.content) {
      const parsed = JSON.parse(doc.content);
      const content = convertFromRaw(parsed);
      const data = EditorState.createWithContent(content);
      setEditorState(data);
    }
    if (doc?.driveLink) {
      setDriveLink(dispatch, doc.driveLink);
    } else {
      setDriveLink(dispatch, "");
    }
    if (!doc.content) {
      const data = EditorState.createEmpty();
      setEditorState(data);
    }
    // setFunctionsList(dispatch, doc.functions);
    
    setCurrentDocID(dispatch, doc.id);
    setReadonly(dispatch, doc.readonly);
    setDocType(dispatch, doc.type);
    seteditorReady(true);
    return;
  }, [docData]);
  const editorRef = createRef();

  useEffect(() => {
    document.addEventListener("resetEditor", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      setEditorState(EditorState.createEmpty());
    });

    return () => {
      document.removeEventListener("resetEditor", () => {});
    };
  }, []);

  const handleEditorState = (editorState) => {
    if (editorReady) {
      setOnTypeData(editorState.getCurrentContent());
    }
    setEditorState(editorState);
  };
  const handlePanel = () => {
    setShowPanel(dispatch, !showPanel);
  };
  const customKeyBindingFn = (e) => {

    // Use the default key binding for other keys
    return getDefaultKeyBinding(e);
  };

  return (
    <div className="d-flex flex-column justify-content-start align-items-center  h-100" style={{ width: "95%" }}>
      <OnRefresh />
      <DeleteModal />
      <ContentModal />
      <div className="col-12 d-flex flex-column justify-content-center align-items-center h-100">
        <ActionBar editorState={editorState} setEditorState={handleEditorState} editorRef={editorRef} setstyles={setstyles} styles={styles} />
        <div className="editorContainer h-100 position-relative">
          <div
            className="position-absolute   bg-dark text-white pe-2 ps-2 rounded d-flex flex-row justify-content-center align-items-center"
            style={{ height: "35px", width: "auto", zIndex: "888", left: "5px", bottom: "5px" }}
          >
            {docName}
          </div>
          <Editor
            customStyleMap={styles}
            textAlignment={textDirection}
            textDirectionality={textRTL}
            editorState={editorState}
            onChange={handleEditorState}
            readOnly={readonly}
            ref={editorRef}
            keyBindingFn={customKeyBindingFn}
          />
        </div>
      </div>
      <div className="col-8 d-flex flex-row justify-content-center align-items-center">
        <div className="col-12 d-flex flex-row justify-content-start align-items-center pointer" onClick={handlePanel}>
          <span className="text-white w-5 fs-5 ">+Add Trigger</span>
          <img src="/assets/icons/arrowBlue.svg" className="align-self-center mt-2 ms-3" height="15" with="15" style={{ transform: "rotate(270deg)" }} />
        </div>
        <div
          className=" col-5 text-white align-self-center mt-2 rounded-1 p-1 fs-5 w-5  blueBg pointer hoverSoft hoverScale "
          onClick={() => {
            // window.location.href = "/prompter";
            changeView(0);
          }}
        >
          START PROMPT
        </div>
      </div>
    </div>
  );
};
export default TextEditor;
