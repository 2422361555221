import _ from "lodash";
import { Fragment } from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getKeys, updateCommand } from "../../firebase";
import {
  setAllCaps,
  setBreakOnSentenceEnd,
  setBreakOnTriggerColor,
  setDualMonitor1,
  setDualMonitor2,
  setFlipHorizontal1,
  setFlipHorizontal2,
  setFlipVertical2,
  setLineSpacing,
  setScreenMargin,
  setShowTextLine,
  setShowTimer,
  setFlipVertical1,
  setMasterScreen2,
  setMasterScreen1,
} from "../../Redux/Utils";
import {
  sendMonitorCommand,
  addLineBreakAfterBackground,
  setPageMargin,
  setPageSpaceLine,
  convertToUpperCase,
  handleDotSpace,
  calculatePercentage,
  calculateOppositePercentage,
} from "../../Utils";
import KeyboardShortcuts from "../KeyboardShortcuts";
import "./SettingsPopup.css";
const MonitorInput = ({ onFlipH, onFlipV, activate, isActiveV, isActiveH, isActive, masterScreen, handleSetMaster }) => {
  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center monitorInputsContainer m-2">
      <div className="col-12 d-flex flex-row justify-content-center align-items-center">
        <div className="col d-flex flex-row justify-content-start align-items-center pointer" onClick={activate}>
          <img src={isActive ? "/assets/icons/reverse.svg" : "/assets/icons/reverseOff.svg"} height="21" width="21" />
          <span className="ms-3 w-3 optionsFont">Dual monitor</span>
        </div>
        {/* <div className="col d-flex flex-row justify-content-start align-items-center pointer" onClick={handleSetMaster}>
          <img src={masterScreen ? "/assets/icons/reverse.svg" : "/assets/icons/reverseOff.svg"} height="21" width="21" />
          <span className="ms-3 w-3 optionsFont">Master</span>
        </div> */}
      </div>
      <div className="col-12 d-flex flex-row justify-content-center align-items-center">
        <div className="col d-flex flex-row justify-content-start align-items-center pointer" onClick={onFlipH}>
          <img src={isActiveH ? "/assets/icons/reverse.svg" : "/assets/icons/reverseOff.svg"} height="21" width="21" />
          <span className="ms-3 w-3 optionsFont">Flip horizontal</span>
        </div>
        <div className="col d-flex flex-row justify-content-start align-items-center pointer" onClick={onFlipV}>
          <img src={isActiveV ? "/assets/icons/reverse.svg" : "/assets/icons/reverseOff.svg"} height="21" width="21" />
          <span className="ms-3 w-3 optionsFont">Flip vertical</span>
        </div>
      </div>
    </div>
  );
};
const PrompterSettings = ({ monitor1, setMonitor1, editorState, setEditorState, monitor2, setMonitor2, show, setPlay, ready }) => {
  const dispatch = useDispatch();
  const lineSpacing = useSelector((state) => state.user.lineSpacing);
  const margin = useSelector((state) => state.user.margin);
  const showTextLine = useSelector((state) => state.user.showTextLine);
  const breakOnSentenceEnd = useSelector((state) => state.user.breakOnSentenceEnd);
  const breakOnTriggerColor = useSelector((state) => state.user.breakOnTriggerColor);
  const allCaps = useSelector((state) => state.user.allCaps);

  const dualMonitor1 = useSelector((state) => state.user.dualMonitor1);
  const flipHorizontal1 = useSelector((state) => state.user.flipHorizontal1);
  const flipVertical1 = useSelector((state) => state.user.flipVertical1);
  const masterScreen1 = useSelector((state) => state.user.masterScreen1);
  const masterScreen2 = useSelector((state) => state.user.masterScreen2);
  const currentDocID = useSelector((state) => state.user.currentDocID);
  const outerHeight = useSelector((state) => state.user.outerHeight);
  const outerWidth = useSelector((state) => state.user.outerWidth);

  useEffect(() => {
    let interval;
    if (dualMonitor1) {
      const elemRoot = document.querySelector(".DraftEditor-root");
      elemRoot.scrollTo(0, 0);
      const query = {
        lineSpacing,
        margin,
        showTextLine,
      };
      setPageMargin(parseInt(margin));
      let monitor1data = window.open(
        `${window.location.origin}/output?id=${currentDocID}&screenNum=${1}`,
        "targetWindow",
        `
      scrollbars=yes,
      resizable=yes,
      menubar=yes,
      titlebar=yes,
      status=yes,
      scrollbars=yes,
      location=yes,
      width=${outerWidth},
      height=${outerHeight}`
      );
      window.monitordata = monitor1data;
      sessionStorage.setItem("monitor1", true);
      setMonitor1(monitor1data);
      interval = setInterval(() => {
        if (monitor1data.closed) {
          setDualMonitor1(dispatch, false);
          setMonitor1(false);
          clearInterval(interval);
          return;
        }
      }, 500);
      return;
    }
    console.log("dualMonitor1", dualMonitor1);
    if (!dualMonitor1) {
      if (monitor1) {
        sessionStorage.setItem("monitor1", false);
        monitor1.close();
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dualMonitor1]);

  const debounced = _.debounce(updateCommand, 200);

  const handleLineSpacing = async (e) => {
    const value = Math.round(parseInt(e.target.value));
    setLineSpacing(dispatch, value);
  };
  const handleScreenMargin = async (e) => {
    const value = Math.round(parseInt(e.target.value));
    setScreenMargin(dispatch, value);
  };

  const handleAllCaps = async () => {
    setAllCaps(dispatch, !allCaps);
    await debounced(currentDocID, "allCaps", !allCaps);
  };
  const handleBreakSentence = async () => {
    setBreakOnSentenceEnd(dispatch, !breakOnSentenceEnd);
    await debounced(currentDocID, "breakSentence", !breakOnSentenceEnd);
  };
  const handleBreakColor = async () => {
    setBreakOnTriggerColor(dispatch, !breakOnTriggerColor);
    await debounced(currentDocID, "breakColor", !breakOnTriggerColor);
  };

  const handleShowTextLine = async () => {
    setShowTextLine(dispatch, !showTextLine);
    await debounced(currentDocID, "showTextLine", !showTextLine);
  };


  const handleDualMonitor1 = useCallback((e) => {
    window.sessionStorage.setItem("monitor1", !dualMonitor1);
    setDualMonitor1(dispatch, !dualMonitor1);
  });

  const handleFlipVertical1 = async () => {
    setFlipVertical1(dispatch, !flipVertical1);
    await debounced(currentDocID, "flipVertical", !flipVertical1);
  };

  const handleFlipHorizontal1 = async () => {
    setFlipHorizontal1(dispatch, !flipHorizontal1);
    await debounced(currentDocID, "flipHorizontal", !flipHorizontal1);
  }

  const handleSetMaster1 = useCallback((e) => {
    setMasterScreen1(dispatch, !masterScreen1);
    setMasterScreen2(dispatch, !masterScreen2);
    sendMonitorCommand(monitor1, "masterScreen1", !masterScreen1);
  });


  useEffect(() => {
    setPageMargin(parseInt(margin));
  }, [margin,currentDocID]);

  useEffect(() => {
    if(!ready){
      return
    }
    const current = convertToUpperCase(allCaps, editorState);
    if (current) {
      setEditorState(current);
    }
  }, [allCaps,ready]);

  useEffect( () => {
    if(!margin){
      return
    }
    let timeout; 
      timeout = setTimeout(async () => {
        await debounced(currentDocID, "margin", margin);

      }, 500);
    return () => {
     if(timeout){
      clearTimeout(timeout)
     }
    };
  }, [margin]);

  useEffect( () => {
    if(!lineSpacing){
      return
    }
    let timeout; 
      timeout = setTimeout(async () => {
        await debounced(currentDocID, "lineSpacing", lineSpacing);

      }, 500);
    return () => {
     if(timeout){
      clearTimeout(timeout)
     }
    };
  }, [lineSpacing]);


  return (
    <div className={`col-12 ${!show ? "d-flex" : "d-none"} flex-column justify-content-center align-items-center optionsParts`}>
      <div className="col-11 d-flex flex-column justify-content-center align-items-center">
        <div className="col-12 d-flex flex-column justify-content-between align-items-center pointer">
          <div className="col-12 d-flex flex-row justify-content-center align-items-center">
            <span className="col w-3 text-start optionsFont">Line spacing</span>
            <span className="col-auto smFont optionsFont">{calculatePercentage(lineSpacing, 300)}px</span>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center">
            <input value={lineSpacing} dir="ltr" type="range" min={15} step="1" max={300} onChange={handleLineSpacing} className="form-range" />
          </div>
        </div>
        <div className="col-12 d-flex flex-column justify-content-between align-items-center mt-2 pointer position-relative">
          <div className="col-12 d-flex flex-row justify-content-between align-items-center optionsFont">
            <span className="col w-3 text-start optionsFont">Screen margin</span>

            <span className="col-auto optionsFont">{calculateOppositePercentage(margin, 100)}%</span>
          </div>
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <input value={margin} dir="rtl" type="range" min="0" step="1" max="100" onChange={handleScreenMargin} className="form-range" />
          </div>
        </div>
        <div className="col-12 d-flex flex-row justify-content-start align-items-center mt-1 pointer" onClick={handleShowTextLine}>
          <img src={showTextLine ? "/assets/icons/reverse.svg" : "/assets/icons/reverseOff.svg"} height="21" width="21" />
          <span className="ms-3 w-3 optionsFont">Show pointer</span>
        </div>
        <div className="col-12 d-flex flex-row justify-content-start align-items-center mt-1 pointer" onClick={handleBreakSentence}>
          <img src={breakOnSentenceEnd ? "/assets/icons/reverse.svg" : "/assets/icons/reverseOff.svg"} height="21" width="21" />
          <span className="ms-3 w-3 optionsFont">Break on sentence end</span>
        </div>
        <div className="col-12 d-flex flex-row justify-content-start align-items-center mt-1 pointer" onClick={handleBreakColor}>
          <img src={breakOnTriggerColor ? "/assets/icons/reverse.svg" : "/assets/icons/reverseOff.svg"} height="21" width="21" />
          <span className="ms-3 w-3 optionsFont">Break trigger color</span>
        </div>
        <div className="col-12 d-flex flex-row justify-content-start align-items-center mt-1 pointer" onClick={handleAllCaps}>
          <img src={allCaps ? "/assets/icons/reverse.svg" : "/assets/icons/reverseOff.svg"} height="21" width="21" />
          <span className="ms-3 w-3 optionsFont">ALL CAPS</span>
        </div>
        <hr className="mt-2 mb-2 col-12" />
      </div>

      <div className="col-11 d-flex flex-column justify-content-start align-items-center">
        {[1, 2].map((el, idx) => {
          if (idx === 0) {
            return (
              <MonitorInput
                activate={handleDualMonitor1}
                isActive={dualMonitor1}
                isActiveH={flipHorizontal1}
                isActiveV={flipVertical1}
                name={el.label}
                onFlipH={handleFlipHorizontal1}
                onFlipV={handleFlipVertical1}
                key="Sdkfsdfiosndfiosnofnsiofnsd"
                masterScreen={masterScreen1}
                handleSetMaster={handleSetMaster1}
              />
            );
          }
        })}
      </div>
    </div>
  );
};
const SettingsPopup = ({ showPopup, monitor1, setMonitor1, editorState, setEditorState, monitor2, setMonitor2, setisEditingSettings,ready }) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={`col-xxl-3 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 p-3 ${
        showPopup ? "d-flex" : "d-none"
      } flex-column justify-content-start align-items-center settingsContainer text-white`}
    >
      <div className="col-10 d-flex flex-row justify-content-start align-items-center m-2 ">
        <div className="col-auto optionsFont w-5 d-flex justify-content-center align-items-center pointer" onClick={() => setShow(false)}>
          <span className={show ? "optionsFont" : "blueText optionsFont"}>Settings</span>
        </div>
        <div className="col-auto optionsFont w-5 d-flex justify-content-center align-items-center ms-3 pointer" onClick={() => setShow(true)}>
          <span className={show ? "blueText optionsFont" : "optionsFont"}>Shortcuts</span>
        </div>
      </div>
      <KeyboardShortcuts setisEditingSettings={setisEditingSettings} show={show} />
      <PrompterSettings
        setMonitor1={setMonitor1}
        monitor1={monitor1}
        setMonitor2={setMonitor2}
        monitor2={monitor2}
        editorState={editorState}
        setEditorState={setEditorState}
        show={show}
        ready={ready}
      />
    </div>
  );
};
export default SettingsPopup;
