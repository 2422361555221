import { useEffect } from "react";
import { useState } from "react";
import FunctionIn from "../FunctionIn";
import { setToast } from "../../Utils";
import { setFunctionsToDB } from "../../firebase";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import "./ColorTrigger.css";
import SampleColor from "../SampleColor";
import { useCallback } from "react";
const Parent = ({ inFunctionsList, setinFunctionsList, type, addallFunctions, differ, setinAmount }) => {
  const local = inFunctionsList.filter((el) => el.type === type);
  if (local == false) {
    const total = [...differ];
    return total.map((el, idx) => {
      idx++;
      return (
        <FunctionIn
          addallFunctions={addallFunctions}
          key={el}
          triggerType={"color"}
          type={type}
          inFunctionsList={inFunctionsList}
          setInFunctionsList={setinFunctionsList}
          idx={idx}
          listElem={false}
          id={el}
          setinAmount={setinAmount}
        />
      );
    });
  }
  let total = [...local];
  if (true) {
    return total.map((el, idx) => {
      idx++;
      return (
        <FunctionIn
          addallFunctions={addallFunctions}
          key={el.id}
          triggerType={"color"}
          type={type}
          inFunctionsList={inFunctionsList}
          setInFunctionsList={setinFunctionsList}
          idx={idx}
          listElem={el.hasOwnProperty("Function") ? el : false}
          id={el.hasOwnProperty("Function") ? el.id : el}
          setinAmount={setinAmount}
        />
      );
    });
  }
};

const ColorTrigger = ({ vmixObject, addallFunctions, setAddAllFunctions, setAllFunctions, allFunctions, externalFunctions, isEditing, setIsEditing }) => {
  const [inFunctionsList, setinFunctionsList] = useState([]);
  const [triggerColor, settriggerColor] = useState("#ffffff");
  const [inAmount, setinAmount] = useState([v4()]);
  const [outAmount, setoutAmount] = useState([v4()]);

  useEffect(() => {
    if (addallFunctions) {
      // setAddAllFunctions(false);
      console.log("isEditing", isEditing);
      if (inFunctionsList == false) {
        setToast({ state: "warning", text: "Fill your functions first." });
        setAddAllFunctions(false);
        return;
      }

      const payload = {
        triggerType: "color",
        trigger: triggerColor,
        id: externalFunctions.id ? externalFunctions.id : v4(),
        functions: inFunctionsList,
        active:true
      };

      if (isEditing) {
        const all = allFunctions.filter((el) => el.id !== externalFunctions.id);
        const total = [...all, payload];
        setIsEditing(false);
        setAllFunctions(total);
        setinFunctionsList([]);
        setinAmount([
          {
            Duration: "500",
            Delay: "500",
            Mix: "1",
            Function: "",
            Input: "",
            id: v4(),
            triggerType: "color",
            textTrigger: "",
            colorTrigger: "",
            type: "in",
          },
        ]);
        setoutAmount([
          {
            Duration: "500",
            Delay: "500",
            Mix: "1",
            Function: "",
            Input: "",
            id: v4(),
            triggerType: "color",
            textTrigger: "",
            colorTrigger: "",
            type: "in",
          },
        ]);
        setAddAllFunctions(false);
        return;
      }

      setAllFunctions((prev) => [...prev, payload]);
      setinFunctionsList([]);
      setinAmount([
        {
          Duration: "500",
          Delay: "500",
          Mix: "1",
          Function: "",
          Input: "",
          id: v4(),
          triggerType: "color",
          textTrigger: "",
          colorTrigger: "",
          type: "in",
        },
      ]);
      setoutAmount([
        {
          Duration: "500",
          Delay: "500",
          Mix: "1",
          Function: "",
          Input: "",
          id: v4(),
          triggerType: "color",
          textTrigger: "",
          colorTrigger: "",
          type: "in",
        },
      ]);

      setAddAllFunctions(false);
    }
  }, [addallFunctions]);

  useEffect(() => {
    if (externalFunctions.hasOwnProperty("triggerType")) {
      settriggerColor(externalFunctions.trigger);
      setinFunctionsList(externalFunctions.functions);
    }
  }, [externalFunctions]);

  useEffect(() => {
    const resetFunc = function () {
      setinFunctionsList([]);
    };
    document.addEventListener("resetFunc", resetFunc);
    return () => {
      document.removeEventListener("resetFunc", resetFunc);
    };
  }, []);
  const addOneIn = function () {
    setinAmount((prev) => [...prev, v4()]);
    setinFunctionsList((prev) => [
      ...prev,
      {
        Duration: "500",
        Delay: "500",
        Mix: "1",
        Function: "",
        Input: "",
        id: v4(),
        triggerType: "color",
        textTrigger: "",
        colorTrigger: "",
        type: "in",
      },
    ]);
  };
  const addOneOut = function () {
    setinFunctionsList((prev) => [
      ...prev,
      {
        Duration: "500",
        Delay: "500",
        Mix: "1",
        Function: "",
        Input: "",
        id: v4(),
        triggerType: "color",
        textTrigger: "",
        colorTrigger: "",
        type: "out",
      },
    ]);
  };
  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <div className="col-12 d-flex flex-row justify-content-between align-items-start colorTrigger">
        <div className="col  d-flex flex-column justify-content-start align-items-start functionContainer">
          <Parent
            setinAmount={setinAmount}
            inFunctionsList={inFunctionsList}
            setinFunctionsList={setinFunctionsList}
            differ={inAmount}
            type={"in"}
            addallFunctions={addallFunctions}
            key="dslfnsodfiosdnfiosdnfiosadasdasdasdsdnfiosdnfiosndfios"
          />
          <div className="text-white col-auto pointer text-center  p-2  rounded m-2" onClick={addOneIn}>
            + Add function in
          </div>
        </div>
        <div className="col-auto d-flex flex-column justify-content-center align-items-center">
          <SampleColor settriggerColor={settriggerColor} triggerColor={triggerColor} />
        </div>
        <div className="col  d-flex flex-column justify-content-start align-items-start functionContainer">
          <Parent
            setinAmount={setoutAmount}
            inFunctionsList={inFunctionsList}
            setinFunctionsList={setinFunctionsList}
            differ={outAmount}
            type={"out"}
            addallFunctions={addallFunctions}
            key="dslfnsodfiosdnfiosdnfiosdnfiosdnfiosndfios"
          />

          <div className="text-white col-auto pointer text-center  p-2  rounded m-2" onClick={addOneOut}>
            + Add function out
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorTrigger;
