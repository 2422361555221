// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import {
  collection,
  query,
  where,
  getFirestore,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  Timestamp,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  browserSessionPersistence,
  setPersistence,
  FacebookAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import { compareUpdateTime, toDateTime } from "./Utils";

// const firebaseConfig = {
//   apiKey: "AIzaSyDeST-oOV-CCsU9LIiwAuJnmlhj2Qls99U",
//   authDomain: "prompter-21d9e.firebaseapp.com",
//   projectId: "prompter-21d9e",
//   storageBucket: "prompter-21d9e.appspot.com",
//   messagingSenderId: "71810531274",
//   appId: "1:71810531274:web:6985c08f5435fc34db5933",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDMqyu2sQWkPGt0npxk-lebi3cXFm1Hv9w",
  authDomain: "prompterapi.firebaseapp.com",
  projectId: "prompterapi",
  storageBucket: "prompterapi.appspot.com",
  messagingSenderId: "1034423425980",
  appId: "1:1034423425980:web:ebf1ebdd5d4ea97a7229b9",
  measurementId: "G-F2XWQLFMS0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
const db = getFirestore(app);

export const savePrompt = async ({ content, name, type, driveLink }) => {
  const current_timestamp = Timestamp.fromDate(new Date()).seconds;
  const ref = collection(db, "prompts");
  const payload = {
    content,
    name,
    owner: "1234567",
    createdAt: current_timestamp,
    updatedAt: current_timestamp,
    readonly: false,
    type,
    functions: [],
    showTextLine: true,
    margin: 100,
    lineSpacing: 0,
    flipHorizontal: false,
    flipVertical: false,
    allCaps: false,
    breakSentence: false,
    breakColor: false,
    textSize: 3,
    driveLink: driveLink || ""
  };
  const res = await addDoc(ref, payload);
  return res.id;
};
export const updatePrompt = async (docID, content) => {
  const updatedAt = Timestamp.fromDate(new Date()).seconds;
  const ref = doc(db, "prompts", docID);
  await updateDoc(ref, { updatedAt, content });
  return new Promise((resolve) => setTimeout(() => resolve(true), 0));
};
export const getUserScripts = async (id, setDocs) => {
  return onSnapshot(collection(db, "prompts"), (docs) => {
    const mapped = docs.docs
      .map((el) => {
        const data = el.data();
        const updatedAt = toDateTime(data.updatedAt);

        return { ...data, updatedAt, id: el.id, timestamp: data.updatedAt };
      })

      .sort(compareUpdateTime);
    setDocs(mapped);
  });
};

export const setFunctionsToDB = async (data, id) => {
  const ref = doc(db, "prompts", id);
  await updateDoc(ref, { functions: data });
};

export const updateReadOnly = async (docID, readonly) => {
  const ref = doc(db, "prompts", docID);
  await updateDoc(ref, { readonly: readonly });
  return new Promise((resolve) => setTimeout(() => resolve(true), 0));
};
export const deletePrompt = async (docID) => {
  const ref = doc(db, "prompts", docID);
  await deleteDoc(ref);
};

export const getSpeicificDoc = async (docID) => {
  const ref = doc(db, "prompts", docID);
  const res = await getDoc(ref);
  if (!res.exists()) {
    return false;
  }
  return { ...res.data(), id: res.id };
};

export const setKeys = async (data) => {
  const ref = doc(db, "keyCodes", "suwzxMgIUYQmRTB0Tutk");
  await updateDoc(ref, { keys: data });
};

export const getKeys = async (cb) => {
  return onSnapshot(doc(db, "keyCodes", "suwzxMgIUYQmRTB0Tutk"), (e) => {
    const data = e.data();
    cb(data);
  });
};
export const removeDrafts = async ()=>{
  const ref = collection(db, "prompts");
  const res = await getDocs(ref);
  res.docs.forEach(async(el)=>{
    const data = el.data();
    console.log(data)
    if(el.data().type === "draft"){
      await deleteDoc(el.ref)
    }
  })
}
export const setStylesNew = async (styles) => {
  const ref = doc(db, "styles", "oVxO7ACB5GFGRiwrTOMx");
  const res = await getDoc(ref);
  await setDoc(ref, { stylesMap: { ...styles} }, { merge: true });
};
export const setStylesMap = async (styles) => {
  const ref = doc(db, "styles", "oVxO7ACB5GFGRiwrTOMx");
  const res = await getDoc(ref);
  await setDoc(ref, { stylesMap: { ...styles, ...res.data().stylesMap } }, { merge: true });
};
export const getStylesMap = async () => {
  const ref = doc(db, "styles", "oVxO7ACB5GFGRiwrTOMx");
  const res = await getDoc(ref);
  return res.data();
};

export const getStylesRef = async (cb) => {
  return onSnapshot(doc(db, "styles", "oVxO7ACB5GFGRiwrTOMx"), (e) => {
    const data = e.data().stylesMap;
    cb(data);
  });
};

export const getPromptRef = async (id, cb) => {
  return onSnapshot(doc(db, "prompts", id), (e) => {
    const data = e.data();
    cb(data);
  });
};

export const updateCommand = async (id, prop, value) => {
  const ref = doc(db, "prompts", id);
  await updateDoc(ref, { [prop]: value });
  console.log("done",{prop, value})

};