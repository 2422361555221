import VmixFunctionList from "vmix-function-list";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
const list = new VmixFunctionList();

const functions = list.all().reduce((acc, item) => {
  if (!acc[item.category]) {
    acc[item.category] = [];
  }
  acc[item.category].push(item);
  return acc;
}, {});

const FunctionList = ({ withTitle, setFunctionObject, functionObject, setSelectedFunction }) => {
  const dispatch = useDispatch();
  const [term, setterm] = useState("");
  const [localFuncs, setlocalFuncs] = useState([]);
  useEffect(() => {
    if (term && term.length > 2) {
      const items = list.all().filter((el) => el.function.toLocaleLowerCase().includes(term));
      setlocalFuncs(items);
    }
    if (!term) {
      setlocalFuncs([]);
    }
  }, [term]);
  const setinput = function (e) {
    setterm(e.target.value);
  };
  const resetfunc =function(){
    setlocalFuncs([]);
  }
  return (
    <div className="col-5 textTriggerInput text-white d-flex flex-column justify-content-center align-items-center position-relative">
      {withTitle && <span className="text-white">Function</span>}

      <div className="col-12 border dropup rounded-0 p-0 midFont p-1  text-start">
        <div type="div" className=" dropdown-toggle functionList pointer" aria-expanded="false" data-bs-toggle="dropdown">
          Functions
        </div>
        <ul className="dropdown-menu  p-0">
          {Object.keys(functions).map((el) => {
            const funcs = functions[el];

            return (
              <div className="dropend border-bottom  pointer">
                <div
                  type="div"
                  className="dropdown-toggle midFont p-2 innerDrop hoverSoft"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-name={el}
                  onClick={(e) => {
                    e.stopPropagation();
                    const all = document.querySelectorAll("[data-name]");
                    all.forEach((els) => {
                      if (els.dataset.name !== el) {
                        window.$(els).dropdown("hide");
                      }
                    });
                  }}
                >
                  {el}
                </div>
                <ul className="dropdown-menu innerSubMenu p-0 textTriggerInput">
                  {funcs.map((func) => {
                    return (
                      <li
                        className="functionItem p-1 border-bottom midFont hoverSoft"
                        onClick={(e) => {
                          console.log(func);
                          setFunctionObject((prev) => {
                            return { ...prev, Function: func.function };
                          });
                          setSelectedFunction(func);
                          window.$(".dropup").dropdown("hide");
                          window.$(".innerDrop").dropdown("hide");
                        }}
                      >
                        {func.function}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
          <input type="text" className=" form-control searchFunctions border-0 border-bottom midFont" placeholder="Search function" onChange={setinput} />
          {localFuncs != false && (
            <div className=" localfuncs position-absolute lightdarkbg start-100 top-0 d-flex flex-column justify-content-start align-items-center" style={{maxHeight:"300px"}}>
              <span className="col-12 d-flex flex-row justify-content-end align-items-end text-white p-1" onClick={resetfunc}>X</span>
              {localFuncs.map((func) => {
                return (
                  <span
                    className="functionItem w-100 p-1 border-bottom midFont hoverSoft"
                    onClick={(e) => {
                      console.log(func);
                      setFunctionObject((prev) => {
                        return { ...prev, Function: func.function };
                      });
                      setSelectedFunction(func);
                      setlocalFuncs([]);
                      window.$(".dropup").dropdown("hide");
                      window.$(".innerDrop").dropdown("hide");
                    }}
                  >
                    {func.function}
                  </span>
                );
              })}
            </div>
          )}
        </ul>
      </div>
      {functionObject.Function && <span className="midFont border-bottom border-end border-start col-12 text-start ps-1">{functionObject.Function}</span>}
    </div>
  );
};
export default FunctionList;
