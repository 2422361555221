import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { deletePrompt } from "../../firebase";
import { setCurrentDocID, setIsEditing, setRefreshData } from "../../Redux/Utils";
import { deleteDocument, resetEditor, resetFuncs } from "../../Utils";
import ButtonLoader from "../ButtonLoader";

const DeleteModal = () => {
  const [deleteDoc, setDeleteDoc] = useState(false);
  const deleteDocID = useSelector((state) => state.user.deleteDocID);
  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteDoc) {
      console.log("deleteDocID",deleteDocID);
      // return
      deletePrompt(deleteDocID).then((res) => {
        setIsEditing(dispatch, false);
        // resetEditor();
        window.$("#deleteModal").modal("hide");
        window.sessionStorage.removeItem("currentDocID");
        setCurrentDocID(dispatch,"");
        resetFuncs()
        setDeleteDoc(false);

        return;
      });
    }
  }, [deleteDoc, deleteDocID]);

  return (
    <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Are you sure you want to delete this document?
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-footer border-0 col d-flex flex-row justify-content-evenly">
            <div
              className="col pointer rounded-1 w-3 closeBtn"
              onClick={() => {
                window.$("#deleteModal").modal("hide");
                setDeleteDoc(false);
              }}
            >
              Close
            </div>
            <div className="col pointer rounded-1 w-5 text-white  approveBtn" onClick={() => setDeleteDoc(true)}>
              {deleteDoc ? <ButtonLoader state={true} /> : "DELETE"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteModal;
