const styleMap = {
  textRed: {
    color: "#FE0002",
  },
  textYellow: {
    color: "#FFFF00",
  },
  textGreen: {
    color: "#00FF00",
  },
  textBlue: {
    color: "#018FFD",
  },
  textTourqua: {
    color: "#00FFFF",
  },
  textPink: {
    color: "#FC02FA",
  },
  textOrage: {
    color: "#F36523",
  },
  textWhite: {
    color: "#FFFFFF",
  },
  textLightGrey: {
    color: "#CCCCCC",
  },
  textGrey: {
    color: "#959595",
  },
  textBlack: {
    color: "#000000",
  },
  textSalmon: {
    color: "#FEA0A0",
  },
  textLightYellow: {
    color: "#FFFFBF",
  },
  textLightGreen: {
    color: "#BFFFBE",
  },
  textLightblue: {
    color: "#7FC8FF",
  },
  textLightpink: {
    color: "#BFFFFF",
  },
  textLightOrange: {
    color: "#FF7FFE",
  },
  textLightOrange: {
    color: "#F5A173",
  },
  bgRed: {
    backgroundColor: "#FE0002",
  },
  bgYellow: {
    backgroundColor: "#FFFF00",
  },
  bgGreen: {
    backgroundColor: "#00FF00",
  },
  bgBlue: {
    backgroundColor: "#018FFD",
  },
  bgTourqua: {
    backgroundColor: "#00FFFF",
  },
  bgPink: {
    backgroundColor: "#FC02FA",
  },
  bgOrage: {
    backgroundColor: "#F36523",
  },
  bgWhite: {
    backgroundColor: "#FFFFFF",
  },
  bgLightGrey: {
    backgroundColor: "#CCCCCC",
  },
  bgGrey: {
    backgroundColor: "#959595",
  },
  bgBlack: {
    backgroundColor: "#000000",
  },
  bgSalmon: {
    backgroundColor: "#FEA0A0",
  },
  bgLightYellow: {
    backgroundColor: "#FFFFBF",
  },
  bgLightGreen: {
    backgroundColor: "#BFFFBE",
  },
  bgLightblue: {
    backgroundColor: "#7FC8FF",
  },
  bgLightpink: {
    backgroundColor: "#BFFFFF",
  },
  bgLightOrange: {
    backgroundColor: "#FF7FFE",
  },
  bgLightOrange: {
    backgroundColor: "#F5A173",
  },
  smText: {
    fontSize: "18px",
  },
  mdText: {
    fontSize: "22px",
  },
  lgText: {
    fontSize: "24px",
  },
  alignTextLeft: {
    textAlign: "left",
    float: "left",
  },
  alignTextCenter: {
    textAlign: "center",
    float: "none",
  },
  alignTextRight: {
    textAlign: "right",
    float: "right",
  },
  toRtl: {
    direction: "rtl",
  },
  toLtr: {
    direction: "ltr",
  },
  defaultStyle: {
    color: "#ffffff",
    backgroundColor: "transparent",
    fontWeight: 400,
    textDecoration: "none",
  },
  defaultText: {
    color: "#ffffff",
  },
  defaultBg: {
    color: "#ffffff",
    backgroundColor: "transparent",
    fontWeight: 400,
    textDecoration: "none",
  },
};

export default styleMap;
