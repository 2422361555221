import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    textColorHex: "",
    isEditing: false,
    currentDocID: "",
    refreshData: "",
    readonly: false,
    docType: "",
    deleteDocID: "",
    docData: {},
    textDirection: "center",
    playSpeed: 25,
    play: false,
    showTimer: false,
    showTextLine: true,
    breakOnSentenceEnd: false,
    breakOnTriggerColor: false,
    allCaps: false,
    dualMonitor1: false,
    flipHorizontal1: false,
    flipVertical1: false,
    dualMonitor2: false,
    flipHorizontal2: false,
    flipVertical2: false,
    lineSpacing: 0,
    margin: 100,
    forward: false,
    backword: false,
    startover: false,
    monitor1: {},
    monitor2: {},
    textRTL: "LTR",
    view: 0,
    masterScreen1: true,
    masterScreen2: false,
    monitors: [],
    saveMode: false,
    functionsList: [],
    loggedToVmix: false,
    selectedFunction: {},
    editFunction: {},
    removeListener: false,
    outerHeight: 720,
    outerWidth: 1280,
    textSize: 3,
    vmixObject: {
      address: "",
      port: "",
      status: "",
    },
    socket: {},
    loginToVmix: false,
    inputs:[],
    showPanel:false,
    runAgain: false,
    driveLink: ""
    
  },
  reducers: {
    setTextColorHex: (state, action) => {
      state.textColorHex = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setCurrentDocID: (state, action) => {
      state.currentDocID = action.payload;
    },
    setRefreshData: (state, action) => {
      state.refreshData = action.payload;
    },
    setReadonly: (state, action) => {
      state.readonly = action.payload;
    },
    setDeletDocID: (state, action) => {
      state.deleteDocID = action.payload;
    },
    setTextSize: (state, action) => {
      state.textSize = action.payload;
    },
    setPlaySpeed: (state, action) => {
      state.playSpeed = action.payload;
    },
    setPlay: (state, action) => {
      state.play = action.payload;
    },
    setShowTimer: (state, action) => {
      state.showTimer = action.payload;
    },
    setShowTextLine: (state, action) => {
      state.showTextLine = action.payload;
    },
    setBreakOnSentenceEnd: (state, action) => {
      state.breakOnSentenceEnd = action.payload;
    },
    setBreakOnTriggerColor: (state, action) => {
      state.breakOnTriggerColor = action.payload;
    },
    setAllCaps: (state, action) => {
      state.allCaps = action.payload;
    },
    setDualMonitor1: (state, action) => {
      state.dualMonitor1 = action.payload;
    },
    setFlipHorizontal1: (state, action) => {
      state.flipHorizontal1 = action.payload;
    },
    setFlipVertical1: (state, action) => {
      state.flipVertical1 = action.payload;
    },
    setDualMonitor2: (state, action) => {
      state.dualMonitor2 = action.payload;
    },
    setFlipHorizontal2: (state, action) => {
      state.flipHorizontal2 = action.payload;
    },
    setFlipVertical2: (state, action) => {
      state.flipVertical2 = action.payload;
    },
    setScreenMargin: (state, action) => {
      state.margin = action.payload;
    },
    setLineSpacing: (state, action) => {
      state.lineSpacing = action.payload;
    },
    setBackword: (state, action) => {
      state.backword = action.payload;
    },
    setForward: (state, action) => {
      state.forward = action.payload;
    },
    setStartover: (state, action) => {
      state.startover = action.payload;
    },
    setMonitor1: (state, action) => {
      state.monitor1 = action.payload;
    },
    setMonitor2: (state, action) => {
      state.monitor2 = action.payload;
    },
    setTextDirection: (state, action) => {
      state.textDirection = action.payload;
    },
    setTextRTL: (state, action) => {
      state.textRTL = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    setDocType: (state, action) => {
      state.docType = action.payload;
    },
    setMasterScreen1: (state, action) => {
      state.masterScreen1 = action.payload;
    },
    setMasterScreen2: (state, action) => {
      state.masterScreen2 = action.payload;
    },
    setMonitors: (state, action) => {
      state.monitors = action.payload;
    },
    setSaveMode: (state, action) => {
      state.saveMode = action.payload;
    },
    setFunctionsList: (state, action) => {
      state.functionsList = action.payload;
    },
    setLoggedToVmix: (state, action) => {
      state.loggedToVmix = action.payload;
    },
    setSelectedFunction: (state, action) => {
      state.selectedFunction = action.payload;
    },
    setEditFunction: (state, action) => {
      state.editFunction = action.payload;
    },
    setDocData: (state, action) => {
      state.docData = action.payload;
    },
    setRemoveListener: (state, action) => {
      state.removeListener = action.payload;
    },
    setOuterHeight: (state, action) => {
      state.outerHeight = action.payload;
    },
    setOuterWidth: (state, action) => {
      state.outerWidth = action.payload;
    },
    setVmixObject: (state, action) => {
      
      state.vmixObject = action.payload;
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setLoginToVmix: (state, action) => {
      state.loginToVmix = action.payload;
    },
    setInputs:(state, action) => {
      state.inputs = action.payload;
    },
    setShowPanel:(state, action) => {
      state.showPanel = action.payload;
    },
    setRunAgain:(state, action) => {
      state.runAgain = action.payload;
    },
    setDriveLink:(state, action) => {
      state.driveLink = action.payload;
    },
  },
});

// this is for dispatch
export const {
  setTextColorHex,
  setCurrentDocID,
  setIsEditing,
  setRefreshData,
  setReadonly,
  setDeletDocID,
  setPlay,
  setPlaySpeed,
  setTextSize,
  setAllCaps,
  setBreakOnSentenceEnd,
  setBreakOnTriggerColor,
  setDualMonitor1,
  setDualMonitor2,
  setFlipHorizontal1,
  setFlipHorizontal2,
  setFlipVertical1,
  setFlipVertical2,
  setShowTextLine,
  setShowTimer,
  setLineSpacing,
  setScreenMargin,
  setBackword,
  setForward,
  setStartover,
  setMonitor1,
  setMonitor2,
  setTextDirection,
  setTextRTL,
  setView,
  setDocType,
  setMasterScreen1,
  setMasterScreen2,
  setMonitors,
  setSaveMode,
  setFunctionsList,
  setLoggedToVmix,
  setSelectedFunction,
  setEditFunction,
  setDocData,
  setRemoveListener,
  setOuterHeight,
  setOuterWidth,
  setVmixObject,
  setSocket,
  setLoginToVmix,
  setInputs,
  setShowPanel,
  setRunAgain,
  setDriveLink
} = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
