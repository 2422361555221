import { RichUtils, Modifier, EditorState, convertFromRaw, EditorBlock, ContentState, ContentBlock } from "draft-js";
import * as utils from "../Redux/Utils";

const Immutable = require("immutable");

export const setToast = ({ state, text }) => {
  const ev = new CustomEvent("setToast", { detail: { state, text } });
  document.dispatchEvent(ev);
  return;
};
export const changeView = (data) => {
  const ev = new CustomEvent("changeView", { detail: data });
  document.dispatchEvent(ev);
  return;
};
export const callVmix = (data, delay, isActive) => {
  if (!isActive) {
    return;
  }
  if (delay) {
    const parsed = parseInt(delay);
    console.log("delay", parsed);
    let timeout = setTimeout(() => {
      const ev = new CustomEvent("callVmix", { detail: data });
      document.dispatchEvent(ev);
      console.log("done !");
      clearTimeout(timeout);
    }, parsed);
  }
  const ev = new CustomEvent("callVmix", { detail: data });
  document.dispatchEvent(ev);
  return;
};
export const disptachToVmix = (type, data) => {
  const ev = new CustomEvent(type, { detail: data });
  document.dispatchEvent(ev);
  return;
};
export const resetFuncs = () => {
  const ev = new CustomEvent("resetFuncs");
  document.dispatchEvent(ev);
  return;
};
export const setOnTypeData = (data) => {
  const ev = new CustomEvent("saveOnType", { detail: data });
  document.dispatchEvent(ev);
  return;
};
export const setOnTypeChange = (data) => {
  const ev = new CustomEvent("saveOnChange", { detail: data });
  document.dispatchEvent(ev);
  return;
};
export const sendMonitorCommand = (monitor, command, value) => {
  if (!monitor) {
    return;
  }
  if (!monitor.hasOwnProperty("Window")) {
    return;
  }
  const ev = new CustomEvent("prompterListener", { detail: { command, value } });
  monitor.dispatchEvent(ev);
  return;
};
export const createCommand = (editorState, setEditorState, command) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const contentStateWithStyle = Modifier.applyInlineStyle(contentState, selectionState, command);
  const state = EditorState.push(editorState, contentStateWithStyle, "change-inline-style");
  setEditorState(state);

  setOnTypeChange(state.getCurrentContent());
};
export const createCommandToggle = (editorState, setEditorState, command) => {
  const state = RichUtils.toggleInlineStyle(editorState, command);
  setEditorState(state);
  setOnTypeChange(state.getCurrentContent());
};
export function appendAfter(newElement, existingElement) {
  existingElement.parentNode.insertBefore(newElement, existingElement.nextSibling);
}
function filterChars(str, chars) {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    if (!chars.includes(str[i])) {
      result += str[i];
    }
  }
  return result;
}

export const setSpaceAfterDot = (value) => {
  if (value) {
    const elementss = [...document.querySelectorAll("span")].filter((a) => {
      return a.textContent.includes(".");
    });
    elementss.forEach((el) => {
      console.log(el.textContent);
      const res = el.textContent + "\n";
      el.textContent = res;
    });
    return;
  }
  if (!value) {
    const elementss = [...document.querySelectorAll("span")].filter((a) => {
      return a.textContent.includes(".");
    });
    elementss.forEach((el) => {
      console.log(el.textContent);
      const res = filterChars(el.textContent, "\n");
      el.textContent = res;
    });
    return;
  }
};
export function calculatePercentage(value, total) {
  if (total === 0) {
    return 0;
  }

  const percentage = Math.round((value / total) * 100);
  return percentage;
}
export function calculateOppositePercentage(value, total) {
  if (total === 0) {
    return 0;
  }

  const currentPercentage = (value / total) * 100;
  const oppositePercentage = 100 - currentPercentage;
  return Math.round(oppositePercentage);
}
export function toggleLineSpaceAfterDot(editorState) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  // Get the current block where the cursor is located
  const currentBlock = contentState.getBlockForKey(selectionState.getStartKey());

  // Check if the current block contains a . character
  const text = currentBlock.getText();
  const dotIndex = text.indexOf(".", selectionState.getStartOffset());
  if (dotIndex !== -1) {
    // Check if there is already a line break after the . character
    const blockMap = contentState.getBlockMap();
    const dotKey = currentBlock.getKey();
    const lineBreakBlock = blockMap.find((block) => block.getType() === "unstyled" && block.getText().startsWith("\n") && block.getKey() !== dotKey);
    const hasLineBreak = lineBreakBlock && lineBreakBlock.getText().slice(1, 2) === ".";

    // Add or remove a line break after the . character
    let newContentState;
    if (!hasLineBreak) {
      const dotSelection = selectionState.merge({
        anchorOffset: dotIndex,
        focusOffset: dotIndex + 1,
      });
      newContentState = Modifier.insertText(contentState, dotSelection, ".\n");
    } else {
      const lineBreakSelection = selectionState.merge({
        anchorKey: lineBreakBlock.getKey(),
        focusKey: lineBreakBlock.getKey(),
        anchorOffset: 0,
        focusOffset: 1,
      });
      newContentState = Modifier.removeRange(contentState, lineBreakSelection);
    }

    // Update the editor state with the new content state
    const newEditorState = EditorState.push(editorState, newContentState, "split-block");
    return newEditorState;
  }

  return editorState;
}

export function removeSpaceAfterDot(editorState, setEditorState) {
  const contentState = editorState.getCurrentContent();
  let newContentState = contentState;

  contentState.getBlockMap().forEach((block) => {
    const text = block.getText();
    const updatedText = text.replace(/\.(\n)/g, ". ");

    if (text !== updatedText) {
      const newBlock = block.merge({ text: updatedText });
      newContentState = newContentState.merge({
        blockMap: newContentState.getBlockMap().set(block.getKey(), newBlock),
      });
    }
  });

  const newEditorState = EditorState.push(editorState, newContentState, "insert-characters");

  setEditorState(newEditorState);
}
function replaceCharsRecursively(str, oldChar, newChar) {
  if (str === "") {
    return "";
  }
  const firstChar = str[0];
  let restOfStr = "";
  if (str.length > 1) {
    restOfStr = str.slice(1);
  }
  let replacedChar = firstChar;
  if (firstChar === oldChar) {
    replacedChar = newChar;
  }
  return replacedChar + replaceCharsRecursively(restOfStr, oldChar, newChar);
}
export function addSpaceAfterDot(editorState, setEditorState) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  let newContentState = contentState;

  contentState.getBlockMap().forEach((contentBlock) => {
    const blockKey = contentBlock.getKey();
    let blockText = contentBlock.getText();
    // // Add a line break after "{char}" if not present
    // if (blockText.includes(".")) {
    //   blockText = blockText.replace("\n", "");
    // }
    // const str = replaceCharsRecursively(blockText, ".", `${char}\n`);
    blockText = blockText.replace(/\.(.)/g, ".\n");

    // blockText = str;

    // Update the block text in the content state
    newContentState = newContentState.merge({
      blockMap: newContentState.getBlockMap().set(
        blockKey,
        contentBlock.merge({
          text: blockText,
        })
      ),
    });
  });

  // Create a new editor state with the updated content state and selection
  const newEditorState = EditorState.push(editorState, newContentState, "change-block-data");

  setEditorState(newEditorState);
}
function findBlockByOffsetKey(contentState, offsetKey) {
  const blockMap = contentState.getBlockMap();

  for (const block of blockMap.values()) {
    console.log(block.getKey());
    if (block.getKey() == offsetKey) {
      // return block;
    }
  }

  return null;
}
function replaceStringInString(searchString, targetString, replacementString) {
  // Escape any special characters in the searchString
  const escapedSearchString = searchString.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(escapedSearchString, "g");

  const resultString = targetString.replace(regex, replacementString);
  return resultString;
}
export const addLineBreakAfterPeriod = (editorState, setEditorState, value) => {
  if (value) {
    const elem = document.querySelector(".DraftEditor-root");
    const elements = elem.querySelectorAll("*");
    elements.forEach((element) => {
      const textContent = element.textContent;
      if (textContent.includes(".")) {
        element.classList.add("element-with-bg-color");
      }
    });
  }
  if (!value) {
    const elem = document.querySelector(".DraftEditor-root");
    const elements = elem.querySelectorAll("*");
    elements.forEach((element) => {
      if (element.classList.contains("element-with-bg-color")) {
        element.classList.remove("element-with-bg-color");
      }
    });
    return;
  }
};
export const maybe = (editorState, setEditorState, value, dispatch) => {};
export const addLineBreakAfterBackground = (editorState, setEditorState, value) => {
  if (value) {
    const elem = document.querySelector(".DraftEditor-root");
    const elements = elem.querySelectorAll("*");

    elements.forEach((element) => {
      const backgroundColor = element.style.backgroundColor;
      if (backgroundColor) {
        const first = element.children[0];
        if (first) {
          if (first.textContent) {
            element.classList.add("element-with-bg-color");
          }
        }
      }
    });
  }
  if (!value) {
    const elem = document.querySelector(".DraftEditor-root");
    const elements = elem.querySelectorAll("*");
    elements.forEach((element) => {
      const backgroundColor = element.style.backgroundColor;
      if (backgroundColor) {
        element.classList.remove("element-with-bg-color");
      }
    });
    return;
  }
};

export const handleDotSpace = (value, editorState, setEditorState, dispatch) => {
  if (value) {
    const currentContent = editorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();
    let newBlocks = [];
    blockMap.forEach((contentBlock) => {
      const text = contentBlock.getText();
      const fragments = text.split(".");
      fragments.forEach((fragment, index) => {
        const key = `${contentBlock.getKey()}-${index}`;
        const newContentBlock = new ContentBlock({
          key,
          type: contentBlock.getType(),
          text: `${fragment.trim()}.`, // Trim to remove leading/trailing whitespaces
        });
        newBlocks.push(newContentBlock);
      });
    });

    const newContentState = ContentState.createFromBlockArray(newBlocks);

    const newEditorState = EditorState.push(editorState, newContentState, "split-blocks");

    setEditorState(newEditorState);
    utils.setRunAgain(dispatch, false);
    setTimeout(() => {
      addBlockWithHeightToEditor("start");
    }, 500);

  }
  if (!value) {
    utils.setRunAgain(dispatch, true);

  }
  let timeout =  setTimeout(() => {
    addBlockWithHeightToEditor("start");
    clearTimeout(timeout)
  }, 500);
};
export function addNewLineAfterChar(str, char) {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    result += str[i];
    if (str[i] === char) {
      result += "\n" + "\n";
    }
  }
  return result;
}
export const startOver = (monitor, setstartover, startover, monitor2, setlastPosition) => {
  const elem = document.querySelector(".DraftEditor-root");
  elem.scrollTo(0, 0);
  const toremove = ["doneAlready", "outDoneAlready", "doneAlreadyText", "outDoneAlreadyText", "doneAlready"];
  let all = [
    ...document.querySelectorAll(".doneAlready"),
    ...document.querySelectorAll(".outDoneAlready"),
    ...document.querySelectorAll(".doneAlreadyText"),
    ...document.querySelectorAll(".outDoneAlreadyText"),
    ...document.querySelectorAll(".doneAlready"),
  ];
  all.forEach((el) => {
    el.classList.remove(toremove);
  });

  if (monitor) {
    sendMonitorCommand(monitor, "startOver", !startOver);
  }

  setlastPosition(0);
};
export const toDateTime = (secs) => {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return { date: t.toLocaleDateString(), time: t.toLocaleTimeString() };
};

export const loadDocToEditor = (data) => {
  const ev = new CustomEvent("setLoadedDoc", { detail: data });
  document.dispatchEvent(ev);
  return;
};
export const resetEditor = () => {
  const ev = new CustomEvent("resetEditor");
  document.dispatchEvent(ev);
  return;
};
export const compareUpdateTime = (a, b) => {
  if (a.timestamp > b.timestamp) {
    return -1;
  }
  if (a.timestamp < b.timestamp) {
    return 1;
  }
  return 0;
};
export const deleteDocument = (data) => {
  const ev = new CustomEvent("deleteDoc", { detail: data });
  document.dispatchEvent(ev);
  return;
};

const Marker = () => {
  return <div className="customMarker">---------------------------------</div>;
};

export const LinesMarker = Immutable.Map({
  walla: {
    element: "div",
    wrapper: <Marker />,
  },
});
export const calculateScrollTimer = (distance, scrollSpeed) => {
  const scrollDuration = distance / scrollSpeed; // Calculate the scroll duration in milliseconds
  return scrollDuration;
};
export function calculateScrollTime(pixelsToScroll, scrollRateMsPerPixel, takeOff, maxPixel) {
  const pixles = pixelsToScroll - takeOff;
  const scrollDuration = calculateScrollTimer(pixelsToScroll, scrollRateMsPerPixel);
  let scrollTimeSeconds = scrollDuration;
  let scrollTimeMinutes = Math.floor(scrollTimeSeconds / 60);
  let scrollTimeSecondsRemaining = Math.floor(scrollTimeSeconds % 60);
  let seconds = "";
  let minutes = "";
  if (scrollTimeMinutes > 0) {
    minutes += scrollTimeMinutes;
  }

  seconds += scrollTimeSecondsRemaining;

  if (scrollTimeMinutes < 0) {
    return `00:${seconds}`;
  }

  return `${minutes}${minutes && ":"}${seconds}`;
}
export const calculateRemainingScrollPercentage = (scrollableElement) => {
  const totalScrollHeight = scrollableElement.scrollHeight - scrollableElement.clientHeight;
  const remainingScroll = totalScrollHeight - scrollableElement.scrollTop;
  return 100 - (remainingScroll / totalScrollHeight) * 100;
};
export const setPageMargin = (value) => {
  try {
    const elem = document.querySelector(".alrighty");
    elem.style.width = `${value}%`;
  } catch (err) {}
};

export const setPageSpaceLine = (value, dispatch) => {
  try {
    const elem = document.querySelector("[data-text]");
    const computedStyle = window.getComputedStyle(elem);
    const lineHeight = computedStyle.fontSize;
    const splitted = Math.round(parseInt(lineHeight.split("px")[0]));
    const num = splitted * 1.54;
    utils.setLineSpacing(dispatch, num);
  } catch (err) {
    console.log(err);
  }
};

export function convertToUpperCase(state, editorState) {
  if (state) {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlockMap();

    let newContentState = contentState;

    blocks.forEach((block) => {
      const text = block.getText();
      const newText = text.toUpperCase();
      const newBlock = block.merge({ text: newText });
      newContentState = newContentState.merge({
        blockMap: newContentState.getBlockMap().set(block.getKey(), newBlock),
      });
    });

    const newEditorState = EditorState.push(editorState, newContentState, "change-block-data");
    return newEditorState;
  }
  if (!state) {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlockMap();

    let newContentState = contentState;

    blocks.forEach((block) => {
      const text = block.getText();
      const newText = text.charAt(0) + text.slice(1).toLowerCase();
      const newBlock = block.merge({ text: newText });
      newContentState = newContentState.merge({
        blockMap: newContentState.getBlockMap().set(block.getKey(), newBlock),
      });
    });

    const newEditorState = EditorState.push(editorState, newContentState, "change-block-data");
    return newEditorState;
  }
}

export const flipPageVerical = (value) => {
  const elem = document.querySelectorAll(".DraftEditor-root");
  elem.forEach((el) => {
    if (!value) {
      el.classList.remove("flipBoth");

      el.classList.remove("flipVertical");
      return;
    }
    if (el.classList.contains("flipHorizontal")) {
      el.classList.remove("flipHorizontal");
      el.classList.remove("flipVertical");
      el.classList.add("flipBoth");
      return;
    }

    el.classList.add("flipVertical");
    return;
  });
};

export const flipPageHorizontal = (value) => {
  const elem = document.querySelectorAll(".DraftEditor-root");
  elem.forEach((el) => {
    if (!value) {
      el.classList.remove("flipBoth");

      el.classList.remove("flipHorizontal");
      return;
    }
    if (el.classList.contains("flipVertical")) {
      el.classList.remove("flipHorizontal");
      el.classList.remove("flipVertical");
      el.classList.add("flipBoth");
      return;
    }
    el.classList.add("flipHorizontal");
    return;
  });
};
export const clearLocal = () => {
  console.log("vleart aaaaaaaaaaaaaaaaaaaaaaaaaaaa here");
  const clearObj = [
    "textDirection",
    "lastPosition",
    "showPointer",
    "setBreakOnSentenceEnd",
    "textSize",
    "textSize",
    "setBreakOnTriggerColor",
    "setFlipHorizontal1",
    "setFlipVertical1",
    "screenMargin",
    "lineSpacing",
  ];
  clearObj.forEach((el) => localStorage.removeItem(el));
  return;
};
export function toggleInlineStyle(editorState, setEditorState, style) {
  const selectionState = editorState.getSelection();

  // Apply the style to the selected range
  const contentStateWithStyle = Modifier.applyInlineStyle(editorState.getCurrentContent(), selectionState, style);

  // Create a new EditorState with the updated content
  const newEditorState = EditorState.push(editorState, contentStateWithStyle, "change-inline-style");
  setEditorState(newEditorState);
  // const selectionState = editorState.getSelection();

  // const contentState = editorState.getCurrentContent();
  // const blockMap = contentState.getBlockMap();
  // const newBlockMap = blockMap.map((block) => {
  //   const text = block.getText();
  //   let newCharacterList = block.getCharacterList();

  //   if (text) {
  //     const firstChar = newCharacterList.get(0);
  //     const currentStyle = firstChar.getStyle();

  //     if (currentStyle.has(style)) {
  //       newCharacterList = newCharacterList.map((char) => char.set("style", char.getStyle().subtract([style])));
  //     } else {
  //       newCharacterList = newCharacterList.map((char) => char.set("style", char.getStyle().add(style)));
  //     }
  //   }

  //   return block.set("characterList", newCharacterList);
  // });

  // const newContentState = contentState.merge({
  //   blockMap: newBlockMap,
  //   selectionAfter: contentState.getSelectionAfter().merge({
  //     anchorOffset: selectionState.getAnchorOffset(),
  //     focusOffset: selectionState.getFocusOffset(),
  //   }),
  // });

  // const newState = EditorState.push(editorState, newContentState, "change-block-data");
  // setOnTypeChange(newState);
  // setEditorState(newState);
}

export const getPreviewText = (script) => {
  if (!script) {
    return "";
  }
  const parsed = JSON.parse(script);
  const content = convertFromRaw(parsed);
  const str = content.getPlainText();
  return str.substring(0, 150);
};

export function appendElementInNewLine(editorState, setEditorState) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const key = selectionState.getStartKey();
  const text = "\n---------------------------------\n";
  const contentStateWithEntity = Modifier.insertText(contentState, selectionState, text);

  const newEditorState = EditorState.push(editorState, contentStateWithEntity, "insert-fragment");

  // Move the cursor to the end of the block after inserting the separator.
  const newSelectionState = selectionState.merge({
    anchorOffset: selectionState.getStartOffset() + text.length,
    focusOffset: selectionState.getStartOffset() + text.length,
  });

  const finalEditorState = EditorState.forceSelection(newEditorState, newSelectionState);
  setEditorState(finalEditorState);
  setOnTypeChange(finalEditorState.getCurrentContent());
  // const newContentState = Modifier.insertText(
  //   editorState.getCurrentContent(),
  //   editorState.getSelection(),
  //   "\n---------------------------------\n",
  //   editorState.getCurrentInlineStyle()
  // );

  // const newBlockMap = newContentState.getBlockMap();
  // const newBlock = newContentState.getFirstBlock();

  // const newContentStateWithSelection = newContentState.merge({
  //   selectionAfter: editorState.getSelection().merge({
  //     anchorKey: newBlock.getKey(),
  //     focusKey: newBlock.getKey(),
  //     anchorOffset: newBlock.getLength(),
  //     focusOffset: newBlock.getLength(),
  //     isBackward: false,
  //   }),
  // });

  // const newEditorState = EditorState.push(editorState, newContentStateWithSelection, "insert-fragment");
  // setEditorState(newEditorState);
  // setOnTypeChange(newEditorState.getCurrentContent());
}

export function isScrolledIntoView(elem) {
  const elemRoot = document.querySelector(".DraftEditor-root");

  var docViewTop = window.$(elemRoot).scrollTop();
  var docViewBottom = docViewTop + window.$(elemRoot).height();

  var elemTop = window.$(elem).offset().top;
  var elemBottom = elemTop + window.$(elem).height();

  return elemBottom <= docViewBottom && elemTop >= docViewTop;
}

export function getCoordinatesInParent(parent, child) {
  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();
  const top = childRect.top - parentRect.top;
  const left = childRect.left - parentRect.left;
  return { top, left };
}

export function logMouseWheelScroll(event, elem, setlastPosition) {
  const scrollableElement = elem;
  const scrollDistance = event.deltaY;
  const currentScrollPosition = scrollableElement.scrollTop;
  const promptBlock = document.querySelector(".promptBlock");
  let takeOff = getComputedStyle(promptBlock).height;
  takeOff = Math.round(takeOff.split("px")[0]) * 2 + 30;
  if (scrollableElement.scrollHeight - currentScrollPosition - takeOff < window.screen.height || currentScrollPosition === 0) {
    return;
  }

  setlastPosition((prev) => prev + scrollDistance);
  return scrollDistance;
}
export function logMouseWheelScrollEvent(event, elem) {
  const scrollableElement = elem;
  const scrollDistance = event.deltaY; // the number of pixels scrolled by the mouse wheel
  const currentScrollPosition = scrollableElement.scrollTop;
  const promptBlock = document.querySelector(".promptBlock");
  let takeOff = getComputedStyle(promptBlock).height;
  takeOff = Math.round(takeOff.split("px")[0]) * 2 + 30;
  if (scrollableElement.scrollHeight - currentScrollPosition - takeOff < window.screen.height || currentScrollPosition === 0) {
    return;
  }

  return scrollDistance;
}
export function addBlockWithHeightToEditor(type) {
  const elem = document.querySelector("[data-contents]");
  const div = document.createElement("div");

  // if (document.querySelector(".promptBlock")) {
  //   return;
  // }
  const all = document.querySelectorAll(".promptBlock");
  all.forEach((el) => {

    if (el.textContent.includes("PLAY TO START") || el.textContent.includes("END")) {
      el.remove();
    }
  });
  div.classList.add("promptBlock", "d-flex", "justify-content-center", "align-items-center", "text-center");
  div.textContent = "PLAY TO START";
  const div2 = document.createElement("div");
  div2.classList.add("promptBlock", "d-flex", "justify-content-center", "align-items-center", "text-center");
  div2.textContent = "END";
  elem.firstChild.before(div);
  elem.lastChild.after(div2);
}

export const handleBackToEditor = (dispatch) => {
  // window.location.href = "/editor";
  changeView(1);
};

export function rgbToHex(r, g, b) {
  // convert each component to hex and concatenate them
  const hex = ((r << 16) | (g << 8) | b).toString(16);
  // pad the hex string with zeros to a length of 6
  return hex.padStart(6, "0");
}
export const CustomBlock = (props) => {
  const { block } = props;
  const blockType = block.getType();
  // Set the height and text for the custom block
  let height = 50;
  let text = "";
  if (blockType === "custom-start") {
    text = "Start of Document";
  } else if (blockType === "custom-end") {
    text = "End of Document";
  }

  // Render the custom block
  return (
    <div style={{ height: height }}>
      <EditorBlock {...props}>{text}</EditorBlock>
    </div>
  );
};
// function appendElementWithStyle({ editorState, text, backgroundColor, textColor }) {
//   const backgroundName = `bg${backgroundColor}`;
//   const colorName = `text${textColor}`;

//   setstyles((prev) => {
//     return {
//       ...prev,
//       [backgroundName]: {
//         backgroundColor: `#${backgroundColor}`,
//       },
//       [colorName]: {
//         color: `#${textColor}`,
//       },
//     };
//   });

//   const contentState = editorState.getCurrentContent();
//   EditorState.createEmpty().getCurrentContent().createEntity();
//   const selectionState = editorState.getSelection();

//   const contentStateWithEntity = contentState.createEntity("CUSTOM-STYLE", "IMMUTABLE");

//   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
//   const newContentState = Modifier.insertText(contentState, selectionState, text, OrderedSet.of(colorName, backgroundName), entityKey);

//   const newEditorState = EditorState.push(editorState, newContentState, "apply-entity");

//   return newEditorState;
// }

export const handleKeysListener = function ({
  keysList,
  sendMonitorCommand,
  setScrollSpeed,
  setCurrentMarker,
  setPreviewsMarker,
  setForward,
  setBackword,
  handleBackToEditor,
  setPlay,
  monitor1,
  monitor2,
  dispatch,
  target,
  play,
  e,
}) {
  e.preventDefault();
  e.stopPropagation();
  [...keysList].forEach((el) => {
    if (el.keyCode === target) {
      const command = el.text;
      if (command === "Play/Pause") {
        setPlay((prev) => !prev);
        return;
      }
      if (command === "Backword") {
        sendMonitorCommand(monitor1, "setBackword", true);
        setBackword(true);
      }
      if (command === "Forward") {
        sendMonitorCommand(monitor1, "setForward", true);
        setForward(true);
      }

      if (command === "Restart") {
        document.getElementById("startOver").click();
        return;
      }
      if (command === "Slower Roll") {
        console.log("Slower Roll");
        // const elem = document.querySelector("#scrollSpeed");
        // console.log(elem);
        setScrollSpeed((prev) => prev - 1);
      }
      if (command === "Faster Roll") {
        console.log("faster");
        setScrollSpeed((prev) => prev + 1);
      }

      if (command === "Prev Marker") {
        setPreviewsMarker((prev) => prev + 1);
        return;
      }
      if (command === "Next Marker") {
        setCurrentMarker((prev) => prev + 1);
        return;
      }
      if (command === "Edit Script") {
        handleBackToEditor(dispatch);
      }
      if (command === "Full Screen") {
        const elem = document.getElementById("mainFs");
        elem.click();
      }
    }
  });
};

export const handleUpKeys = function ({ keysList, e, sendMonitorCommand, setBackword, setForward, monitor1, monitor2 }) {
  e.preventDefault();
  e.stopPropagation();
  keysList.forEach((el) => {
    const target = e.code;
    if (el.keyCode === target) {
      const command = el.text;
      if (command === "Backword") {
        // sendMonitorCommand(monitor1, "setBackword", false);
        setBackword(false);
      }
      if (command === "Forward") {
        // sendMonitorCommand(monitor1, "setForward", false);
        setForward(false);
      }
      return;
    }
  });
};

export function hideScrollbar(element) {
  element.style.overflowY = "scroll";
  element.style.scrollbarWidth = "none"; // For Firefox
  element.style.msOverflowStyle = "none"; // For Internet Explorer

  // For Chrome, Safari, and other WebKit browsers
  element.style.webkitOverflowScrolling = "touch";
  element.style["::-webkit-scrollbar"] = "display: none";
  element.addEventListener("mouseover", () => {
    element.style["::-webkit-scrollbar"] = "display: none";
  });
}

export const toggleScrollbar = (value) => {
  try {
    const elem = document.querySelector(".prompterArea");
    const elemRoot = document.querySelector(".prompterPointer");

    if (value) {
      elem.classList.add("hideScroll");
      elemRoot.classList.add("w-100");

      return;
    }
    elem.classList.remove("hideScroll");
    elemRoot.classList.remove("w-100");

    return;
  } catch (err) {
    console.log(err);
  }
};

export function toggleFullscreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen().catch((err) => {
      alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
    });
  } else {
    document.exitFullscreen();
  }
}

export function parseVmixXml(xmlText) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlText, "application/xml");
  const inputsNode = xmlDoc.getElementsByTagName("inputs")[0];
  const inputs = Array.from(inputsNode.getElementsByTagName("input"));
  return inputs.map((input) => ({
    key: input.getAttribute("key"),
    number: input.getAttribute("number"),
    title: input.getAttribute("title"),
    type: input.getAttribute("type"),
    state: input.getAttribute("state"),
  }));
}
export const scrollToRateToTop = (timestamp, lastTimestamp, rate, scrollRef) => {
  const elemRoot = document.querySelector(".DraftEditor-root");

  const delta = timestamp - lastTimestamp;
  const scrollAmount = -(rate * delta) / 1000;

  elemRoot.scrollBy(0, scrollAmount);
  scrollRef.current = window.requestAnimationFrame((newTimestamp) => scrollToRateToTop(newTimestamp, timestamp, rate, scrollRef));
};

export const toggleRowStyle = (e) => {
  const tableRow = document.querySelectorAll(".tableRow");
  tableRow.forEach((el) => {
    if (el != e.currentTarget) {
      el.classList.remove("selectedLine");
    } else {
      el.classList.toggle("selectedLine");
    }
  });
};
export const startScrollingTop = (scrollSpeed, scrollRef) => {
  const initialTimestamp = performance.now();
  scrollRef.current = window.requestAnimationFrame((timestamp) => scrollToRateToTop(timestamp, initialTimestamp, scrollSpeed, scrollRef));
};
export const scrollToRate = (timestamp, lastTimestamp, rate, scrollRef) => {
  const elemRoot = document.querySelector(".DraftEditor-root");

  const delta = timestamp - lastTimestamp;
  const scrollAmount = (rate * delta) / 1000;

  elemRoot.scrollBy(0, scrollAmount);
  scrollRef.current = window.requestAnimationFrame((newTimestamp) => scrollToRate(newTimestamp, timestamp, rate, scrollRef));
};

export const startScrolling = (scrollSpeed, scrollRef) => {
  const initialTimestamp = performance.now();
  scrollRef.current = window.requestAnimationFrame((timestamp) => scrollToRate(timestamp, initialTimestamp, scrollSpeed, scrollRef));
};

export const stopScrolling = (scrollRef) => {
  if (scrollRef.current) {
    window.cancelAnimationFrame(scrollRef.current);
    scrollRef.current = null;
  }
};

export const handlePointerToggle = function (value) {
  const elem = document.querySelector(".prompterArea");

  if (value) {
    elem.classList.add("alrighty");
  }
  if (!value) {
    elem.classList.remove("alrighty");
  }
};

export const removeBgStyles = (editorState, setEditorState) => {
  const selection = editorState.getSelection(); // the current selection state
  const contentState = editorState.getCurrentContent(); // the current content state

  // Get the current inline styles
  const inlineStyles = editorState.getCurrentInlineStyle();

  let contentStateWithoutStyles = contentState;

  // Loop over all inline styles and remove them
  inlineStyles.forEach((style) => {
    contentStateWithoutStyles = Modifier.removeInlineStyle(contentStateWithoutStyles, selection, style);
  });

  // Update the editor state with the new content state
  const newState = EditorState.push(editorState, contentStateWithoutStyles, "change-inline-style");

  setEditorState(newState);
  setOnTypeChange(newState.getCurrentContent());
};

export const filterColorDOMElements = (type, condition) => {
  const elements = [...document.querySelector("[data-contents]").querySelectorAll("*")];

  const results = [];

  elements.forEach((element) => {
    let meetsCondition = false;

    if (type === "color" && element.style.backgroundColor === condition && element.textContent.length > 3) {
      meetsCondition = true;
    }

    if (meetsCondition) {
      const siblings = [...element.parentElement.children];
      const hasSiblingWithCondition = siblings.filter(
        (sibling) => type === "color" && sibling.style.backgroundColor === condition && element.textContent.length > 3
      );
      if (hasSiblingWithCondition.length > 1) {
        if (!results.includes(element.parentElement)) {
          results.push(element.parentElement);
        }
      }
      if (hasSiblingWithCondition.length <= 1) {
        results.push(element);
      }
    }
  });
  return results;
};
export const filterTextDOMElements = (type, condition) => {
  const elements = [...document.querySelector("[data-contents]").querySelectorAll("[data-text='true']")];

  const foundElements = [];
  const existingText = [];

  Array.from(elements).forEach((element) => {
    let isElementAdded = false;

    if (type === "text" && element.innerText.includes(condition)) {
      isElementAdded = true;
    }

    if (isElementAdded && !foundElements.includes(element)) {
      existingText.push(element.textContent);

      foundElements.push(element);
    }
  });

  return foundElements;
};

export const getElementsRelated = (functionsList, readyColorFunctions, readyTextFunctions) => {
  const result = functionsList.map((el) => {
    if (el.triggerType === "color") {
      return {
        ...el,
        elems: filterColorDOMElements(el.triggerType, el.trigger),
        inFunctionDone: false,
        outFunctionDone: false,
      };
    }

    if (el.triggerType === "text") {
      return {
        ...el,
        elems: filterTextDOMElements(el.triggerType, el.trigger),
        inFunctionDone: false,
        outFunctionDone: false,
      };
    }
  });

  const colors = result.filter((el) => el.triggerType === "color");
  const texts = result.filter((el) => el.triggerType === "text");

  readyColorFunctions.current = colors;
  readyTextFunctions.current = texts;
};

export const serverUrl = "https://promptercopy-fac0b625e342.herokuapp.com";
