import * as utils from "./Slice";
export const setTextColorHex = (dispatch, value) => {
  dispatch(utils.setTextColorHex(value));
  return;
};

export const setCurrentDocID = (dispatch, value) => {
  dispatch(utils.setCurrentDocID(value));
  return;
};
export const setIsEditing = (dispatch, value) => {
  dispatch(utils.setIsEditing(value));
  return;
};
export const setRefreshData = (dispatch, value) => {
  dispatch(utils.setRefreshData(value));
  return;
};
export const setDocData = (dispatch, value) => {
  dispatch(utils.setDocData(value));
  return;
};
export const setReadonly = (dispatch, value) => {
  dispatch(utils.setReadonly(value));
  return;
};
export const setDeletDocID = (dispatch, value) => {
  dispatch(utils.setDeletDocID(value));
  return;
};

export const setPlaySpeed = (dispatch, value) => {
  dispatch(utils.setPlaySpeed(value));
  return;
};
export const setPlay = (dispatch, value) => {
  dispatch(utils.setPlay(value));
  return;
};
export const setAllCaps = (dispatch, value) => {
  dispatch(utils.setAllCaps(value));
  return;
};

export const setShowTextLine = (dispatch, value) => {
  dispatch(utils.setShowTextLine(value));
  return;
};
export const setShowTimer = (dispatch, value) => {
  dispatch(utils.setShowTimer(value));
  return;
};
export const setBreakOnSentenceEnd = (dispatch, value) => {
  dispatch(utils.setBreakOnSentenceEnd(value));
  return;
};
export const setBreakOnTriggerColor = (dispatch, value) => {
  dispatch(utils.setBreakOnTriggerColor(value));
  return;
};
export const setDualMonitor1 = (dispatch, value) => {
  dispatch(utils.setDualMonitor1(value));
  return;
};
export const setDualMonitor2 = (dispatch, value) => {
  dispatch(utils.setDualMonitor2(value));
  return;
};
export const setShowPanel = (dispatch, value) => {
  dispatch(utils.setShowPanel(value));
  return;
};
export const setFlipHorizontal1 = (dispatch, value) => {
  dispatch(utils.setFlipHorizontal1(value));
  return;
};
export const setRemoveListener = (dispatch, value) => {
  dispatch(utils.setRemoveListener(value));
  return;
};
export const setFlipHorizontal2 = (dispatch, value) => {
  dispatch(utils.setFlipHorizontal2(value));
  return;
};
export const setFlipVertical1 = (dispatch, value) => {
  dispatch(utils.setFlipVertical1(value));
  return;
};
export const setFlipVertical2 = (dispatch, value) => {
  dispatch(utils.setFlipVertical2(value));
  return;
};

export const setLineSpacing = (dispatch, value) => {
  console.log("value",value)
  dispatch(utils.setLineSpacing(value));
  return;
};
export const setScreenMargin = (dispatch, value) => {
  dispatch(utils.setScreenMargin(value));
  return;
};

export const setBackword = (dispatch, value) => {
  dispatch(utils.setBackword(value));
  return;
};

export const setForward = (dispatch, value) => {
  dispatch(utils.setForward(value));
  return;
};
export const setTextSize = (dispatch, value) => {
  dispatch(utils.setTextSize(value));
  return;
};
export const setStartover = (dispatch, value) => {
  dispatch(utils.setStartover(value));
  return;
};
export const setMonitor1 = (dispatch, value) => {
  dispatch(utils.setMonitor1(value));
  return;
};
export const setMonitor2 = (dispatch, value) => {
  dispatch(utils.setMonitor2(value));
  return;
};

export const setTextDirection = (dispatch, value) => {
  dispatch(utils.setTextDirection(value));
  return;
};

export const setTextRTL = (dispatch, value) => {
  dispatch(utils.setTextRTL(value));
  return;
};
export const setView = (dispatch, value) => {
  dispatch(utils.setView(value));
  return;
};
export const setDocType = (dispatch, value) => {
  dispatch(utils.setDocType(value));
  return;
};

export const setMasterScreen1 = (dispatch, value) => {
  dispatch(utils.setMasterScreen1(value));
  return;
};

export const setMasterScreen2 = (dispatch, value) => {
  dispatch(utils.setMasterScreen2(value));
  return;
};

export const setMonitors = (dispatch, value) => {
  dispatch(utils.setMonitors(value));
  return;
};

export const setSaveMode = (dispatch, value) => {
  dispatch(utils.setSaveMode(value));
  return;
};
export const setFunctionsList = (dispatch, value) => {
  dispatch(utils.setFunctionsList(value));
  return;
};
export const setLoggedToVmix = (dispatch, value) => {
  dispatch(utils.setLoggedToVmix(value));
  return;
};
export const setSelectedFunction = (dispatch, value) => {
  dispatch(utils.setSelectedFunction(value));
  return;
};
export const setEditFunction = (dispatch, value) => {
  dispatch(utils.setEditFunction(value));
  return;
};
export const setOuterHeight = (dispatch, value) => {
  dispatch(utils.setOuterHeight(value));
  return;
};
export const setOuterWidth = (dispatch, value) => {
  dispatch(utils.setOuterWidth(value));
  return;
};
export const setVmixObject = (dispatch, value) => {
  dispatch(utils.setVmixObject(value));
  return;
};
export const setSocket = (dispatch, value) => {
  dispatch(utils.setSocket(value));
  return;
};
export const setLoginToVmix = (dispatch, value) => {
  dispatch(utils.setLoginToVmix(value));
  return;
};
export const setInputs = (dispatch, value) => {
  dispatch(utils.setInputs(value));
  return;
};
export const setRunAgain = (dispatch, value) => {
  dispatch(utils.setRunAgain(value));
  return;
};
export const setDriveLink = (dispatch, value) => {
  dispatch(utils.setDriveLink(value));
  return;
};
