import { useEffect, useState, Suspense, lazy } from "react";

const Prompter = lazy(async () => import("../Components/Prompter"));
const Editor = lazy(async() => import("../Components/Editor"));

const View = ({setScrollPosition}) => {
  const [view, setView] = useState(1);
  useEffect(() => {
    document.addEventListener("changeView", (e) => {
      console.log(e.detail);
      setView(e.detail);
    });

    return () => {
      document.removeEventListener("changeView", (e) => {
        console.log(e.detail);
      });
    };
  }, []);
  if (view === 1) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Editor />
      </Suspense>
    );
  }
  if(view === 0){
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Prompter setScrollPosition={setScrollPosition} />
      </Suspense>
    );
  }
  
};
export default View;
