export const colors = [
  {name:"Red",color:"FE0002"},
  {name:"Yellow",color:"FFFF00"},
  {name:"Green",color:"00FF00"},
  {name:"Blue",color:"018FFD"},
  {name:"Tourqua",color:"00FFFF"},
  {name:"Pink",color:"FC02FA"},
  {name:"Orage",color:"F36523"},
  {name:"White",color:"FFFFFF"},
  {name:"Grey",color:"959595"},
  {name:"Black",color:"005223"},
  {name:"Salmon",color:"FEA0A0"},
  {name:"LightYellow",color:"FFFFBF"},
  {name:"LightGreen",color:"BFFFBE"},
  {name:"Lightblue",color:"7FC8FF"},
  {name:"textLightGreen",color:"BFFFFF"},
  {name:"Lightpink",color:"FF7FFE"},
  {name:"LightOrange",color:"F5A173"},
]

