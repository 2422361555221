import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SettingRow.css";
import { setFunctionsList } from "../../Redux/Utils";
const ColorItem = ({ color }) => {
  return <div style={{ height: "20px", width: "20px", backgroundColor: color }} className="m-auto" />;
};

const SplitTable = ({ condition, first, second, sliceAmount }) => {
  return (
    <table class=" w-100 border-0">
      <tr data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={first || "-"} className=" border-0">
        <td>{first.slice(0, sliceAmount)}</td>
      </tr>
      {condition && (
        <tr data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={second || "-"} className="border-top border-dark">
          <td>{second.slice(0, sliceAmount) || "-"}</td>
        </tr>
      )}
    </table>
  );
};

const CheckFunction = ({ active, setActive, id }) => {
  const [check, setCheck] = useState(true);
  const dispatch = useDispatch();

  return active ? (
    <img
      style={{ filter: "invert(100%)", verticalAlign: "middle" }}
      onClick={() => {
        setActive(id,dispatch);
      }}
      src="/assets/icons/check.svg"
      height={"18"}
    />
  ) : (
    <img
      style={{ filter: "invert(100%)", verticalAlign: "middle" }}
      onClick={() => {
        setActive(id,dispatch);
      }}
      src="/assets/icons/close.svg"
      height={"15"}
    />
  );
};
const SettingRow = ({ setIsEditing, onclick, setAllFunctions, allFunctions }) => {
  const functionsList = useSelector((state) => state.user.functionsList);

  const toggleRowStyle = (e) => {
    const tableRow = document.querySelectorAll(".tableRow");
    setIsEditing(false);
    tableRow.forEach((el) => {
      if (el != e.currentTarget) {
        el.classList.remove("selectedLine");
      } else {
        el.classList.toggle("selectedLine");
      }
    });
  };
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));
  }, [functionsList]);
  const setActive = (id,dispatch) => {

    const items = functionsList
      .map((el) => {
        if (el.id == id) {
          const current = el.active;
          return {
            ...el,
            active: !current,
          };
        }
        return el
      })
    setFunctionsList(dispatch, items);
    console.log("functionsList", functionsList);
  };
  return (
    <div className="col-12  text-white text-center mt-3 mb-3">
      <table class="table text-white border border-1 border-dark rounded">
        <thead>
          <tr>
            <th scope="col ">#</th>
            <th scope="col ">#</th>
            <th scope="col">Trigger</th>
            <th scope="col">Function</th>
            <th scope="col">Input</th>
            <th scope="col">Duration</th>
            <th scope="col">Delay</th>
            <th scope="col">Value</th>
          </tr>
        </thead>
        <tbody>
          {functionsList != false
            ? functionsList.map((el, idx) => {
                if (el.hasOwnProperty("functions")) {
                  const fIn = el.functions.filter((el) => el.type === "in");
                  const fOut = el.functions.filter((el) => el.type === "out");
                  const rows = el.triggerType === "text" ? false : true;
                  const functionsNames = fIn
                    .map((el) => el.Function)
                    .filter((el) => el)
                    .toString()
                    .replace(",", "/");
                  const outFunctionsNames = fOut
                    .map((el) => el.Function)
                    .filter((el) => el)
                    .toString()
                    .replace(",", "/");
                  const inputNames = fIn
                    .map((el) => el.Input)
                    .filter((el) => el)
                    .toString()
                    .replace(",", "/");
                  const outInputNames = fOut
                    .map((el) => el.Input)
                    .filter((el) => el)
                    .toString()
                    .replace(",", "/");
                  const Durations = fIn
                    .map((el) => el.Duration)
                    .filter((el) => el)
                    .toString()
                    .replace(",", "/");
                  const Delays = fIn
                    .map((el) => el.Delay)
                    .filter((el) => el)
                    .toString()
                    .replace(",", "/");
                  const ValuesIn = fIn
                    .map((el) => el.Value)
                    .filter((el) => el)
                    .toString()
                    .replace(",", "/");
                  const ValuesOut = fOut
                    .map((el) => el.Value)
                    .filter((el) => el)
                    .toString()
                    .replace(",", "/");
                  return (
                    <tr className="tableRow mdFont" data-rowid={el.id} onClick={onclick}>
                      <td style={{ verticalAlign: "middle" }}>1</td>
                      <td style={{ width: "30px" }} className={`border-dark border-start position-relative`}>
                        <table class="inner-table w-100">
                          <tr>
                            <td>{el.triggerType === "color" && <ColorItem color={el.trigger} />}</td>
                          </tr>
                          {rows && (
                            <tr className="border-top border-dark">
                              <td>
                                <CheckFunction id={el.id} active={el.active} setActive={setActive} />
                              </td>
                            </tr>
                          )}
                        </table>
                      </td>
                      {/* trigger */}
                      <td className={`border-start border-dark`} style={{ width: "130px" }}>
                        {el.triggerType === "text" ? (
                          el.trigger
                        ) : (
                          <SplitTable sliceAmount={20} condition={rows} first={"Function In"} second={"Function Out"} />
                        )}
                      </td>
                      <td className={`border-dark border-start col-3  ps-0 pe-0`}>
                        <SplitTable sliceAmount={100} condition={rows} first={functionsNames} second={outFunctionsNames} />
                      </td>
                      <td className={`border-dark border-start col-3`}>
                        <SplitTable sliceAmount={35} condition={rows} first={inputNames || "-"} second={outInputNames || "-"} />
                      </td>
                      <td className={`border-dark border-start col-1`}>
                        <SplitTable sliceAmount={50} condition={rows} first={Durations} second={"-"} />
                      </td>
                      <td className={`border-dark border-start`}>
                        <SplitTable sliceAmount={50} condition={rows} first={Delays} second={"-"} />
                      </td>
                      <td className={`border-dark border-start`}>
                        <SplitTable sliceAmount={50} condition={rows} first={ValuesIn || "-"} second={ValuesOut} />
                      </td>
                    </tr>
                  );
                  {
                    /* }); */
                  }
                }
              })
            : [1, 2, 3, 4, 5, 6, 7].map((items, index) => {
                return (
                  <tr className="tableRow mdFont" onClick={toggleRowStyle}>
                    <td>{items}</td>
                    {/* checkbox */}
                    <td style={{ width: "30px" }} className={`border-dark border-start position-relative`}>
                      <table class="inner-table w-100">
                        <tr>
                          <td>-</td>
                        </tr>
                        {false && (
                          <tr className="border-top border-dark">
                            <td>-</td>
                          </tr>
                        )}
                      </table>
                    </td>
                    {/* trigger */}
                    <td className={`border-start border-dark`} style={{ width: "130px" }}>
                      <SplitTable sliceAmount={20} condition={false} first={"-"} second={"-"} />
                    </td>

                    <td className={`border-dark border-start col-3  ps-0 pe-0`}>
                      <SplitTable sliceAmount={100} condition={false} first={"-"} second={"-"} />
                    </td>
                    <td className={`border-dark border-start col-3`}>
                      {" "}
                      <SplitTable sliceAmount={50} condition={false} first={"-"} second={"-"} />
                    </td>
                    <td className={`border-dark border-start col-1`}>
                      <SplitTable sliceAmount={50} condition={false} first={"-"} second={"-"} />
                    </td>
                    <td className={`border-dark border-start col-1`}>
                      <SplitTable sliceAmount={50} condition={false} first={"-"} second={"-"} />
                    </td>
                    <td className={`border-dark border-start col`}>
                      <SplitTable sliceAmount={50} condition={false} first={"-"} second={"-"} />
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default SettingRow;
