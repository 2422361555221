import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedToVmix, setLoginToVmix, setSocket, setVmixObject } from "../Redux/Utils";
import { disptachToVmix, sendMonitorCommand, serverUrl, setToast } from "../Utils";
import { useState } from "react";
import { v4 } from "uuid";
import io from "socket.io-client";
const VmixLogin = () => {
  const vmixObject = useSelector((state) => state.user.vmixObject);
  const loggedToVmix = useSelector((state) => state.user.loggedToVmix);
  const srSocket = useSelector((state) => state.user.socket);
  const saved = window.localStorage.getItem("vmixObject");

  const [submit, setSubmit] = useState(false);
  const [newwin, setnewwin] = useState(false);
  const [sok, setsok] = useState(false);
  const [adress, setadress] = useState("");
  const [port, setport] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const item = window.localStorage.getItem("loggedToVmix");
    if (item) {
      const data = window.localStorage.getItem("vmixObject");
      const dataparsed = JSON.parse(data);
      const parsed = JSON.parse(item);
      console.log("dataparsed",dataparsed);
      if (dataparsed) {
        setVmixObject(dispatch, dataparsed);
      }
      setLoggedToVmix(dispatch, parsed);
    }
  }, []);

  const tryToLogin = (payload) => {
    const socket = io(serverUrl);

    socket.once("dataTransferFrom", (e) => {
      console.log(e);
      if (e.type == "login") {
        if (e.value) {
          setVmixObject(dispatch, payload);
          window.localStorage.setItem("vmixObject", JSON.stringify(payload));
          window.localStorage.setItem("loggedToVmix", true);

          setLoggedToVmix(dispatch, true);
          socket.disconnect();
          return;
        }
      }
    });
    socket.emit("dataTransfer", { type: "login", value: payload });
  };
  useEffect(() => {
    if (submit) {
      if (!adress && !port & !vmixObject.address && !vmixObject.port) {
        setToast({ state: "warning", text: "Please verify your vmix details." });
        setLoginToVmix(dispatch, false);
        setSubmit(false);
        return;
      }
      const payload = { address: adress, port: port, status: "" };
      tryToLogin(payload);

      setSubmit(false);
    }
  }, [submit]);
  // (e) => setVmixObject(dispatch, { ...vmixObject, address: e.target.value })
  // (e) => setVmixObject(dispatch, { ...vmixObject, port: e.target.value })
  const setAdressss = (e) => {
    setadress(e.target.value);
  };
  const setporttt = (e) => {
    setport(e.target.value);
  };
  const setsubmit = (e) => {
    setSubmit(true);
  };
  return (
    <div className="col-12 d-flex flex-row justify-content-start align-items-center text-white mt-3 mb-3">
      <div className="col-auto d-flex flex-row justify-content-center align-items-center">
        <img src="/assets/icons/vmix.png" height="30" className="me-2" />
        <span className="text-white fs-5 w-5 me-2">Trigger Vmix</span>
      </div>
      <div className="col-6 d-flex flex-row justify-content-center align-items-center">
        <div className="col me-3 d-flex flex-row justify-content-center align-items-center">
          <select style={{ padding: "2px" }} className="form-select rounded-1  mdFont darkbg text-white">
            {["Vmix"].map((el) => {
              return (
                <option className="w-5 text-white" value={el}>
                  {el}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col d-flex flex-row justify-content-center align-items-center ">
          <input
            type="text"
            style={{ padding: "2px" }}
            className="form-control bg-black rounded-1  mdFont darkbg text-white"
            placeholder="Vmix Localhost"
            value={adress ? adress : vmixObject.address}
            onChange={setAdressss}
          />
        </div>
        <div className="col-3 ms-2 d-flex flex-row justify-content-center align-items-center">
          <input
            type="text"
            style={{ padding: "2px" }}
            value={port ? port : vmixObject.port}
            className="form-control bg-black rounded-1  mdFont darkbg text-white"
            placeholder="Vmix Port"
            onChange={setporttt}
          />
        </div>
        <div className={`bg-${loggedToVmix ? "success" : "danger"} m-1 rounded pointer col-auto btn p-0 mdFont p-1 text-white`} onClick={setsubmit}>
          Connect
        </div>
      </div>
    </div>
  );
};

export default VmixLogin;
