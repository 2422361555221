import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../Input";
import * as utils from "../../Redux/Utils";
import {
  addBlockWithHeightToEditor,
  calculateRemainingScrollPercentage,
  calculateScrollTime,
  callVmix,
  centerBlockByBackgroundColor,
  centerBlockWithBackgroundColor,
  changeView,
  filterColorDOMElements,
  filterTextDOMElements,
  getCoordinatesInParent,
  getElementsRelated,
  handleBackToEditor,
  handleKeysListener,
  handleUpKeys,
  logMouseWheelScroll,
  logMouseWheelScrollEvent,
  sendMonitorCommand,
  startOver,
  startScrolling,
  startScrollingTop,
  stopScrolling,
  toggleFullscreen,
  toggleScrollbar,
} from "../../Utils";
import { useScrollHook } from "../../hooks/scrollHook";
import { useFunctionsHook } from "../../hooks/functionsHook";

import { getKeys, removeDrafts, updateCommand } from "../../firebase";
import { useCallback } from "react";
import { useRef } from "react";
const PrompterController = ({ setShowSettings, editorReady, monitor1, monitor2 }) => {
  const [showTextInput, setShowTextInput] = useState(false);
  const textSize = useSelector((state) => state.user.textSize);
  const [showSpeedInput, setShowSpeedInput] = useState(false);
  const [scrollSpeed, setScrollSpeed] = useState(300);
  const { setForward, setBackword, setPlay, play } = useScrollHook(monitor1, scrollSpeed);
  const [elementsVisited, setelementsVisited] = useState([]);
  const [currentMarker, setCurrentMarker] = useState(0);
  const [previewsMarker, setPreviewsMarker] = useState(0);
  const [lastPosition, setlastPosition] = useState(0);
  const [startover, setstartover] = useState(false);
  const [timer, settimer] = useState(0);
  const [miniMenu, setminiMenu] = useState(false);
  const scrollRef = useRef(null);
  const scrollSpeedRef = useRef(null);
  const [keywords, setkeywords] = useState(new Set());
  const functionsList = useSelector((state) => state.user.functionsList);
  const currentDocID = useSelector((state) => state.user.currentDocID);

  const dispatch = useDispatch();
  const dataRef = useRef(null);



  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));
  }, []);
  const functionsHook = useFunctionsHook(editorReady, play, textSize);

  const wheelFunc = useCallback(
    function (e) {
      const progressBar = document.getElementById("progress-bar");

      const num = Math.round(e.target.scrollTop);
      const remainingScrollPercentage = calculateRemainingScrollPercentage(e.target);
      progressBar.style.width = remainingScrollPercentage + "%";
      if (!play) {
        sendMonitorCommand(monitor1, "customScroll", num);
      }
    },
    [monitor1]
  );

  useEffect(() => {
    const elem = document.querySelector(".DraftEditor-root");
    if (editorReady) {
      elem.addEventListener("scroll", wheelFunc);
    }
    return () => {
      elem.removeEventListener("scroll", wheelFunc);
    };
  }, [editorReady, monitor1, play]);



  // TIMER
  useEffect(() => {
    let intreval;
    if (editorReady) {
      intreval = setInterval(() => {
        const elem = document.querySelector(".DraftEditor-root");
        const promptBlock = document.querySelector(".promptBlock");
        let takeOff = getComputedStyle(promptBlock).height;
        takeOff = Math.round(takeOff.split("px")[0]) * 2 + 30;
        const num = elem.scrollHeight - elem.scrollTop;
        const scrollTime = calculateScrollTime(num, scrollSpeed, takeOff, elem.scrollHeight);
        settimer(scrollTime);
      }, 500);
    }

    return () => {
      if (intreval) {
        clearInterval(intreval);
      }
    };
  }, [editorReady]);

  
  const setSize = async (value) => {
    utils.setTextSize(dispatch, value);
  };

  useEffect( () => {
    let timeout; 
    if (editorReady) {
      const elemRoot = document.querySelector("[data-contents]");
      elemRoot.style.zoom = textSize;
      timeout = setTimeout(async () => {
        await updateCommand(currentDocID,'textSize', textSize)
      }, 500);
    }
    return () => {
     if(timeout){
      clearTimeout(timeout)
     }
    };
  }, [textSize, editorReady]);


  // NEXT MARKER
  useEffect(() => {
    const elem = document.querySelector(".DraftEditor-root");
    const markers2 = [...document.querySelectorAll("[data-text]")].filter((a) => {
      return a.textContent && a.textContent.trim().includes("---------------------------------");
    });

    const elements = markers2;

    // get the target element
    const target = document.querySelector("#here");

    // get the target element's center position
    const targetCenterY = target.getBoundingClientRect().top + target.offsetHeight / 2;
    if (currentMarker >= markers2.length || currentMarker < 0) {
      setCurrentMarker(0);
      return;
    }
    const current = elements[currentMarker];
    // scroll element to the top of the viewport
    current.scrollIntoView(true);

    // get the amount of pixels the element needs to be scrolled to be centered in target
    const elementCenterY = current.getBoundingClientRect().top + current.offsetHeight / 2;
    const offsetY = targetCenterY - elementCenterY;

    // scroll the window the needed amount of pixels for centering
    elem.scrollBy({ top: -offsetY, behavior: "smooth" });
  }, [currentMarker]);

  const localCb = (data) => {
    dataRef.current = [...data.keys];
  };

  // KEY FUNCTIONS
  useEffect(() => {
    let handler;
    const fireAsync = async () => {
      handler = await getKeys(localCb);
    };
    fireAsync();
    if (handler) {
      handler();
    }
  }, []);

  useEffect(() => {});
  const cb = useCallback(
    (e) =>
      handleKeysListener({
        dispatch,
        target: e.code,
        handleBackToEditor,
        keysList: dataRef.current,
        monitor1,
        monitor2,
        sendMonitorCommand,
        setBackword,
        setCurrentMarker,
        setForward,
        setPlay: setPlay,
        setPreviewsMarker,
        setScrollSpeed,
        play,
        e,
      }),
    [dataRef]
  );

  const cbOut = useCallback(
    (e) =>
      handleUpKeys({
        e,
        keysList: dataRef.current,
        sendMonitorCommand,
        setBackword,
        setForward,
        monitor1,
        monitor2,
      }),
    [dataRef]
  );

  useEffect(() => {
    document.addEventListener("keydown", cb);
    document.addEventListener("keyup", cbOut);
    return () => {
      document.removeEventListener("keydown", cb);
      document.removeEventListener("keyup", cbOut);
    };
  }, []);

  useEffect(() => {
    const sorted = elementsVisited.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return 1;
      }
      if (a.createdAt < b.createdAt) {
        return -1;
      }
      return 0;
    });
    setelementsVisited(sorted);
  }, [elementsVisited]);

  useEffect(() => {
    const markers = [...document.querySelectorAll("[data-text]")].filter((a) => {
      return a.textContent && a.textContent.trim().includes("---------------------------------");
    });
    console.log(markers);
    markers.forEach((el, idx) => {
      const pointer = document.querySelector("#here").getBoundingClientRect();
      const markerDistanceFromTop = el.getBoundingClientRect().top;
      const pointerDistanceFromTop = pointer.top;
      if (markerDistanceFromTop < pointerDistanceFromTop) {
        const parent = el.parentElement;
        const parentKey = parent.dataset.offsetKey;
        const item = elementsVisited.filter((el) => el.parentKey === parentKey);
        if (item == false) {
          const now = Math.floor(Date.now() / 1000);
          setelementsVisited((prev) => [...prev, { createdAt: now, parentKey }]);
        }
      }
      if (markerDistanceFromTop > pointerDistanceFromTop) {
        const parent = el.parentElement;
        const parentKey = parent.dataset.offsetKey;
        const item = elementsVisited.filter((el) => el.parentKey === parentKey);
        if (item != false) {
          return;
        }
      }
    });
  }, [lastPosition]);

  useEffect(() => {
    const menuBtn = document.querySelectorAll(".menuBtn");
    if (miniMenu) {
      menuBtn.forEach((el) => {
        el.classList.add("d-none");
      });
      setShowSettings(false);

      return;
    }
    if (!miniMenu) {
      menuBtn.forEach((el) => {
        el.classList.remove("d-none");
      });
      return;
    }
  }, [miniMenu]);

  return (
    <div
      className="col-12 d-flex flex-row justify-content-center align-items-center  text-white localJer position-absolute bottom-0"
      style={{ height: miniMenu ? "10px" : "75px", backgroundColor: "rgb(17, 17, 17)", zIndex: "999" }}
    >
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
        <div className={`col-11 d-flex flex-row justify-content-between align-items-center prompterController position-relative`}>
          <div
            onClick={() => {
              setminiMenu((prev) => !prev);
            }}
            className="col-12 d-flex justify-content-center pointer position-absolute end-0 start-0"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title={miniMenu ? "Open Menu" : "Minimize Menu"}
            style={{ top: "10px" }}
          >
            <img className="selectDisable" src="/assets/icons/next.svg" height="15" width="15" style={{ transform: `rotate(${miniMenu ? "270" : "90"}deg)` }} />
          </div>
          <div
            className="col-auto d-flex flex-row justify-content-center align-items-center pointer menuBtn"
            onClick={() => {
              // window.location.href = "/editor";
              changeView(1);
            }}
          >
            <img src="/assets/icons/arrowBlue.svg" height="20" className="mt-1 selectDisable" />
            <span className="fs-5 m-2" style={{ color: "#C6C3C3" }}>
              Edit Script
            </span>
          </div>
          <div className="col-7 d-flex flex-row justify-content-center align-items-center menuBtn">
            <div style={{ width: "75px" }} className="border rounded me-3">
              {timer}
            </div>
            <div className="col-auto d-flex flex-row justify-content-evenly align-items-center">
              {/* <div className="recordBtnOff recordBtnLg pointer menuBtn hoverSoft " /> */}
              <div
                onMouseDown={(e) => {
                  setBackword(true);
                }}
                onMouseUp={(e) => {
                  setBackword(false);
                }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Backward"
                className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft  position-relative"
              >
                {" "}
                <img src="/assets/icons/speed.svg" className="selectDisable" height="30" width="30" style={{ transform: "rotate(180deg)" }} />
              </div>
              <div
                onClick={() => {
                  setPlay((prev) => !prev);
                }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title={play ? "Pause" : "Play"}
                className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft ms-2 "
              >
                <img src={play ? "/assets/icons/pauseGrey.svg" : "/assets/icons/playGrey.svg"} className="selectDisable" height="30" width="30" />
              </div>
              <div
                onMouseDown={(e) => {
                  setForward(true);
                }}
                onMouseUp={(e) => {
                  setForward(false);
                }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Forward"
                className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft m-1"
              >
                <img src="/assets/icons/speed.svg" height="30" width="30" className="selectDisable" />
              </div>
            </div>
            <div className="col-5 d-flex flex-row justify-content-evenly align-items-center">
              <div
                onClick={() => setShowTextInput((prev) => !prev)}
                className="col-auto d-flex justify-content-center pointer menuBtn position-relative selectDisable"
              >
                <Input
                  dirType="ltr"
                  maxType="6"
                  minType="1"
                  stepType={"0.1"}
                  show={showTextInput}
                  text="Text Size"
                  key="aksdnanasdasddainndsio"
                  handleChange={setSize}
                  valueProp={textSize}
                  setShow={setShowTextInput}
                  classItem="sizeInput selectDisable"
                />

                <img
                  src="/assets/icons/t.svg"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Text size"
                  className="selectDisable nonPointer"
                  height="50"
                  width="50"
                />
              </div>
              <div onClick={() => setShowSpeedInput((prev) => !prev)} className="col-auto d-flex justify-content-center pointer menuBtn position-relative">
                <Input
                  minType="25"
                  maxType="500"
                  stepType="0.1"
                  show={showSpeedInput}
                  text="Speed"
                  key="aksdnanasdassdfdfsdfsdfddainndsio"
                  handleChange={setScrollSpeed}
                  valueProp={scrollSpeed}
                  dirType="ltr"
                  id="scrollSpeed"
                  setShow={setShowSpeedInput}
                  classItem="speedInput"
                />

                <img
                  src="/assets/icons/clock.svg"
                  className="selectDisable"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Prompt Speed"
                  height="30"
                  width="30"
                />
              </div>
              <div className="col-auto d-flex flex-row justify-content-center align-items-center">
                <div
                  onClick={() => {
                    setCurrentMarker((prev) => prev - 1);
                  }}
                  className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Pre Marker"
                >
                  <img src="/assets/icons/next.svg" className="selectDisable" height="38" width="38" style={{ transform: "rotate(180deg)" }} />
                </div>
                <div className="col-auto d-flex justify-content-center menuBtn ">
                  <img src="/assets/icons/stopGrey.svg" className="selectDisable" height="30" width="30" />
                </div>
                <div
                  onClick={() => {
                    setCurrentMarker((prev) => prev + 1);
                  }}
                  className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Next Marker"
                >
                  <img src="/assets/icons/next.svg" className="selectDisable" height="38" width="38" />
                </div>
              </div>
              <div
                id="startOver"
                onClick={() => startOver(monitor1, setstartover, startover, monitor2, setlastPosition)}
                className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Start Over"
              >
                <img src="/assets/icons/replay.svg" className="selectDisable" height="35" width="35" />
              </div>
              <div
                onClick={toggleFullscreen}
                className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Full Screen"
                id="mainFs"
              >
                <img src="/assets/icons/fullscreen.svg" className="selectDisable" height="30" width="30" />
              </div>
            </div>
          </div>

          <div className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft ms-5" onClick={() => setShowSettings((prev) => !prev)}>
            <img src="/assets/icons/menu.svg" className="selectDisable" height="30" width="30" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrompterController;
