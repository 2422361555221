import "./OutputPrompt.css";
import * as utils from "../../Redux/Utils";

import { Editor, EditorState, RichUtils, DefaultDraftBlockRenderMap, convertFromRaw } from "draft-js";
import styleMap from "../../Utils/styleMap";
import { createRef, useEffect, useState } from "react";
import { getPromptRef, getSpeicificDoc, getStylesRef } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import useEditorReadyHook from "../../hooks/readyHook";

import {
  addBlockWithHeightToEditor,
  addLineBreakAfterBackground,
  calculateRemainingScrollPercentage,
  calculateScrollTimer,
  convertToUpperCase,
  flipPageHorizontal,
  flipPageVerical,
  handleDotSpace,
  handlePointerToggle,
  setPageMargin,
  setPageSpaceLine,
  startScrolling,
  startScrollingTop,
  stopScrolling,
  toggleFullscreen,
  toggleScrollbar,
} from "../../Utils";
import { useCallback } from "react";
import Pointer from "../Pointer";
import ColorLayer from "../../ColorLayer";
import { useRef } from "react";
import { useScrollHook } from "../../hooks/scrollHook";
import Line from "../Line";
import ButtonLoader from "../ButtonLoader";

const OutputPrompt = ({ scrollPosition }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [textSize, setTextSize] = useState(3);
  const [playSpeed, setPlaySpeed] = useState(300);
  const [currentDocID, setcurrentDocID] = useState("");

  const [allCaps, setAllCaps] = useState(false);
  const [spaceAfterDot, setspaceAfterDot] = useState(false);
  const [monitorNum, setMonitorNum] = useState(false);
  const [styles, setstyles] = useState({});
  const [masterScreen1, setmasterScreen1] = useState(true);
  const [masterScreen2, setmasterScreen2] = useState(false);
  const [dragPosition, setDragPosition] = useState(false);
  const [isClicked, setisClicked] = useState(false);
  const lineSpacing = useSelector((state) => state.user.lineSpacing);
  const [newPosition, setnewPosition] = useState({ x: 0, y: 0 });
  const [showTextLine, setshowTextLine] = useState(true);
  const { setPlay } = useScrollHook(null);
  const [docData, setdocData] = useState({});
  const runAgain = useSelector((state) => state.user.runAgain);
  const dispatch = useDispatch();
  const { ready } = useEditorReadyHook();

  const ref = createRef();
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");

  useEffect(() => {
    // const id = window.sessionStorage.getItem("currentDocID");
    const screenNum = params.get("screenNum");
    setMonitorNum(screenNum);
    getSpeicificDoc(id).then((res) => {
      const doc = res;
      setdocData(doc)
      const parsed = JSON.parse(doc.content);
      const content = convertFromRaw(parsed);
      setEditorState(EditorState.createWithContent(content));
      setcurrentDocID(id);
    });
  }, [id]);

  useEffect(() => {
    if (runAgain) {
      console.log({runAgain})
      const parsed = JSON.parse(docData.content);
      const content = convertFromRaw(parsed);
      setEditorState(EditorState.createWithContent(content));
    }
  }, [runAgain]);

  useEffect(() => {
    // const id = window.sessionStorage.getItem("currentDocID");
    const screenNum = params.get("screenNum");
    setMonitorNum(screenNum);
    getSpeicificDoc(id).then((res) => {
      const doc = res;
      setdocData(doc)
      const parsed = JSON.parse(doc.content);
      const content = convertFromRaw(parsed);
      setEditorState(EditorState.createWithContent(content));
      setcurrentDocID(id);
    });
  }, [id]);

  useEffect(() => {
    const func = function (e) {
      console.log("change", e.storageArea);
      const data = e.storageArea;

      if (data.hasOwnProperty("progressbar")) {
        console.log(data["progressbar"]);
      }
    };
    window.addEventListener("storage", func);

    return () => {
      window.removeEventListener("storage", func);
    };
  }, []);

  useEffect(() => {
    handlePointerToggle(showTextLine);
  }, [showTextLine]);

  useEffect(() => {
    if(!currentDocID || !ready){
      return
    }
    let ref;
    const fireAsync = async () => {
      ref = await getPromptRef(currentDocID, (doc) => {
        console.log(doc)
        // setstyles(doc);
        if (doc.hasOwnProperty("lineSpacing")) {
          const value = parseFloat(doc.lineSpacing);
          const elem = document.querySelector("[data-contents]");
          elem.style.lineHeight = `${value}px`;
        }
        if (doc.hasOwnProperty("margin")) {
          setPageMargin(parseInt(doc.margin));
        }
        if (doc.hasOwnProperty("textSize")) {
          console.log("textSize",doc.textSize)
          setTextSize(parseFloat(doc.textSize));
        }
        if (doc.hasOwnProperty("breakColor")) {
          addLineBreakAfterBackground(editorState, setEditorState, doc.breakColor);
        }
        if (doc.hasOwnProperty("breakSentence")) {
          setspaceAfterDot(doc.breakSentence);
        }
        if (doc.hasOwnProperty("allCaps")) {
          setAllCaps(doc.allCaps);
        }
        if (doc.hasOwnProperty("flipHorizontal")) {
          flipPageHorizontal(doc.flipHorizontal)
        }
        if (doc.hasOwnProperty("flipVertical")) {
          flipPageVerical(doc.flipVertical);
        }
        if (doc.hasOwnProperty("showTextLine")) {
          setshowTextLine(doc.showTextLine);
        }
      });
    };
    fireAsync();

    return () => {
      if (ref) {
        ref();
      }
    };
  }, [currentDocID, ready]);

  useEffect(() => {
    let ref;
    const fireAsync = async () => {
      ref = await getStylesRef((doc) => {
        setstyles(doc);
      });
    };
    fireAsync();

    return () => {
      if (ref) {
        ref();
      }
    };
  }, []);

  const sendResizeEvent = useCallback(() => {
    if (window.opener) {
      const elem = document.querySelector(".prompterArea");
      const height = Math.round(getComputedStyle(elem).height.split("px")[0]);
      const width = Math.round(getComputedStyle(elem).width.split("px")[0]);
      console.log(height, width);
      const resizeEvent = new CustomEvent("newWindowResize", {
        detail: {
          height: height,
          width: width,
        },
      });
      if (masterScreen1) {
        if (window.name === "targetWindow") {
          window.opener.dispatchEvent(resizeEvent);
          return;
        }
      }
    }
  }, [masterScreen1]);

  useEffect(() => {
    window.addEventListener("resize", sendResizeEvent);
    return () => {
      window.removeEventListener("resize", sendResizeEvent);
    };
  }, [masterScreen1]);

  useEffect(() => {
    if (ready) {
      const lastPosition = JSON.parse(window.localStorage.getItem("lastPosition")) || { y: 0, x: 0 };
      if (lastPosition) {
        // const size = JSON.parse(lastPosition);
        setnewPosition(lastPosition);
      }


    }
  }, [ready, currentDocID]);

  useEffect(() => {
    let timeout;
    if (ready) {
      timeout = setTimeout(() => {
        addBlockWithHeightToEditor("start");
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [ready]);

  useEffect(() => {
    if (ready) {
      handleDotSpace(spaceAfterDot, editorState, setEditorState,dispatch);
    }
  }, [ready, spaceAfterDot]);

  useEffect(() => {
    if (ready) {
      const elemRoot = document.querySelector("[data-contents]");

      elemRoot.style.zoom = textSize;
    }
  }, [textSize, ready]);



  useEffect(() => {
    if(!ready){
      return
    }
    const current = convertToUpperCase(allCaps, editorState);
    if (current) {
      setEditorState(current);
    }
  }, [allCaps,ready]);

  // useEffect(() => {
  //   // Get a reference to your scrollable element and progress bar
  //   const scrollableElement = document.querySelector(".DraftEditor-root");

  //   // Set up the event listener
  //   scrollableElement.addEventListener("scroll", () => {
  //     const remainingScrollPercentage = calculateRemainingScrollPercentage(scrollableElement);
  //     progressBar.style.width = remainingScrollPercentage + "%";
  //   });
  // }, [ready]);

  const listenerFunc = function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    const { value, command } = e.detail;
    const elem = document.querySelector(".DraftEditor-root");
    const progressBar = document.getElementById("progress-bar");

    if (command === "customScroll") {
      console.log(command);
      const remainingScrollPercentage = calculateRemainingScrollPercentage(elem);
      progressBar.style.width = remainingScrollPercentage + "%";
      elem.scrollTo({
        top: value,
      });
    }

    if (command === "setLineSpacing") {
      const elem = document.querySelector("[data-contents]");
      elem.style.lineHeight = `${value}px`;
      return;
    }
    if (command === "setScreenMargin") {
      console.log("setScreenMargin", value);
      setPageMargin(value);
      return;
    }
    if (command === "setAllCaps") {
      setAllCaps(value);
    }
    if (command === "setBreakOnSentenceEnd") {
      console.log("setBreakOnSentenceEnd", value);
      setspaceAfterDot(value);
    }
    if (command === "setBreakOnTriggerColor") {
      addLineBreakAfterBackground(editorState, setEditorState, value);
    }
    if (command === "setShowTextLine") {
      console.log("setshowTextLine", value);
      setshowTextLine(value);
      return;
    }
    if (command === "setFlipVertical1") {
      flipPageVerical(value);
      return;
    }
    if (command === "setFlipHorizontal1") {
      flipPageHorizontal(value);
      return;
    }

    if (command === "setTextSize") {
      setTextSize(value);
      return;
    }
    if (command === "scrollSpeed") {
      console.log("scrollSpeed", value);
      setPlaySpeed(value);
      return;
    }
    if (command === "nextMarker") {
      elem.scrollTo({
        left: 0,
        top: value.scrollTo,
        behavior: "smooth",
      });
      return;
    }
    if (command === "prevMarker") {
      elem.scrollTo({
        left: 0,
        top: value.scrollTo,
        behavior: "smooth",
      });
      return;
    }
    if (command === "startOver") {
      elem.scrollTo(0, 0);
      return;
    }
    if (command === "setPointer") {
      setnewPosition(value);
      return;
    }
  };
  useEffect(() => {
    window.addEventListener("prompterListener", listenerFunc);
    return () => {
      window.removeEventListener("prompterListener", listenerFunc);
    };
  }, [monitorNum]);

  useEffect(() => {
    function handleScroll(event) {
      event.preventDefault();
    }

    document.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <div className="col-12 d-flex flex-column justify-content-start align-items-center outputWrapper darker position-relative">
      <Line />
      <div
        onClick={toggleFullscreen}
        className="col-auto d-flex justify-content-center pointer menuBtn hoverSoft position-absolute top-0 start-0 m-2"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-title="Full Screen"
        style={{ zIndex: "999" }}
      >
        <img src="/assets/icons/fullscreen.svg" height="30" width="30" />
      </div>
      <div
        className={`col-12 d-flex flex-column justify-content-center align-items-center moka wallakH position-relative withPointer prompterArea`}
        style={{ fontSize: "35px" }}
      >
        <Pointer setisClicked={setisClicked} isClicked={isClicked} dragPosition={newPosition} editorReady={ready} showTextLine={showTextLine} isDemo={true} />
        <Editor ref={ref} customStyleMap={styles} textAlignment="center" editorState={editorState} onChange={setEditorState} readOnly={true} />
      </div>
      <ButtonLoader state={!ready} className={"position-absolute top-0 left-0 right-0 bottom-0"} />

    </div>
  );
};
export default OutputPrompt;
