import { useState } from "react";
import TextFunctionIn from "../TextFunctionIn";
import { useEffect } from "react";
import { setToast } from "../../Utils";
import { setFunctionsToDB } from "../../firebase";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
const Parent = ({ inFunctionsList, setinFunctionsList, type, addallFunctions, differ, settriggerText, setinAmount }) => {
  if (inFunctionsList == false) {
    const total = [...differ];
    return total.map((el, idx) => {
      return (
        <TextFunctionIn
          addallFunctions={addallFunctions}
          key={el.id}
          inFunctionsList={inFunctionsList}
          settriggerText={settriggerText}
          setInFunctionsList={setinFunctionsList}
          idx={idx}
          listElem={el.hasOwnProperty("Function") ? el : false}
          setinAmount={setinAmount}
          id={el.id}
        />
      );
    });
  }
  const total = [...inFunctionsList];
  return total.map((el, idx) => {
    return (
      <TextFunctionIn
        addallFunctions={addallFunctions}
        key={el.id}
        inFunctionsList={inFunctionsList}
        settriggerText={settriggerText}
        setInFunctionsList={setinFunctionsList}
        idx={idx}
        listElem={el.hasOwnProperty("Function") ? el : false}
        setinAmount={setinAmount}
        id={el.hasOwnProperty("Function") ? el.id : el}
      />
    );
  });
};
const TextTrigger = ({
  vmixObject,
  addallFunctions,
  setAddAllFunctions,
  setAllFunctions,
  allFunctions,
  done,
  setDone,
  externalFunctions,
  isEditing,
  setIsEditing,
}) => {
  const [inFunctionsList, setinFunctionsList] = useState([]);
  const [triggerText, settriggerText] = useState("");
  const [inAmount, setinAmount] = useState([{
    Duration: "500",
    Delay: "0",
    Mix: "1",
    Function: "",
    Input: "",
    id: v4(),
    triggerType: "text",
    textTrigger: "",
    colorTrigger: "",
    type: "in",
  }]);
  useEffect(() => {
    if (addallFunctions) {
      if (inFunctionsList == false) {
        setToast({ state: "warning", text: "Fill your functions first." });
        setAddAllFunctions(false);
        return;
      }

      const item = inFunctionsList.filter((el) => !el.Function);
      if (!item != false) {
        setToast({ state: "warning", text: "Check your functions configuration." });
        setAddAllFunctions(false);
        return;
      }
      const payload = {
        triggerType: "text",
        trigger: triggerText,
        id: externalFunctions.id ? externalFunctions.id : v4(),
        functions: inFunctionsList,
        active:true
      };
      if (isEditing) {
        const all = allFunctions.filter((el) => el.id !== externalFunctions.id);
        const total = [...all, payload];
        setinAmount([]);
        setIsEditing(false);
        setAllFunctions(total);

        setAddAllFunctions(false);

        return;
      }
      setinAmount([]);

      setAllFunctions((prev) => [...prev, payload]);
      setinFunctionsList([{
        Duration: "500",
        Delay: "0",
        Mix: "1",
        Function: "",
        Input: "",
        id: "",
        triggerType: "text",
        textTrigger: "",
        colorTrigger: "",
        type: "in",
      }]);

      setAddAllFunctions(false);

      return;
    }
  }, [addallFunctions]);

  useEffect(() => {
    if (externalFunctions.hasOwnProperty("triggerType")) {
      settriggerText(externalFunctions.trigger);
      setinFunctionsList(externalFunctions.functions);
    }
  }, [externalFunctions]);

  useEffect(() => {
    const resetFunc = function () {
      setinFunctionsList([]);
    };
    document.addEventListener("resetFunc", resetFunc);
    return () => {
      document.removeEventListener("resetFunc", resetFunc);
    };
  }, []);

  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center textTrigger">
      <Parent
        addallFunctions={addallFunctions}
        differ={inAmount}
        inFunctionsList={inFunctionsList}
        setinFunctionsList={setinFunctionsList}
        settriggerText={settriggerText}
        type={"in"}
        setinAmount={setinAmount}
      />

      <div className="text-white col-auto pointer text-center  p-2  rounded m-2" onClick={() => setinFunctionsList((prev)=>([...prev,{
        Duration: "500",
        Delay: "0",
        Mix: "1",
        Function: "",
        Input: "",
        id: v4(),
        triggerType: "text",
        textTrigger: "",
        colorTrigger: "",
        type: "in",
      }]))}>
        + Add function out
      </div>
    </div>
  );
};
export default TextTrigger;
