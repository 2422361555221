import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { deletePrompt, updatePrompt, savePrompt } from "../../firebase";
import { setCurrentDocID, setFunctionsList, setIsEditing, setRefreshData, setSaveMode } from "../../Redux/Utils";
import { deleteDocument, loadDocToEditor, resetEditor } from "../../Utils";
import ButtonLoader from "../ButtonLoader";

const ContentModal = () => {
  const [deleteDoc, setDeleteDoc] = useState(false);
  const dispatch = useDispatch();
  const deleteDocID = useSelector((state) => state.user.deleteDocID);
  const docType = useSelector((state) => state.user.docType);

  useEffect(() => {
    if (deleteDoc) {
      setFunctionsList(dispatch,[])
      if (docType === "draft") {
        console.log("draft");
        resetEditor();
        window.$("#contentModal").modal("hide");
        setDeleteDoc(false);

        return;
      }
      if (docType === "prod") {
        setIsEditing(dispatch, false);
        window.$("#contentModal").modal("hide");
        setCurrentDocID(dispatch, "");
        setDeleteDoc(false);
      }
    }
  }, [deleteDoc]);

  return (
    <div className="modal fade" id="contentModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h1 className="modal-title fs-6 text-center m-auto" id="exampleModalLabel">
              Do you want to save this script before you open a new one?
            </h1>
          </div>
          <div className="modal-footer border-0 col d-flex flex-row justify-content-evenly">
            <div
              className="col pointer rounded-1 w-3 closeBtn"
              onClick={() => {
                window.$("#contentModal").modal("hide");
                setDeleteDoc(false);
                setSaveMode(dispatch, true);
              }}
            >
              SAVE
            </div>
            <div className="col pointer rounded-1 w-5 text-white  approveBtn" onClick={() => setDeleteDoc(true)}>
              {deleteDoc ? <ButtonLoader state={true} /> : "Open new script"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContentModal;
