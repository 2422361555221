import { useEffect } from "react";
import { getInputs } from "../../firebase";
import { useState } from "react";
import { setLoggedToVmix } from "../../Redux/Utils";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { disptachToVmix, parseVmixXml } from "../../Utils";

const InputsList = ({ withTitle, onchange, val, address, port }) => {
  const [refersh, setrefersh] = useState(false);
  const inputs = useSelector((state) => state.user.inputs);

  useEffect(() => {
    disptachToVmix("getInputs","")
  }, [refersh]);
  return (
    <div className="col-5 d-flex flex-column justify-content-center align-items-center ms-2">
      {withTitle && <span className="text-white">Input</span>}

      <div className="col-12 textTriggerInput d-flex flex-row justify-content-center align-items-center">
        <select
          className="form-select rounded-1 p-0 midFont p-1"
          value={val.Input}
          onChange={(e) =>
            onchange((prev) => {
              return { ...prev, Input: e.target.value };
            })
          }
        >
          <option className="w-5 midFont pointer" value={0}>
            Choose input
          </option>
          {inputs.map((el) => {
            return (
              <option className="w-5 midFont pointer" value={el.title}>
                {el.title}
              </option>
            );
          })}
        </select>
        <img
          src="/assets/icons/refresh.svg"
          onClick={() => setrefersh((prev) => !prev)}
          height="18"
          width="18"
          className="pointer m-1"
          style={{ filter: "invert(100%)" }}
        />
      </div>
    </div>
  );
};
export default InputsList;
