import { useEffect } from "react";
import "./App.css";
import AppRouter from "./AppRouter";
import DeleteModal from "./Components/DeleteModal";
import Toast from "./Components/Toast";
import Manager from "./Manager";

function App() {
  return (
    <div className="App">
      <Toast />
      <Manager />
      <AppRouter />
    </div>
  );
}

export default App;
